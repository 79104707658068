import React from 'react';
import Form from './Form';
import styles from './Contact.module.css';
import logoGif from '../../images/logo_trans.gif';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';


function Contact(props) {

  const language = useSelector(selectLanguage);

  const [loading, setLoading] = React.useState(true);

  function handleLoad() {
    setLoading(false);
  }

  // Dynamically changes meta tags
  React.useEffect(() => {
    document.title = language === 'EN' ? 'Contact' : 'İletişim';
    document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Contact us to get more info about our products.' : 'Ürünlerimiz hakkında daha fazla bilgi almak için bizimle iletişime geçin.';
    document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Contact' : 'İletişim';
  }, [language]);

  return (
    <div className={styles.Contact}>
        <section>

          <div className={styles.addressContainer}>
            <p>{language === 'EN' ? 'Address:' : 'Adres:'}</p>
            <a href="https://www.google.com/maps/place/Z%C3%BCht%C3%BCpa%C5%9Fa,+Feniks+Ambalaj,+R%C3%BC%C5%9Ftiye+Sk.+No:+8%2F3,+34724+Kad%C4%B1k%C3%B6y%2F%C4%B0stanbul/@40.9829104,29.0394953,17z/data=!4m6!3m5!1s0x14cab9c212dae5ab:0xdcb6aa98ef1e66d1!8m2!3d40.9829104!4d29.0394953!16s%2Fg%2F11fwg9xz6d" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#515150'}}>
            <p className={styles.linkText}>Zühtüpaşa Mahallesi,<br/>Rüştiye Sok. No : 8/3 34724 <br/> Kadıköy İstanbul</p></a>
            <p>{language === 'EN' ? 'Email:' : 'E-posta:'}</p>
            <a href="mailto: info@feniks.com.tr" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#515150'}}><p className={styles.linkText}>info@feniks.com.tr</p></a>
            <p>{language === 'EN' ? 'Phone:' : 'Telefon:'}</p>
            <a href="tel:+902165932782" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#515150'}}><p className={styles.linkText}>+90 (216) 593 27 82</p></a>
            <p>{language === 'EN' ? 'Instagram:' : 'Instagram:'}</p>
            <a href="https://www.instagram.com/feniksambalaj/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#515150'}}><p className={styles.linkText}>@feniksambalaj</p></a>
          </div>

          <br/>
          <br/>
          <div className={styles.iFrameContainer}>
            { loading ? <img className={styles.loading} src={logoGif} alt="loading gif" /> : null }
            <iframe
              onLoad={handleLoad}
              title="Map"
              className={styles.iframe}
              width="600"
              height="275"
              style={{border:0}}
              loading={"lazy"}
              allowFullScreen
              src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyBnJGRgeNVb7xFlQX9CCuW5bzKtwjMQMgw&q=Feniks+Ambalaj,Istanbul+Turkey"}>
            </iframe>
          </div>

        </section>
        <Form formtype='contact'/>
    </div>
  );
}

export default Contact;