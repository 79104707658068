import React from 'react';
import styles from './Solutions.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetFilters, toggleFilter } from '../catalogue/filterSlice';

import solutionsBanner from '../../images/solutions2.jpg';
import { selectLanguage, selectWidth } from '../../commons/appSlice';


function Solutions() {

    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const width = useSelector(selectWidth);

    // Scroll to Image
    function ScrollToImage() {
        if (window.innerWidth > 800) {
            let scrollInterval = setInterval(() => {
                window.scrollBy(0,1);
                if (window.scrollY > 100) {
                    clearInterval(scrollInterval);
                }
            }, 5)
        }
    }


    // Hover System
    function handleEnter(e) {
        
        if (width > 800) {
            const boxOne = document.getElementById('boxOne');
            // const boxTwo = document.getElementById('boxTwo');
            const boxThree = document.getElementById('boxThree');
            const boxFour = document.getElementById('boxFour');
            // const empty1 = document.getElementById('empty1');
            // const empty2 = document.getElementById('empty2');
            const kaplar = document.getElementById('kaplar');
            const etiket = document.getElementById('etiket');
            // const dis = document.getElementById('dis');
            const kapaklar = document.getElementById('kapaklar');
            const tasarim = document.getElementById('tasarim');
            // const makineler = document.getElementById('makineler');
    
            //Box One
            if (e.target.id !== 'empty1' && e.target.id !== 'empty2' && e.target.id !== 'empty3'){
                const rect = e.target.getBoundingClientRect();
                boxOne.style.top = `${rect.top + window.scrollY - 15}px`;
                boxOne.style.left = `${rect.left - 35}px`;
                boxOne.style.display = 'block';
            }
    
            //Box Two
            if (e.target.id === "empty1"){
                // const rect = e.target.getBoundingClientRect();
                // boxTwo.style.top = `${rect.top + window.scrollY - 15}px`;
                // boxTwo.style.left = `${rect.left + 65}px`;
                // boxTwo.style.height = '220px';
                // boxTwo.style.display = 'block';
    
                //Kapaklar BoxOne
                const kapaklarRect = kapaklar.getBoundingClientRect();
                boxOne.style.top = `${kapaklarRect.top + window.scrollY - 15}px`;
                boxOne.style.left = `${kapaklarRect.left - 35}px`;
                boxOne.style.display = 'block';
                
            } else if (e.target.id === "empty2" || e.target.id === "empty3"){
                // const empty2Rect = empty2.getBoundingClientRect();
                // boxTwo.style.top = `${empty2Rect.top + window.scrollY - 35}px`;
                // boxTwo.style.left = `${empty2Rect.left + 65}px`;
                // boxTwo.style.height = '470px';
                // boxTwo.style.display = 'block';
    
                //Kaplar BoxOne
                const kaplarRect = kaplar.getBoundingClientRect();
                boxOne.style.top = `${kaplarRect.top + window.scrollY - 15}px`;
                boxOne.style.left = `${kaplarRect.left - 35}px`;
                boxOne.style.display = 'block';
    
                //Etiket BoxThree
                const etiketRect = etiket.getBoundingClientRect();
                boxThree.style.top = `${etiketRect.top + window.scrollY - 15}px`;
                boxThree.style.left = `${etiketRect.left - 35}px`;
                boxThree.style.display = 'block';
    
                //Tasarim BoxFour
                const tasarimRect = tasarim.getBoundingClientRect();
                boxFour.style.top = `${tasarimRect.top + window.scrollY - 15}px`;
                boxFour.style.left = `${tasarimRect.left - 35}px`;
                boxFour.style.display = 'block';
            }
    
            // //Kapaklar BoxTwo
            // if (e.target.id === "kapaklar"){
            //     const empty1Rect = empty1.getBoundingClientRect();
            //     boxTwo.style.top = `${empty1Rect.top + window.scrollY - 15}px`;
            //     boxTwo.style.left = `${empty1Rect.left + 65}px`;
            //     boxTwo.style.height = '220px';
            //     boxTwo.style.display = 'block';
            // }
    
            // //Etiket/Tasarim/Kaplar BoxTwo
            // if (e.target.id === "etiket" || e.target.id === "tasarim" || e.target.id === "kaplar" ||
            //     e.target.id === "etiketChild" || e.target.id === "tasarimChild" || e.target.id === "kaplarChild") {
            //     const empty2Rect = empty2.getBoundingClientRect();
            //     boxTwo.style.top = `${empty2Rect.top + window.scrollY - 35}px`;
            //     boxTwo.style.left = `${empty2Rect.left + 65}px`;
            //     boxTwo.style.height = '470px';
            //     boxTwo.style.display = 'block';
            // }
    
            // //Dis/Makinler Vanish BoxTwo
            // if (e.target.id === "dis" || e.target.id === "makineler" || e.target.id === "makinelerChild" || e.target.id === "disChild"){
            //     boxTwo.style.display = 'none';
            // }
            
    
            //BoxThree and Four Vanish
            if (e.target.id !== "empty2" && e.target.id !== "empty3"){
                boxThree.style.display = 'none';
                boxFour.style.display = 'none';
            }
        }



    };

    // Dynamically change meta tags
    React.useEffect(() => {
        document.title = language === 'EN' ? 'Solutions' : 'Çözümler';
        document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Check out all our solutions that we offer for packaging your products.' : 'Ürünlerinizi paketlemek için sunduğumuz tüm çözümlerimizi inceleyin.';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Solutions' : 'Çözümler';
      }, [language]);


    return (
        <div className={styles.Solutions}>

            <div className={styles.bannerContainer}>
                <img src={solutionsBanner} alt="zeytinyağı şişesi" className={styles.image} onLoad={ScrollToImage}/>
                <div className={styles.textsContainer}>
                    <Link to="/catalogue" className={styles.bottlesContainer} onMouseOver={handleEnter} id="kaplar" onClick={() => {window.scrollTo(0,0); dispatch(resetFilters()); dispatch(toggleFilter(['type', 'bottle', true])); dispatch(toggleFilter(['sector', 'oliveOil', true]));}}>
                        
                        {language === 'TR' ?
                            <div id="kaplarChild">
                                <strong>ŞİŞE ve DİĞER KAPLAR ></strong>
                                <br/>
                                <br/>
                                Dünyaca ünlü cam ambalaj ve ülkemizin elit plastik ve metal ambalaj
                                üreticileri tarafından üretilen eşsiz kap tasarımlarını gıda ve içecek
                                sektörlerine sunmaktayız. Geniş ürün yelpazemiz ve 30 senelik
                                deneyimimiz sayesinde, müşterilerimizin ürünlerine ve marka
                                kimliklerine en uygun kapları öneriyor ve raflarda öne çıkmalarını
                                sağlıyoruz. Özel kalıplı veya mevcut tasarımların kişiselleştirilmesi
                                konularında esnek koşullar ve makul alım miktarları sunarak
                                müşterilerimizin özel projelerine de destek oluyoruz.
                            </div>
                        :
                            <div id="kaplarChild">
                                <strong>CONTAINERS ></strong>
                                <br/>
                                <br/>
                                We provide unmatched containers to our customers from world
                                renowned glass container producers and elite domestic metal and
                                plastic container producers. With our diverse selection of products
                                and 30 years of experience, we help our customers
                                outshine their competition on the shelves by making sure they have the
                                most suitable containers for their product types and brand identities.
                                We also bolster our customers’ specialty projects through providing
                                flexible conditions and reasonable minimum purchase amounts for
                                their personalized molds.
                            </div>
                        }

                    </Link>

                    <Link to="/design" className={styles.labelsContainer} onMouseOver={handleEnter} id="etiket">
                        
                        {language === 'TR' ?
                            <div id="etiketChild">
                                <strong>ETİKET, BASKI ve DEKORASYON ></strong>
                                <br/>
                                <br/>
                                Etiket ve ambalaj üzerine direkt uygulanan farklı dekorasyon
                                teknikleri konusunda müşterilerimize tedarik desteği vererek,
                                ürünlerinin raflardaki duruşunda büyük bir önem taşıyan ve hata
                                yapılması olasılığı yüksek olan bu uygulamalarda müşterilerimize
                                kolaylık sağlamaktayız. Bu hizmetimizdeki amaç, müşterimizin
                                ambalajın son halinden % 100 tatmin olması ve gereksiz harcamaların
                                önüne geçilmesidir.
                            </div>
                        :
                            <div id="etiketChild">   
                                <strong>LABLES, PRINT AND DECORATION ></strong>
                                <br/>
                                <br/>
                                Labels and decoration of containers is one of the most important
                                factors when it comes to outshining one’s competition on shelves,
                                and it’s implementation is usually quite tricky whether it is labeling or
                                printing. Through the help of our experienced solution partners, we
                                help our customers get the right decorations with the right
                                implementations so that the end result is fulfilling and not defective.
                            </div>
                        }   
                        
                    </Link>

                    <Link to="/catalogue" className={styles.casesContainer} onMouseOver={handleEnter} id="dis" onClick={() => {window.scrollTo(0,0); dispatch(resetFilters()); dispatch(toggleFilter(['type', 'outer', true])); dispatch(toggleFilter(['sector', 'oliveOil', true]));}}>
                        
                        {language === 'TR' ?
                            <div id="disChild">
                                <strong>DIŞ AMBALAJLAR ></strong>
                                <br/>
                                <br/>
                                Uzaktan satışların ciddi olarak artmış olduğu bu dönemde koliler ve
                                kutular büyük önem kazandılar. Müşterilerimize kırılmaları
                                engelleyici teknik destek vermek ve ürün sunumlarını daha estetik
                                hale getirmek amacıyla bu ürün grubunu da yelpazemize ekledik.
                                Buradaki amacımız müşterilerimizin olası ölçü ve malzeme seçimi
                                hatalarından mağdur olmadan kendilerini tatmin edecek ambalaj
                                malzemelerini tek noktadan, çok büyük emek sarfetmeden ve fazla
                                bir bedel ödemeden elde etmesidir.
                            </div>
                        :
                            <div id="disChild">
                                <strong>OUTER PACAKING ></strong>
                                <br/>
                                <br/>
                                Packages and parcels gained substantial importance with the recent
                                increase of remote sales. We have added this product group to our
                                catalogue in order to give our customers the necessary technical
                                support to avoid fractures and further improve their products’

                                aesthetics. Through this, we aim to allow our customers to acquire
                                the most suitable packaging for their products from a single vendor
                                without any extra effort or fees.
                            </div>
                        }
                        
                    </Link>

                    <div className={styles.empty} id="empty1" onMouseOver={handleEnter}></div>
                    <div className={styles.empty} id="empty2" onMouseOver={handleEnter}></div>
                    <div className={styles.empty} id="empty3" onMouseOver={handleEnter}></div>

                    <Link to="/catalogue" className={styles.capsContainer} onMouseOver={handleEnter} id="kapaklar" onClick={() => {window.scrollTo(0,0); dispatch(resetFilters()); dispatch(toggleFilter(['type', 'cap', true])); dispatch(toggleFilter(['sector', 'oliveOil', true]));}}>
                        
                        {language === 'TR' ?
                            <div id="kapaklarChild">
                                <strong>KAPAMA SİSTEMLERİ ></strong>
                                <br/>
                                <br/>
                                Cam, plastik veya metal olsun, satış hattında olan herhangi bir kabı
                                kapatabilmek için müşterilerine birden fazla seçenek sunmaktayız.
                                Ambalaj grubuna katılan yeni ürünler ile birlikte gelişen kapama
                                sistemleri grubunda alüminyum, metal, plastik, ahşap malzeme ile
                                yapılmış vidalı ve çakma kapakların yanısıra sonsuz sayıda dekoratif
                                başlıklı tıpa çeşidini müşterilerimize sağlamaktayız.
                            </div>
                        :
                            <div id="kapaklarChild">
                                <strong>CLOSURES ></strong>
                                <br/>
                                <br/>
                                We provide our customers with numerous closure options for their
                                glass, plastic and metal containers. The novel packaging products of
                                the 21 st century gave way to innovative closure systems, including
                                screw caps and stoppers made out of aluminum, metal, plastic and
                                wood. We help our customers select the most suitable closures for
                                their containers.
                            </div>
                        }
                        
                    </Link>

                    <Link to="/design" className={styles.designsContainer} onMouseOver={handleEnter} id="tasarim">
                        
                        {language === 'TR' ?
                            <div id="tasarimChild">
                                <strong>TASARIM ></strong>
                                <br/>
                                <br/>
                                Müşterilerimizin ürünlerinin cezbedici bir şekilde raflarda yer
                                alabilmesi için tasarım çok önemli bir basamaktır. Başarılı bir
                                “ambalaj”, tasarımcı ve kap-kapak-etiket-dekorasyon-kutu-koli
                                tedarikçilerinin koordinasyonu ile ortaya çıkar. Biz Feniks Ambalaj
                                olarak deneyimli tasarım çözüm ortaklarımızla beraber bu
                                koordinasyonu sağlayarak ürünlerin kara kalemden rafa kadar başarı
                                ile gelmesine katkıda bulunuyoruz.
                            </div >
                        :
                            <div id="tasarimChild">
                                <strong>DESIGN ></strong>
                                <br/>
                                <br/>
                                The design of the labels and prints are crucial for the success of a
                                product, and the success of a packaging depends on the cohesive
                                interrelationship between the designers and the suppliers of the
                                other packaging elements such as containers and labels. We make
                                sure to maintain this interrelationship with the help of our
                                experienced solution partners so that our customers’ products reach
                                the shelves without any inconveniences.
                            </div>
                        }
                    </Link>

                    <Link to="/catalogue" className={styles.machinesContainer} onMouseOver={handleEnter} id="makineler" onClick={() => {window.scrollTo(0,0); dispatch(resetFilters()); dispatch(toggleFilter(['type', 'machine', true])); dispatch(toggleFilter(['sector', 'oliveOil', true]));}}>
                        
                    {language === 'TR' ?
                            <div id="makinelerChild">
                                <strong>MAKİNELER ></strong>
                                <br/>
                                <br/>
                                Ürünlerin rafa hazır hale gelmesindeki çok büyük bir rolü, ürünlerin
                                doldurulmasını ve ambalajların uygulanmasını sağlayan makineler
                                üstlenir. Ambalajlama kalitesini ve hızını arttırmak için doğru
                                makinelere sahip olmak çok önemlidir. Makine satışlarımızdaki en
                                büyük amacımız müşterilerimizin ürün profili, üretim miktarı ve
                                üretim sahasına uygun ekipmanları önermek ve satışını
                                gerçekleştirdiğimiz ekipmanların teknik bakım ve onarım anlamında
                                arkasında durmaktır.
                            </div>
                        :
                            <div id="makinelerChild">
                                <strong>MACHINES ></strong>
                                <br/>
                                <br/>
                                Packaging application and filling machines have a prominent role in
                                making the products ready to be displayed on shelves. It is vital to
                                have the right machines to optimize packaging speed and quality. Our
                                main goal in our machinery sales is to provide our customers with the
                                most suitable machines based on their product types, production
                                volumes and production facilities, and to give the necessary support
                                in terms of technical maintenances and repairs.
                            </div>
                        }
                    </Link>
                </div>
                <div className={styles.boxOne} id='boxOne'></div>
                <div className={styles.boxTwo} id='boxTwo'></div>
                <div className={styles.boxOne} id='boxThree'></div>
                <div className={styles.boxOne} id='boxFour'></div>
            </div>

        </div>
    );
}

export default Solutions;