import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeName, selectName,
         changeCompany, selectCompany,
         changeEmail, selectEmail,
         changePhone, selectPhone,
         changeMessage, selectMessage, selectContactClicked, 
         selectNewsletterChecked, handleCheck,
         selectQuoteClicked, handleClick, clearAll } from './formSlice';
import { selectBasket, clearQuotes } from '../quote/quoteSlice';
import emailjs from 'emailjs-com';
import styles from './Form.module.css';
import { selectLanguage } from '../../commons/appSlice';

function Form(props) {

    const name = useSelector(selectName);
    const company = useSelector(selectCompany);
    const email = useSelector(selectEmail);
    const phone = useSelector(selectPhone);
    const message = useSelector(selectMessage);
    const basket = useSelector(selectBasket);

    const contactClicked = useSelector(selectContactClicked);
    const quoteClicked = useSelector(selectQuoteClicked);
    const newsletterChecked = useSelector(selectNewsletterChecked);

    const language = useSelector(selectLanguage);

    const dispatch = useDispatch();

    // Quotes currently in the basket
    const quotes = basket.map(quote => quote.id);

    // Handles sending emails
    function sendEmail(event) {
        event.preventDefault();
        emailjs.sendForm('service_25aa4fl', 'template_ay0wz32', event.target, 'user_u0dezM5XUrY9HGJbufsTf')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }

    // Handles sending to database
    function saveCustomer(event) {
        event.preventDefault();
        const data = {name: name, company: company, email: email, phone: phone, message: message, newsletter: newsletterChecked, quotes: quotes}
        fetch('/admin/customers', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)});
    }

    return (
        <div>
            { props.formtype === 'contact' ?
                ( !contactClicked ?
                <form className={styles.Form} onSubmit={(event) => {saveCustomer(event); sendEmail(event); dispatch(handleClick('contact'))}}>
                    <h2>{language === 'EN' ? 'Contact us here.' : 'Bize ulaşın.'}</h2>
                    <input type="text" name="user_name" className={styles.input} placeholder={language === 'EN' ? 'Your full name *' : 'Adınız Soyadınız *'} value={name} onChange={(event) => dispatch(changeName(event.target.value))} maxLength="50" required/>
                    <input type="text" name="user_company" className={styles.input} placeholder={language === 'EN' ? 'Your company *' : 'Şirketiniz *'} value={company} onChange={(event) => dispatch(changeCompany(event.target.value))} maxLength="50" required/>
                    <input type="email" name="user_email" className={styles.input} placeholder={language === 'EN' ? 'Your email *' : 'E-postanız *'} value={email} onChange={(event) => dispatch(changeEmail(event.target.value))} maxLength="50" required/>
                    <input type="tel" name="user_phone" className={styles.input} placeholder={language === 'EN' ? 'Your phone number' : 'Telefon Numaranız'} value={phone} onChange={(event) => dispatch(changePhone(event.target.value))} maxLength="50"/>
                    <textarea name="message" className={styles.input} value={message} placeholder={language === 'EN' ? 'Your message *' : 'Mesajınız *'} onChange={(event) => dispatch(changeMessage(event.target.value))} maxLength="500" required/>
                    <section className={styles.checkboxContainer}>
                        <input type='checkbox' id='newsletter' name='newsletter' className={styles.checkbox} checked={newsletterChecked} onClick={() => dispatch(handleCheck())}/>
                        <label htmlFor="newsletter">{language === 'EN' ? 'Subscribe to our newsletter' : 'Bültenimize abone olun'}</label>
                    </section>
                    <input type="submit" value={language === 'EN' ? 'SEND' : 'GÖNDER'} className={styles.submit}/>
                </form>
                : 
                <section className={styles.submitted}>
                    <p>{language === 'EN' ? 'Our team has received your message and we will get back to you as soon as possible, thanks!' : 'Ekimibiz mesajınızı aldı, en kısa zamanda size geri dönüş yapacağız. Teşekkürler!'}</p>
                    <button className={styles.button} onClick={() => {dispatch(handleClick('contact')); dispatch(clearAll());}}>{language === 'EN' ? 'Send another message' : 'Bir mesaj daha gönderin'}</button>
                </section>
                )    
            : null}

            { props.formtype === 'quote' ?
                ( !quoteClicked ?
                <form className={styles.Form} onSubmit={(event) => {saveCustomer(event); sendEmail(event); dispatch(handleClick('quote'))}}>
                    <h2>{language === 'EN' ? 'Contact Info' : 'İletişim Bilgileri'}</h2>
                    <input type="text" name="user_name" className={styles.input} placeholder={language === 'EN' ? 'Your full name *' : 'Adınız Soyadınız *'} value={name} onChange={(event) => dispatch(changeName(event.target.value))} maxLength="50" required/>
                    <input type="text" name="user_company" className={styles.input} placeholder={language === 'EN' ? 'Your company *' : 'Şirketiniz *'} value={company} onChange={(event) => dispatch(changeCompany(event.target.value))} maxLength="50" required/>
                    <input type="email" name="user_email" className={styles.input} placeholder={language === 'EN' ? 'Your email *' : 'E-postanız *'} value={email} onChange={(event) => dispatch(changeEmail(event.target.value))} maxLength="50" required/>
                    <input type="tel" name="user_phone" className={styles.input} placeholder={language === 'EN' ? 'Your phone number' : 'Telefon Numaranız'} value={phone} onChange={(event) => dispatch(changePhone(event.target.value))} maxLength="50"/>
                    <textarea name="message" className={styles.input} value={message} placeholder={language === 'EN' ? 'Your message *' : 'Mesajınız *'} onChange={(event) => dispatch(changeMessage(event.target.value))} maxLength="500" required/>
                    <section className={styles.checkboxContainer}>
                        <input type='checkbox' id='newsletter' name='newsletter' className={styles.checkbox} checked={newsletterChecked} onClick={() => dispatch(handleCheck())}/>
                        <label htmlFor="newsletter">{language === 'EN' ? 'Subscribe to our newsletter' : 'Bültenimize abone olun'}</label>
                    </section>
                    <input type="hidden" name="quotes" value={quotes} />
                    <input type="submit" value={language === 'EN' ? 'GET QUOTE' : 'TEKLİF AL'} className={styles.submit}/>
                </form>
                : 
                <section className={styles.submitted}>
                    <p>{language === 'EN' ? 'Our team has received your message and we will get back to you as soon as possible, thanks!' : 'Ekimibiz mesajınızı aldı, en kısa zamanda size geri dönüş yapacağız. Teşekkürler!'}</p>
                    <button className={styles.button} onClick={() => {dispatch(handleClick('quote')); dispatch(clearQuotes()); dispatch(clearAll());}}>{language === 'EN' ? 'Get another quote' : 'Bir teklif daha al'}</button>
                </section>
                )    
            : null} 
        </div>
    )
}

export default Form;