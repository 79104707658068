import React from 'react';
import { Link } from 'react-router-dom';
import styles from './QuoteBox.module.css';

import collapseIcon from '../../images/collapse_icon.png'

import { removeQuote, clearQuotes, toggleQuoteBox,selectBasket, selectQuoteBoxIsVisible } from './quoteSlice';
import { useDispatch, useSelector} from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';




function QuoteBox() {

    const quoteBasket = useSelector(selectBasket);
    const quoteBoxIsVisible = useSelector(selectQuoteBoxIsVisible);
    const dispatch = useDispatch();

    const language = useSelector(selectLanguage);

    // Click-out Collapse
    const boxRef = React.useRef(null);
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target) && quoteBoxIsVisible) {
                dispatch(toggleQuoteBox(false));
            }
        };
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => document.removeEventListener("mousedown", handleClickOutside, false);
    });


    if (!quoteBoxIsVisible) {
        return null
    }


    return (
        <div className={styles.QuoteBox} ref={boxRef}>
            <div className={styles.topContainer}>
                {quoteBasket.length > 0 ? <button onClick={() => {dispatch(clearQuotes()); dispatch(toggleQuoteBox(false))}} className={styles.clearButton}>{language === 'EN' ? 'CLEAR ALL' : 'TEMİZLE'} X</button> : <p> </p>}
                <img src={collapseIcon} onClick={() => dispatch(toggleQuoteBox(false))} alt='collapse' className={styles.collapse}/>
            </div>
            
            <section className={styles.itemsContainer}>
                {quoteBasket.map(quote =>   <Link to={`/catalogue/${quote.id.toLowerCase().split(" ").join('-')}`} className={styles.item} key={quote.id}>
                                                <img src={quote.image} alt="quoted product" className={styles.image}/>
                                                <h5 className={styles.itemName}>{language === 'EN' ? quote.name : (quote.nameTR !== 'NA' ? quote.nameTR : quote.name)}</h5>
                                                {/* <p style={{fontSize: '12px'}}>({quote.volume}ml)</p> */}
                                                <button onClick={() => dispatch(removeQuote(quote))} className={styles.removeButton}>x</button>
                                            </Link>)}
            </section>
            
            <section className={styles.buttons}>                        
                {quoteBasket.length > 0 ? <Link to="/quote"><button onClick={() => dispatch(toggleQuoteBox(false))} className={styles.getButton}>{language === 'EN' ? 'GET QUOTE' : 'TEKLİF AL'}</button></Link> : <Link to="/catalogue"><button onClick={() => dispatch(toggleQuoteBox())} className={styles.getButton}>{language === 'EN' ? 'ADD ITEMS' : 'ÜRÜN EKLE'}</button></Link>}
                {quoteBasket.length > 0 ? <Link to="/comparison"><button onClick={() => dispatch(toggleQuoteBox(false))} className={styles.getButton}>{language === 'EN' ? 'COMPARE' : 'KARŞILAŞTIR'}</button></Link> : null}
            </section>    
        </div>
    )
}

export default QuoteBox;