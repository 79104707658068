import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectLanguage } from '../../commons/appSlice';
import styles from './NotFound.module.css';
import arrayBottles from '../../images/arrayBottles.jpg';


function NotFound() {

    const language = useSelector(selectLanguage);

    React.useEffect(() => {
        document.title = language === 'EN' ? 'Page Not Found' : 'Sayfa Bulunamadı';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Page Not Found' : 'Sayfa Bulunamadı';
      }, [language]);

    return (
        <div className={styles.NotFound}>
            <meta name="robots" content="noindex" />

            <img src={arrayBottles} className={styles.image} alt="Zeytinyağı Şişeleri"/>

            <div className={styles.textContainer}>
                <h1>{language === 'EN' ? 'Page not found.' : 'Bu sayfa bulunamadı.'}</h1>
                <Link to="/" className={styles.button}>{language === 'EN' ? 'Go to homepage >' : 'Anasayfaya Git >'}</Link>
            </div>



        </div>
    );
}

export default NotFound;