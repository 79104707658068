import { jsPDF } from "jspdf";

import pdfchart from '../../images/pdfchart.png';
import pdffooter from '../../images/pdffooter.png';

import colorsDict from "./colors";
import { set } from "react-ga";

// const pdf_w = 210;
// const pdf_h = 297;

let chart = new Image();
chart.src = pdfchart;

let footer = new Image();
footer.src = pdffooter;

// function readFontText() {
              
//     fetch(font64)
//     .then(r => r.text())
//     .then(text => {
//       return text;
//     });
// }

// const openSans = readFontText();

export async function CreatePDF(product, img, logo, language){

    // const sectorNames = language === 'EN' ? {oliveOil: 'OLIVE OIL AND VINEGAR', 
    //                                          wine: 'WINE', 
    //                                          sparkling: 'SPARKLING WINE', 
    //                                          alcohol: 'ALCOHOLIC BEVERAGES',
    //                                          various: 'VARIOUS FOOD'}
    //                                       : {oliveOil: 'ZEYTİNYAĞI VE SİRKELER', 
    //                                          wine: 'ŞARAPLAR', 
    //                                          sparkling: 'KÖPÜKLÜ ŞARAPLAR', 
    //                                          alcohol: 'ALKOLLÜ İÇECEKLER',
    //                                          various: 'GIDA ÜRÜNLERİ'}

    const sectorNames = {oliveOil: 'Olive Oil and Vinegar', 
                            wine: 'Wine', 
                            sparkling: 'Sparkling Wine', 
                            alcohol: 'Alcoholic Beverages',
                            various: 'Various Food'}

    // const neckNames = language === 'EN' ? {dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', 
    //                                         bvs30: 'BVS 30', corkFA: 'CORK FA', corkFP: 'CORK FP', stopper: 'STOPPER',
    //                                         gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', 
    //                                         tm: 'TAPPO MECANICO', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', 
    //                                         crown26: 'CROWN 26', crown29: 'CROWN 29', fc29: 'FC 29', ropp18: 'ROPP 18'} 
    //                                     : {dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', bvs30: 'BVS 30', 
    //                                         corkFA: 'MANTAR FA', corkFP: 'MANTAR FP', stopper: 'TIPA',
    //                                         gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', 
    //                                         rical32: 'RICAL 32', tm: 'MEKANİK KAPAK', ropp24: 'ROPP 24', ropp25: 'ROPP 25', 
    //                                         ropp28: 'ROPP 28', crown26: 'TAÇ 26', crown29: 'TAÇ 29', fc29: 'FC 29', ropp18: 'ROPP 18'}; 
    
    const neckNames = {dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', 
                            bvs30: 'BVS 30', corkFA: 'CORK FA', corkFP: 'CORK FP', stopper: 'STOPPER',
                            gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', 
                            tm: 'TAPPO MECANICO', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', 
                            crown26: 'CROWN 26', crown29: 'CROWN 29', fc29: 'FC 29', ropp18: 'ROPP 18'} 

    const doc = new jsPDF();

    doc.setFont('Helvetica');
    doc.setTextColor(81, 81, 80);

    await new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, 500)
    })

    doc.addImage(pdffooter, 'PNG', 15, 260, 180, 30, undefined, 'FAST');


    await new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, 500)
    })

    if (product.type === 'bottle') {

        doc.addImage(img, 'PNG', 5, 0, 200, 200, undefined, 'FAST');
        doc.addImage(logo, 'PNG', 130, 0, 70, 103.812 / 2, undefined, 'FAST')
    
        doc.addImage(pdfchart, 'PNG', 15, 210, 180, 30, undefined, 'FAST');
    
        let colorCount = 0;
        product.colors.forEach((color) => {
            doc.setFillColor(colorsDict[color]);
            doc.circle(100 + 10*colorCount, 206, 3, "F");
            colorCount += 1;
        })
    
        doc.setFontSize(12);
        doc.text(sectorNames[product.sector], 15, 21);
        doc.setFontSize(24);
        doc.text(product.name, 15, 30);
        doc.setFontSize(9);
        doc.text(product.volume + 'ml', 15, 35);
    
        doc.setFontSize(10);
        // doc.text('AVAILABLE VOLUMES: ' + product.allVolumes + 'ml', 20, 285);
        // doc.text('AVAILABLE COLORS: ' + product.colors, 20, 285);
    
        const distance = (210 - 28*2) / 7;
        doc.text(product.weight !== "0" ? product.weight  + 'mg' : '-', 28, 233, 'center');
    
        const neckValues = product.neckFinish.map(neck => neckNames[neck])
        doc.text(neckValues, 28+distance, neckValues.length === 1 ? 233 : 231, 'center');
    
        doc.text(product.diameter !== '0' ? product.diameter + 'mm' : '-', 28+distance*2, 233, 'center');
        doc.text(product.height !== '0' ? product.height + 'mm' : '-', 28+distance*3, 233, 'center');
        doc.text(product.innerNeckDiameter !== '0' ? product.innerNeckDiameter + 'mm' : '-', 28+distance*4, 233, 'center');
        doc.text(product.outerNeckDiameter !== '0' ? product.outerNeckDiameter + 'mm' : '-', 28+distance*5, 233, 'center');
        doc.text(product.palletHAmount !== '0' ? product.palletHAmount : '-', 28+distance*6, 233, 'center');
        doc.text(product.palletLAmount !== '0' ? product.palletLAmount : '-', 210 - 28, 233, 'center');
    }


    doc.save(product.name + ".pdf");
}
