import React from 'react';
import styles from './Quote.module.css';
import {Link} from 'react-router-dom';

import { removeQuote, clearQuotes, selectBasket } from './quoteSlice';
import { useDispatch, useSelector} from 'react-redux';

import Form from '../contact/Form';
import { selectLanguage } from '../../commons/appSlice';


function Quote() {

    const language = useSelector(selectLanguage);

    const quoteBasket = useSelector(selectBasket);
    const dispatch = useDispatch();

    React.useEffect(() => {
        document.title = language === 'EN' ? 'Quote' : 'Teklif';
        document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Get a quote from us for the products you like.' : 'Beğendiğiniz ürünler için bizden teklif alın.';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Quote' : 'Teklif';
        }, [language]);

    return (
        <div className={styles.Quote}>
            <section className={styles.leftContainer}>
                <h2 className={styles.headerQuote}>{language === 'EN' ? 'Quote Basket' : 'Teklif Sepeti'} ({quoteBasket.length} {language === 'EN' ? 'items' : 'ürün'})</h2>
                <section className={styles.buttons}>
                    <Link to="/comparison" style={{textDecoration: 'none'}} className={styles.compareItems}><p>{language === 'EN' ? 'COMPARISON' : 'KARŞILAŞTIR'}</p></Link>
                    <Link to="/catalogue" style={{textDecoration: 'none'}} className={styles.addItems}><p>{language === 'EN' ? 'ADD ITEMS' : 'ÜRÜN EKLE'} <strong>+</strong></p></Link>
                    <Link to="/catalogue" style={{textDecoration: 'none'}} className={styles.removeItems} onClick={() => dispatch(clearQuotes())}><p>{language === 'EN' ? 'REMOVE ALL' : 'TEMİZLE'} X</p></Link>
                </section>
                <div className={styles.itemsContainer}>
                    {quoteBasket.map(quote =>   <div className={styles.item}>   
                                                    <Link to={`/catalogue/${quote.id.toLowerCase().split(" ").join('-')}`} key={quote.id} className={styles.itemLink}>
                                                        <img src={quote.image} alt="quoted product" className={styles.image}/>
                                                        <h5 className={styles.name}>{language === 'EN' ? quote.name : (quote.nameTR !== 'NA' ? quote.nameTR : quote.name)}</h5>
                                                        {quote.type === 'bottle' || quote.type === 'jar' ? <p className={styles.volume}>({quote.volume}ml)</p> : null}
                                                    </Link>
                                                    <button onClick={() => dispatch(removeQuote(quote))} className={styles.button}>X</button>
                                                </div>  
                                    )}
                </div>
            </section> 
            <Form formtype='quote'/>                           
        </div>
    );
}

export default Quote;