import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

import bannerOleaEN from '../../images/BannerWarmEN.jpg';
import bannerOleaTR from '../../images/BannerWarmTR.jpg';
import bannerBiolioEN from '../../images/bannerBiolioEN.jpg';
import bannerBiolioTR from '../../images/bannerBiolioTR.jpg';

import pouchSprayBanner from '../../images/pouchSprayBanner.jpg';

import mobileBanner from '../../images/mobilePreziosa.png';
import mobilePouchSpray from '../../images/mobilePouchSpray.jpg';

import references from '../../images/referanslar.jpg';

import renderOliveOils from '../../images/renderOliveOils.jpg';
import renderWines from '../../images/renderWines.jpg';
import renderSparklings from '../../images/renderSparklings.jpg';
import renderAlcohols from '../../images/renderAlcohols.jpg';
import renderFoods from '../../images/renderFoods.jpg';


import Newsletter from '../../commons/Newsletter';

import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, selectOrientation, selectWidth, changeHomeLoaded, selectHomeLoaded } from '../../commons/appSlice';
import { resetFilters, updateProducts, changeFirstTime, toggleFilter} from '../catalogue/filterSlice';

function Home() { 

  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const width = useSelector(selectWidth);
  const homeLoaded = useSelector(selectHomeLoaded);
  // const orientation = useSelector(selectOrientation);

  // Handle Video
  // function handleVideo() {
  //   const video = document.getElementById("video_background");
  //   setTimeout(function() {
  //     video.play()
  //   }, 1000);
  // }
  // React.useEffect(() => handleVideo(), []);

  // Fade Blank White
  function fadeBlank() {
    // document.getElementById("blankWhite").fade();
      var element = document.getElementById("blankWhite");
      
      if (element) {
        var op = 1;  // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.01){
                clearInterval(timer);
                element.style.display = 'none';
            }
            element.style.opacity = op;
            // element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.01;
        }, 4);
      }

  }

  // Scroll Nav Color Change
  function handleScroll(e) {
    if (e.target.scrollTop > 100) {
      document.getElementById("nav").style.backgroundColor = 'white';
    } else {
      document.getElementById("nav").style.backgroundColor = 'transparent';
    }
  };

  // Change document title
  React.useEffect(() => {
    document.title = language === 'EN' ? 'Feniks Packaging | Olive Oil, Vinegar and Wine Packaging' : 'Feniks Ambalaj | Zeytinyağı, Sirke ve Şarap Ambalajları';
  }, [language]);

  return (
    <div className={styles.Home} onScroll={handleScroll}>

        {!homeLoaded ? <div id="blankWhite" className={styles.blankWhite}></div> : null}
        {/* <video width="100%" height="100%" muted className={styles.video} id="video_background">
          <source src={animDeneme} type="video/mp4"/>
        </video> */}

        {width < 800 ?
          <div className={styles.mobileBannerBigContainer}>
            <div className={styles.mobileBannerContainer}>
              <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.mobileBanner} src={mobileBanner} alt="banner"/>
              {/* <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.mobileBannerFade}/> */}
              <div className={styles.bannerText}>
                {language === 'EN' ? <h1 className={styles.bannerTitleEN}>‘COMPLETE SOLUTION’</h1> : <h1 className={styles.bannerTitle}>‘KOMPLE ÇÖZÜM’</h1>}
                {language === 'EN' ? <h4 className={styles.bannerTitleTwoEN}>FOR YOUR PACKAGING NEEDS</h4> : <h4 className={styles.bannerTitleTwo}>AMBALAJA DAİR HER ŞEY</h4>}
                <p className={styles.bannerP}>{language === 'EN' ? 'Let us handle every aspect of packaging from bottles, to label design, to printing, to boxing and to machines. Browse through our catalogue and solutions to find out more.' : 
                                        'Şişeden etiket tasarımına, kutulamadan makineye ambalajın her yönünü ele alıyoruz. Daha fazlasını öğrenmek için kataloğumuza ve çözümlerimize göz atabilirsiniz.'}</p>
                <Link to="/solutions" className={styles.buttonLeft} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'LEARN MORE' : 'KEŞFET'}</Link>
              </div> 
            </div>
            <div className={styles.mobileBannerContainer2}>
                <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.mobileBanner2} src={mobilePouchSpray} alt="banner"/>
                <img className={styles.mobileBannerFade}/>
                <div className={styles.bannerText}>
                  {language === 'EN' ? <h1 className={styles.bannerTitleEN}>OUR NEW PRODUCTS</h1> : <h1 className={styles.bannerTitle}>YENİ ÜRÜNLERİMİZ</h1>}
                  {language === 'EN' ? <h4 className={styles.bannerTitleTwoEN}>SPRAY AND POUCH</h4> : <h4 className={styles.bannerTitleTwo}>SPREY VE POUCH</h4>}
                  <p className={styles.bannerP}>{language === 'EN' ? 'Discover our new disruptive products, Spray and Pouch. Introduce yourself to our innovative products, designed for convenience and precision in cooking, marinating, and salad preparation. The Pouch offers a no-drip alternative to tin can packaging, while the Spray provides a controlled and measured application of oils.' : 
                                        'Ezber bozan yeni ürünlerimiz Sprey ve Pouch ile tanışın. Taşıma kolaylığı ve sızdırmayışı ile, Pouch teneke ambalajı rafa kaldırır ve sprey ise fırında pişirme, marine etme ve salata hazırlama sırasında yağların daha kontrollü ve ölçekli uygulanmasını sağlar.'}</p>
                  <Link to="/catalogue" className={styles.buttonLeft} onClick={() => {window.scrollTo(0, 0); dispatch(changeFirstTime()); dispatch(toggleFilter(['sector', 'oliveOil'])); dispatch(toggleFilter(['type', 'bottle'])); dispatch(toggleFilter(['material', 'laminated'])); dispatch(toggleFilter(['material', 'tin'])); dispatch(updateProducts());}}>{language === 'EN' ? 'LEARN MORE' : 'KEŞFET'}</Link>
                </div> 
            </div>
          </div>

        :

          <div className={styles.bannerContainer}>

            <div className={styles.firstBanner}>
              {language === 'EN' ? <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.bannerImg} src={bannerOleaEN} alt="olive oil bottle"/> : <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.bannerImg} src={bannerOleaTR} alt="zeytinyağı şişesi"/>}
              <div className={styles.bannerText}>
                {language === 'EN' ? <h1 className={styles.bannerTitleEN}>‘COMPLETE SOLUTION’</h1> : <h1 className={styles.bannerTitle}>‘KOMPLE ÇÖZÜM’</h1>}
                {language === 'EN' ? <h4 className={styles.bannerTitleTwoEN}>FOR YOUR PACKAGING NEEDS</h4> : <h4 className={styles.bannerTitleTwo}>AMBALAJA DAİR HER ŞEY</h4>}
                <p className={styles.bannerP}>{language === 'EN' ? 'Let us handle every aspect of packaging from bottles, to label design, to printing, to boxing and to machines. Browse through our catalogue and solutions to find out more.' : 
                                        'Şişeden etiket tasarımına, kutulamadan makineye ambalajın her yönünü ele alıyoruz. Daha fazlasını öğrenmek için kataloğumuza ve çözümlerimize göz atabilirsiniz.'}</p>
                <Link to="/solutions" className={styles.buttonLeft} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'LEARN MORE' : 'KEŞFET'}</Link>
              </div> 
            </div>
            <div className={styles.secondBanner}>
              {language === 'EN' ? <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.bannerImg} src={pouchSprayBanner} alt="olive oil bottle"/> : <img onLoad={() => {dispatch(changeHomeLoaded(true)); fadeBlank();}} className={styles.bannerImg} src={pouchSprayBanner} alt="zeytinyağı şişesi"/>}
              <div className={styles.bannerText}>
                {language === 'EN' ? <h1 className={styles.bannerTitleEN}>OUR NEW PRODUCTS</h1> : <h1 className={styles.bannerTitle}>YENİ ÜRÜNLERİMİZ</h1>}
                {language === 'EN' ? <h4 className={styles.bannerTitleTwoEN}>SPRAY AND POUCH</h4> : <h4 className={styles.bannerTitleTwo}>SPREY VE POUCH</h4>}
                <p className={styles.bannerP}>{language === 'EN' ? 'Discover our new disruptive products, Spray and Pouch. Introduce yourself to our innovative products, designed for convenience and precision in cooking, marinating, and salad preparation. The Pouch offers a no-drip alternative to tin can packaging, while the Spray provides a controlled and measured application of oils.' : 
                                        'Ezber bozan yeni ürünlerimiz Sprey ve Pouch ile tanışın. Taşıma kolaylığı ve sızdırmayışı ile, Pouch teneke ambalajı rafa kaldırır ve sprey ise fırında pişirme, marine etme ve salata hazırlama sırasında yağların daha kontrollü ve ölçekli uygulanmasını sağlar.'}</p>
                <Link to="/catalogue" className={styles.buttonLeft} onClick={() => {window.scrollTo(0, 0); dispatch(changeFirstTime()); dispatch(toggleFilter(['sector', 'oliveOil'])); dispatch(toggleFilter(['type', 'bottle'])); dispatch(toggleFilter(['material', 'laminated'])); dispatch(toggleFilter(['material', 'tin'])); dispatch(updateProducts());}}>{language === 'EN' ? 'LEARN MORE' : 'KEŞFET'}</Link>
              </div> 
            </div>
          
        </div>
          
        }
        {/* <p className={styles.sectorsHeader}>{language === 'EN' ? '-BROWSE BY SECTOR-' : '-SEKTÖRLER-'}</p> */}

        <div className={styles.Sectors}>
            <Link to='/catalogue' className={styles.sector} onClick={() => {dispatch(resetFilters(['oliveOil', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderOliveOils} alt="zeytinyağı şişeleri ve sirke şişeleri" className={styles.renderSectors}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'OLIVE OIL AND VINEGAR' : 'ZEYTİNYAĞI VE SİRKELER'}</p>
            </Link>
            <Link to='/catalogue' className={styles.sector} onClick={() => {dispatch(resetFilters(['wine', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderWines} alt="şarap şişeleri" className={styles.renderSectors}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'STILL WINE' : 'ŞARAPLAR'}</p>
            </Link>
            <Link to='/catalogue' className={styles.sector} onClick={() => {dispatch(resetFilters(['sparkling', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderSparklings} alt="şampanya şişeleri" className={styles.renderSectors}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'SPARKLING WINE' : 'KÖPÜKLÜ ŞARAPLAR'}</p>
            </Link>
            <Link to='/catalogue' className={styles.sector} onClick={() => {dispatch(resetFilters(['alcohol', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>  
                <img src={renderAlcohols} alt="alkol şişeleri" className={styles.renderSectors}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'ALCOHOLIC BEVERAGES' : 'ALKOLLÜ İÇECEKLER'}</p>
            </Link>
            <Link to='/catalogue' className={styles.sector} onClick={() => {dispatch(resetFilters(['various', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderFoods} alt="gıda kavanozları" className={styles.renderSectors}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'VARIOUS FOOD' : 'GIDA ÜRÜNLERİ'}</p>
            </Link>
        </div>
        <div className={styles.references}>
          <h3 className={styles.referencesTitle}>- {language === 'EN' ? 'REFERENCES' : 'REFERANSLAR'} -</h3>
          <img className={styles.referencesImage} src={references} alt="references" />
        </div>
          
        <Newsletter />

        {/* <Link to="/catalogue" className={styles.buttonLeft} onClick={() => window.scrollTo(0, 0)}>PRODUCTS ></Link> */}
    </div>
  );
}

export default Home;