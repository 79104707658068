import React from 'react';
import styles from './Product.module.css';

import colorsDict from './colors';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';


function Product(props) {

    const language = useSelector(selectLanguage);


    return (
        <div className={styles.Product}>
            <img src={props.image} alt={props.name} className={styles.image}/>
            <h2 className={styles.name}>{language === 'EN' ? props.name : (props.nameTR !== 'NA' ? props.nameTR : props.name)}</h2>
            {(props.type === 'bottle' || props.type === 'jar') && !(props.material === 'tin' || props.neckFinish[0] === 'tap') ? <p className={styles.volume}>{props.volume}ml</p> : null}
            <div className={styles.colorsContainer}>
                {(props.type === 'bottle' || props.type === 'jar') && !(props.material === 'tin' || props.neckFinish[0] === 'tap') ? props.colors.map(color => <p key={color} className={styles.colors} style={{backgroundColor: colorsDict[color]}}></p>) : null}
            </div>
        </div>
    )
}

export default Product;