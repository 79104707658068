import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    basket: [],
    quoteBoxIsVisible: false
}

export const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        addQuote: (state, action) => {
            const duplicateIndex = state.basket.findIndex(item => item.id === action.payload.id);
            if (duplicateIndex === -1) {
                state.basket.push(action.payload);    
            }
        },
        removeQuote: (state, action) => {
            const removeIndex = state.basket.findIndex(item => item.id === action.payload.id);
            state.basket.splice(removeIndex, 1);
        },
        clearQuotes: (state) => {
            state.basket = [];
        },
        toggleQuoteBox: (state, action) => {
            if (action.payload ===undefined) {
                state.quoteBoxIsVisible = !(state.quoteBoxIsVisible);
            } else {
                state.quoteBoxIsVisible = action.payload;
            }

            if (window.innerWidth < 800){
                state.quoteBoxIsVisible = false;
            }
        }
    }
})

export const {addQuote, removeQuote, clearQuotes, toggleQuoteBox} = quoteSlice.actions;

export const selectBasket = (state) => state.quote.basket;
export const selectQuoteBoxIsVisible = (state) => state.quote.quoteBoxIsVisible;

export default quoteSlice.reducer;