import React from 'react';
import styles from './Blog.module.css';
import logoGif from '../../images/logo_trans.gif';
import allBottles from '../../images/allbottles.jpg';
import fuarBiolio from '../../images/bannerFuarBiolio.jpg';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';

function Blog() {

  const language = useSelector(selectLanguage);

  const [loading, setLoading] = React.useState(true);

  function handleLoad() {
    setLoading(false);
  }

  // Change document title etc.
  React.useEffect(() => {
    document.title = language === 'EN' ? 'Blog' : 'Blog';
    document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Find out what we have been up to.' : 'Neler yaptığımızı öğrenin.';
    document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Blog' : 'Blog';
  }, [language]);

  // Scroll Nav Color Change
  function handleScroll(e) {
    if (e.target.scrollTop > 500) {
      document.getElementById("nav").style.backgroundColor = 'white';
    } else {
      document.getElementById("nav").style.backgroundColor = 'transparent';
    }
  };

  return (
    <div className={styles.Blog} onScroll={handleScroll}>

      <div className={styles.bannerContainer}>
        <img src={fuarBiolio} alt="zeytinyağı şişesi" className={styles.image}/>
        <div className={styles.fade}></div>
        <h1 className={styles.header}>{language === 'EN' ? 'BLOG' : 'BLOG'}</h1> 
      </div>

      <section className={styles.vlogContainer}>

        <div className={styles.iFrameContainer}>
          { loading ? <img className={styles.loading} src={logoGif} alt="logo gif" /> : null }
          <iframe className={styles.iFrame} onLoad={handleLoad} width="560" height="315" src="https://www.youtube.com/embed/MsWG27QrbdM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

        <section className={styles.vlogText}>
          <h2>{language === 'EN' ? 'An Interview with Our CEO' : "CEO'muzla Fuarda Röportaj"}</h2>
          <p className={styles.p}>{language === 'EN' ? 'Our founder Tuncay Altinok talked about our company and conveyed his thoughts on the olive oil sector in the interview he gave to FuarTV at OLIVIA Istanbul.' 
              : 'Kurucumuz Tuncay Altınok, OLIVIA İstanbul’da FuarTV’ye vermiş olduğu röportajında firmamızın faaliyetlerinden bahsetti ve zeytinyağı sektörü hakkındaki düşüncelerini aktardı.'}</p>
        </section>
        
      </section>

      <section className={styles.vlogContainer2}>

        <div className={styles.iFrameContainer}>
          { loading ? <img className={styles.loading} src={logoGif} alt="logo gif" /> : null }
          <iframe className={styles.iFrame} onLoad={handleLoad} width="560" height="315" src="https://www.youtube.com/embed/Gsxl-o6TyGE?si=fm5jggu1pSUC31EM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

        <section className={styles.vlogText}>
          <h2>{language === 'EN' ? 'Our new product: Pouch' : 'Yeni ürünümuz: Pouch'}</h2>
          <p className={styles.p}>{language === 'EN' ? 'The impact-resistant Pouch (Bag) can be easily stored and carried. In addition, it protects the products against UV rays and provides a long shelf life. Thanks to its practical design, it can be easily filled manually or with the help of a machine. There is a nitrogen gas injection feature in the filling machine to ensure it stands upright and full on the shelf. The Pouch (Bag) is now in stock with 1, 3, 5-liter capacities and in black, white, and kraft colors!' 
              : 'Darbelere karşı dayanıklı Pouch (Torba), kolay depolanabilir ve kolayca taşınır. Bunların yanı sıra Ürünleri UV ışınlarına karşı korur ve uzun raf ömrü sağlar. Pratik tasarımı sayesinde manuel olarak veya makine yardımıyla kolayca doldurulur. Rafta dik ve dolgun duruşu sağlamak için dolum makinasında azot gazı basma özelliği mevcuttur. Pouch (Torba) 1, 3, 5 litre hacimleriyle ve siyah, beyaz, kraft renkleriyle şimdi stokta!'}</p>
          <a href="https://feniksambalaj.com.tr/catalogue/pouch-for-olive-oil-0" target="_blank" rel="noreferrer"><button className={styles.button}>{language === 'EN' ? 'BUY' : 'SATIN AL'}</button></a>
        </section>

        </section>

      <section className={styles.vlogContainer3}>

        <div className={styles.iFrameContainer}>
          { loading ? <img className={styles.loading} src={logoGif} alt="logo gif" /> : null }
          <iframe className={styles.iFrame} onLoad={handleLoad} width="560" height="315" src="https://www.youtube.com/embed/gZzjPv5bEgs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

        <section className={styles.vlogText}>
          <h2>{language === 'EN' ? 'Packaging Talks' : 'Ambalaj Sohbetleri'}</h2>
          <p className={styles.p}>{language === 'EN' ? 'As Feniks, we always stand by our customers, and informing our customers is of great importance to us. For this purpose, our founder Tuncay Altınok shares his experiences gained over the years in the video series, "Packaging Talks".' 
              : 'Biz Feniks olarak her zaman müşterilerimizin yanındayız ve bu misyonumuzun parçası olarak müşterilerimizin bilgilendirilmesi bizim için çok büyük önem taşır. Bu amaç doğrultusunda kurucumuz Tuncay Altınok, yıllar içinde kazandığı deneyimleri "Ambalaj Sohbetleri" adlı video serisinde paylaşıyor.'}</p>
          <a href="https://www.youtube.com/channel/UCdDQ1hSudEQ9VhkSJN9GuBg" target="_blank" rel="noreferrer"><button className={styles.button}>{language === 'EN' ? 'WATCH ALL' : 'HEPSİNİ İZLE'}</button></a>
        </section>

      </section>

    </div>
  );
}

export default Blog;