import React, { lazy } from 'react';
import styles from './Design.module.css';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';
import allBottles from '../../images/allbottles.jpg';
import whiteArrow from '../../images/whiteArrow.png';
import designImage from '../../images/designImage.jpg';
import feniksBaski from '../../images/feniksBaski.jpg';

function Design() {

    const language = useSelector(selectLanguage);

    // Window scroll top

    React.useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // Dynamically change meta tags
    React.useEffect(() => {
        document.title = language === 'EN' ? 'Design' : 'Tasarım';
        document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Check out all the design solutions that we offer.' : 'Sunduğumuz tüm tasarım çözümlerini inceleyin.';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'LABEL, PRINT & DESIGN' : 'ETİKET, BASKI VE TASARIM';
    }, [language]);

    // Scroll Arrow Disappear and Nav Bar
    function handleScroll(e) {
        if (e.target.scrollTop > 540) {
        document.getElementById("whiteArrow").style.display = 'none';
        } else {
        document.getElementById("whiteArrow").style.display = 'block';
        }

        document.getElementById("nav").style.backgroundColor = 'transparent';

        const feniksBaskiContainer = document.getElementById("feniksBaskiContainer");
        const designPage = document.getElementById("designPage");

        if (e.target.scrollTop > designPage.offsetHeight/2.5 && feniksBaskiContainer.offsetLeft <= 0) {
            let leftValue = e.target.scrollTop*(0.92) - Math.abs(e.target.offsetWidth);
            if (leftValue > 0) { leftValue = 0};
            feniksBaskiContainer.style.left = `${leftValue}` + "px";
        }

    };

    return (
        <div className={styles.Design} onScroll={handleScroll} id="designPage">
            <div className={styles.bannerContainer}>
                <img src={allBottles} alt="şişeler" className={styles.image}/>
                <h1 className={styles.header}>{language === 'EN' ? 'DESIGN' : 'TASARIM'}</h1> 
                <img src={whiteArrow} alt="white arrow" className={styles.whiteArrow} id="whiteArrow"/>
            </div>

            
            <div className={styles.textContainer}>
                <img src={designImage} alt="banner" className={styles.designImage}/>
                {language === 'TR' ?
                                <div className={styles.text1}>
                                    <h2>ETİKET, BASKI ve BOYAMA</h2>                              
                                    Tasarımlarınıza göre size uygun etiket ve etiketleme makinaları sunuyoruz.
                                    Daha kaliteli bir görünüm elde etmek isterseniz tasarımlarınızı şişelere baskı yoluyla da uyguluyoruz.
                                    Bir adım daha öteye gidip şişelerinizi boyayıp ve ardından üzerlerine baskı yapıyoruz.
                                </div>
                            :
                                <div className={styles.text1}>   
                                    <h2>LABEL, PRINT AND DYEING</h2>
                                    According to your designs, we provide you with the labels and the appropriate labeling machines.
                                    If you wish to go for a more quality look, we can also have your designs printed on the bottles
                                    before sending them your way. We can even take another step by dyeing the bottles before printing
                                    the designs on them.
                                </div>
                }

                {language === 'TR' ?
                                <div className={styles.text2}>
                                    <h2>TASARIM</h2>
                                    Tasarımın ürününüzün, kimliğinizin ve satışınızın hayati bir parçası olduğunun farkındayız.
                                    İhtiyaçlarınıza gore mümkün olan en iyi sunumu oluşturmak icin sizlere logonuzu 
                                    ve etiketlerinizi tasarlama ve hatta şişeler üzerinde kişiselleştirilmiş kabartmalar uygulama konusunda yardımcı oluyoruz.
                                    
                                </div >
                            :
                                <div className={styles.text2}>
                                    <h2>DESIGN</h2>
                                    As we are aware that design is a vital part of your product, identitiy and sales. 
                                    We help you design your logo, labels and even have personalized embossings on the bottles, thereby 
                                    creating the best possible presentation for your needs.
                                </div>
                }
            </div>
            
            <div className={styles.feniksBaskiContainer} id="feniksBaskiContainer">
                <img src={feniksBaski} alt="printed bottles" className={styles.feniksBaski}/>
            </div>
            

        </div>
    );
};

export default Design;  