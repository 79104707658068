import React from 'react';
import styles from './Language.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, toggleLanguage } from './appSlice';
import { toggleLanguageFilter } from '../routes/catalogue/filterSlice';


function Language() {

    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);


    return (
        <div className={styles.container}>
            <p className={styles.EN} onClick={() => {dispatch(toggleLanguage('EN')); dispatch(toggleLanguageFilter('EN'));}} style={{color:(language === 'EN' ? 'rgb(218, 197, 84)' : '#515150')}} >EN</p>
            <p className={styles.TR} onClick={() => {dispatch(toggleLanguage('TR')); dispatch(toggleLanguageFilter('TR'));}} style={{color:(language === 'TR' ? 'rgb(218, 197, 84)' : '#515150')}} >TR</p>
        </div>
    )
};

export default Language;