import React from 'react';
import styles from './Filter.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Slider from './Slider';

import colorsDict from './colors';

import {
    selectOliveOil, selectWine,
    selectSparkling, selectAlcohol,
    selectVarious, selectBottle, selectJar,
    selectCap, selectCapsule, selectOuter,
    selectRound, selectSquare,
    selectElliptical, selectRectangular,    
    selectGlass, selectMetal, selectPet, selectPP, selectPE, 
    selectPlastic, selectAluminum,
    selectTin, selectVN,
    selectVE, selectAG, selectMB, selectBN, selectEB, selectXB, selectRB, 
    selectNDop, selectRopp315, selectRopp35, selectBvs30, selectCork,
    selectStopper, selectGpi, selectGcmi, selectTwistOff, selectTm,
    selectRical29, selectRical32, selectSDop, selectDopTE, selectRingTE,
    selectNoSecurity, selectAdvancedVisible,
    selectMachine, selectVacuumFiller,
    selectVolumetricFiller, selectCapper,selectLabeler,
    selectComplementary, selectCustomSolutions, selectMobileFilterVisible, selectRopp28, 
    selectRopp25, selectRopp24, selectCorkFA, selectCorkFP, selectVinolok, selectCrown26, 
    selectCrown29, selectFc29, selectGrinder, selectRopp18, selectWooden, selectRegion, selectCosmetics, selectLaminated, selectTap, selectSpray,
    selectBordeaux, selectBurgundy, selectRenana
} from './filterSlice';   

import { changeFirstTime, selectFirstTime, resetOnly, toggleMobileFilter,
         changeSearch, resetFilters, toggleFilter, 
         selectSearch, updateProducts, toggleRegion} from './filterSlice';
import { selectLanguage, selectWidth } from '../../commons/appSlice';


function Filter() {

    const language = useSelector(selectLanguage);
    const width = useSelector(selectWidth);

    const dispatch = useDispatch();
    const search = useSelector(selectSearch);
    const mobileFilterVisible = useSelector(selectMobileFilterVisible);
    const firstTime = useSelector(selectFirstTime);
    const advancedVisible = useSelector(selectAdvancedVisible);
    const region = useSelector(selectRegion);

    const oliveOil = useSelector(selectOliveOil);
    const wine = useSelector(selectWine);
    const sparkling = useSelector(selectSparkling);
    const alcohol = useSelector(selectAlcohol);
    const various = useSelector(selectVarious);
    const cosmetics = useSelector(selectCosmetics);
    const bottle = useSelector(selectBottle);
    const jar = useSelector(selectJar);
    const cap = useSelector(selectCap);
    const capsule = useSelector(selectCapsule);
    const machine = useSelector(selectMachine);
    const outer = useSelector(selectOuter);
    const vacuumFiller = useSelector(selectVacuumFiller);
    const volumetricFiller = useSelector(selectVolumetricFiller);
    const capper = useSelector(selectCapper);
    const labeler = useSelector(selectLabeler);
    const complementary = useSelector(selectComplementary);
    const customSolutions = useSelector(selectCustomSolutions);
    const bordeaux = useSelector(selectBordeaux);
    const burgundy = useSelector(selectBurgundy);
    const renana = useSelector(selectRenana);
    const round = useSelector(selectRound);
    const square = useSelector(selectSquare);
    const elliptical = useSelector(selectElliptical);
    const rectangular = useSelector(selectRectangular);
    const glass = useSelector(selectGlass);
    const metal = useSelector(selectMetal);
    const laminated = useSelector(selectLaminated);
    const pet = useSelector(selectPet);
    const pp = useSelector(selectPP);
    const pe = useSelector(selectPE);
    const plastic = useSelector(selectPlastic);
    const aluminum = useSelector(selectAluminum);
    const tin = useSelector(selectTin);
    const wooden = useSelector(selectWooden);
    const dopN = useSelector(selectNDop);
    const ropp18 = useSelector(selectRopp18);
    const ropp24 = useSelector(selectRopp24);
    const ropp25 = useSelector(selectRopp25);
    const ropp28 = useSelector(selectRopp28);
    const ropp315 = useSelector(selectRopp315);
    const ropp35 = useSelector(selectRopp35);
    const bvs30 = useSelector(selectBvs30);
    const corkFA = useSelector(selectCorkFA);
    const corkFP = useSelector(selectCorkFP);
    const vinolok = useSelector(selectVinolok);
    const stopper = useSelector(selectStopper);
    const gpi = useSelector(selectGpi);
    const gcmi = useSelector(selectGcmi);
    const twistOff = useSelector(selectTwistOff);
    const grinder = useSelector(selectGrinder);
    const rical29 = useSelector(selectRical29);
    const rical32 = useSelector(selectRical32);
    const tm = useSelector(selectTm);
    const crown26 = useSelector(selectCrown26);
    const crown29 = useSelector(selectCrown29);
    const fc29 = useSelector(selectFc29);
    const tap = useSelector(selectTap);
    const spray = useSelector(selectSpray);
    const dopS = useSelector(selectSDop);
    const dopTE = useSelector(selectDopTE);
    const ringTE = useSelector(selectRingTE);
    const noSecurity = useSelector(selectNoSecurity);
    const vn = useSelector(selectVN);
    const ve = useSelector(selectVE);
    const ag = useSelector(selectAG);
    const mb = useSelector(selectMB);
    const bn = useSelector(selectBN);
    const eb = useSelector(selectEB);
    const xb = useSelector(selectXB);
    const rb = useSelector(selectRB);


    // Toggles mobile filter
    React.useEffect(() => {
        const filter = document.getElementById("filter");
        if (width < 800){
            if (mobileFilterVisible) {
                filter.style.display = 'block';
            } else {
                filter.style.display = 'none';
            }
        }

    }, [mobileFilterVisible]);

    // Toggles advanced filter for mobile

    // React.useEffect(() => {
    //     if (width < 800){
    //         dispatch(toggleAdvanced());
    //     }
    // }, []);

    // Zoom out after mobile keyboard
    function toggleZoomScreen() {
        if (width < 800){
            document.body.style.zoom = "100%";
        }
    }


    // Toggles sub-filters off when not visible
    React.useEffect(() => {
        if (!bottle) {
            dispatch(resetOnly('shape'));
        }   
        if (!(bottle || jar)) {
            dispatch(resetOnly('material'));
            dispatch(resetOnly('colors'));
            dispatch(resetOnly('sliders'));
        }
        if (!cap) {
            // dispatch(resetOnly('material'));
        }
        if (!machine) {
            dispatch(resetOnly('machineType'));
        }
        dispatch(updateProducts());
    }, [bottle, jar, cap, machine]);

    // Toggled color style
    const toggledColor = 'rgba(218, 197, 84, 1)';

    // Changes url and title according to sector
    React.useEffect(() => {
        if (oliveOil) {
            window.history.pushState({}, null, "/catalogue/oliveOil");
            document.title = language === 'EN' ? 'Products | Olive Oil and Vinegar Packaging' : 'Ürünler | Zeytinyağı ve Sirke Ambalajları';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your olive oil or vinegar.' : 'Zeytinyağı veya sirkenize en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }   
        else if (wine) {
            window.history.pushState({}, null, "/catalogue/wine");
            document.title = language === 'EN' ? 'Products | Wine Packaging' : 'Ürünler | Şarap Ambalajları';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your wine.' : 'Şarabınıza en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }
        else if (sparkling) {
            window.history.pushState({}, null, "/catalogue/sparkling");
            document.title = language === 'EN' ? 'Products | Sparkling Wine Packaging' : 'Ürünler | Köpüklü Şarap Ambalajları';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your sparkling wine.' : 'Köpüklü şarabınıza en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }
        else if (alcohol) {
            window.history.pushState({}, null, "/catalogue/alcohol");
            document.title = language === 'EN' ? 'Products | Alcohol Packaging' : 'Ürünler | Alkol Ambalajları';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your alcoholic beverage.' : 'Alkollü içeceğinize en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }
        else if (various) {
            window.history.pushState({}, null, "/catalogue/various");
            document.title = language === 'EN' ? 'Products | Various Food Packaging' : 'Ürünler | Çeşitli Gıda Ambalajları';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your food product.' : 'Gıda ürününüze en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }
        else {
            document.title = language === 'EN' ? 'Products' : 'Ürünler';
            document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your product.' : 'Ürününüze en uygun ambalajı bulmak için kataloğumuza göz atın.';
        }
    }, [oliveOil, wine, sparkling, alcohol, various]);

    return (
        <div className={styles.filterContainer} id="filter" >
            <div className={styles.Filter} >
               
                <input className={styles.search} type='text' name='search' placeholder={language === 'EN' ? 'Search for a product here...' : 'Ürün arayın...'} value={search} autoComplete="off" onInput={(event) => {dispatch(changeSearch(event.target.value)); dispatch(updateProducts()); dispatch(changeFirstTime(false))}} onKeyDown={(event) => {if(event.key === 'Enter'){dispatch(toggleMobileFilter()); document.activeElement.blur(); toggleZoomScreen();}}}/>
                <p className={styles.reset} onClick={() => {dispatch(resetFilters()); dispatch(changeSearch(""));dispatch(changeFirstTime(true)); dispatch(updateProducts())}}>{language === 'EN' ? 'CLEAR ALL FILTERS  X' : 'TEMİZLE  X'}</p>
                <div className={styles.region}>
                    <p className={styles.regionText}>{language === 'EN' ? 'REGION:' : 'BÖLGE:'}</p>
                    <div className={styles.regionAnswer}>
                        <p className={styles.regionTR} onClick={() => {dispatch(toggleRegion('TR')); dispatch(updateProducts());}} style={{color:(region === 'TR' ? 'rgb(218, 197, 84)' : '#515150')}}>{language === 'EN' ? 'TURKEY' : 'TÜRKİYE'}</p>
                        <p className={styles.regionINT} onClick={() => {dispatch(toggleRegion('INT')); dispatch(updateProducts());}} style={{color:(region === 'INT' ? 'rgb(218, 197, 84)' : '#515150')}}>{language === 'EN' ? 'INTERNATIONAL' : 'ULUSLARARASI'}</p>
                     </div>
                </div>
                
                { !firstTime ?
                    <section>
                        <h5>{language === 'EN' ? 'SECTOR' : 'SEKTÖR'}</h5> 
                        <ul className={styles.ulist}>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('sector')); dispatch(resetOnly('sliders')); dispatch(toggleFilter(['sector', 'oliveOil'])); dispatch(updateProducts())}}><p className={styles.toggled} style={oliveOil ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'OLIVE OIL AND VINEGAR' : 'ZEYTİNYAĞI VE SİRKELER'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('sector')); dispatch(resetOnly('sliders')); dispatch(toggleFilter(['sector', 'wine'])); dispatch(updateProducts())}}><p className={styles.toggled} style={wine ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'STILL WINE' : 'ŞARAPLAR'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('machineType')); dispatch(resetOnly('sliders')); dispatch(resetOnly('sector')); dispatch(toggleFilter(['sector', 'sparkling'])); dispatch(toggleFilter(['type', 'machine', false])); dispatch(updateProducts())}}><p className={styles.toggled} style={sparkling ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'SPARKLING WINE' : 'KÖPÜKLÜ ŞARAPLAR'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('sector')); dispatch(resetOnly('sliders')); dispatch(toggleFilter(['sector', 'alcohol'])); dispatch(updateProducts())}}><p className={styles.toggled} style={alcohol ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'ALHCOHOLIC BEVERAGES' : 'ALKOLLÜ İÇECEKLER'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('sector')); dispatch(resetOnly('sliders')); dispatch(toggleFilter(['sector', 'various'])); dispatch(toggleFilter(['type', 'capsule', false])); dispatch(updateProducts())}}><p className={styles.toggled} style={various ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'VARIOUS FOOD' : 'GIDA ÜRÜNLERİ'}</p></li>
                            {region === 'INT' ? <li className={styles.list} onClick={() => {dispatch(resetOnly('sector')); dispatch(resetOnly('sliders')); dispatch(resetOnly('type')); dispatch(toggleFilter(['sector', 'cosmetics'])); dispatch(updateProducts())}}><p className={styles.toggled} style={cosmetics ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'COSMETICS' : 'KOZMETİK'}</p></li> : null}
                        </ul>
                    </section>
                : null} 
                        
                { !firstTime ?
                    <section>
                        <h5>{language === 'EN' ? 'PRODUCT TYPE' : 'ÜRÜN TİPİ'}</h5> 
                        <ul className={styles.ulist}>
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'bottle'])); dispatch(updateProducts())}}><p className={styles.toggled} style={bottle ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'BOTTLES AND CONTAINERS' : 'ŞİŞE VE KAPLAR'}</p></li>
                            {various ? <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'jar'])); dispatch(updateProducts())}}><p className={styles.toggled} style={jar ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'JAR' : 'KAVANOZ'}</p></li> : null}
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'cap'])); dispatch(updateProducts())}}><p className={styles.toggled} style={cap ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CAP' : 'KAPAK'}</p></li>
                            {!various ? <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'capsule'])); dispatch(updateProducts())}}><p className={styles.toggled} style={capsule ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CAPSULE' : 'KAPUŞON'}</p></li> : null}
                            {!sparkling ? <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'machine'])); dispatch(updateProducts())}}><p className={styles.toggled} style={machine ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'MACHINE' : 'MAKİNE'}</p></li> : null}
                            <li className={styles.list} onClick={() => {dispatch(resetOnly('type')); dispatch(toggleFilter(['type', 'outer'])); dispatch(updateProducts())}}><p className={styles.toggled} style={outer ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'OUTER PACKAGE' : 'DIŞ AMBALAJ'}</p></li>
                        </ul>
                    </section>
                : null}

                { machine && !sparkling ?
                    <section>
                        <h5>{language === 'EN' ? 'MACHINE TYPE' : 'MAKİNE TİPİ'}</h5>
                        <ul className={styles.ulist}>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'vacuumFiller'])); dispatch(updateProducts())}}><p className={styles.toggled} style={vacuumFiller ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'VACUUM FILLER' : 'VAKUMLU DOLUM MAKİNESİ'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'volumetricFiller'])); dispatch(updateProducts())}}><p className={styles.toggled} style={volumetricFiller ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'VOLUMETRIC FILLER' : 'HACİMSEL DOLUM MAKİNESİ'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'capper'])); dispatch(updateProducts())}}><p className={styles.toggled} style={capper ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CAPPER' : 'KAPATMA MAKİNESİ'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'labeler'])); dispatch(updateProducts())}}><p className={styles.toggled} style={labeler ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'LABELER' : 'ETİKETLEME MAKİNESİ'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'complementary'])); dispatch(updateProducts())}}><p className={styles.toggled} style={complementary ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'COMPLEMENTARY MACHINES' : 'TAMAMLAYICI MAKİNELER'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['machineType', 'customSolutions'])); dispatch(updateProducts())}}><p className={styles.toggled} style={customSolutions ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CUSTOM SOLUTIONS' : 'ÖZEL ÇÖZÜMLER'}</p></li>
                        </ul>                            
                    </section>
                : null}

                { bottle && wine ?
                    <section>
                        <h5>{language === 'EN' ? 'BOTTLE TYPE' : 'ŞİŞE TİPİ'}</h5>
                        <ul className={styles.ulistNeck}>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['bottleType', 'bordeaux'])); dispatch(updateProducts())}}><p className={styles.toggled} style={bordeaux ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'BORDEAUX' : 'BORDO'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['bottleType', 'burgundy'])); dispatch(updateProducts())}}><p className={styles.toggled} style={burgundy ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'BURGUNDY' : 'BURGONYA'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['bottleType', 'renana'])); dispatch(updateProducts())}}><p className={styles.toggled} style={renana ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'RENANA' : 'RENANA'}</p></li>
                        </ul>                
                    </section>
                : null}         

                { bottle ?
                    <section>
                        <h5>{language === 'EN' ? 'SHAPE' : 'ŞEKİL'}</h5>
                        <ul className={styles.ulistNeck}>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['shape', 'round'])); dispatch(updateProducts())}}><p className={styles.toggled} style={round ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'ROUND' : 'DAİRESEL'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['shape', 'square'])); dispatch(updateProducts())}}><p className={styles.toggled} style={square ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'SQUARE' : 'KARE'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['shape', 'elliptical'])); dispatch(updateProducts())}}><p className={styles.toggled} style={elliptical ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'ELLIPTICAL' : 'ELİPS'}</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['shape', 'rectangular'])); dispatch(updateProducts())}}><p className={styles.toggled} style={rectangular ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'RECTANGULAR' : 'DİKDÖRTGEN'}</p></li>
                        </ul>                
                    </section>
                : null}

                { (bottle || jar || cap) ?
                    <section>
                        <h5>{language === 'EN' ? 'MATERIAL' : 'MALZEME TİPİ'}</h5>
                        { (bottle || jar) ?
                            <ul className={styles.materials}>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'glass'])); dispatch(updateProducts())}}><p className={styles.toggled} style={glass ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'GLASS' : 'CAM'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'tin'])); dispatch(updateProducts())}}><p className={styles.toggled} style={tin ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'TIN' : 'TENEKE'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'laminated'])); dispatch(updateProducts())}}><p className={styles.toggled} style={laminated ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'LAMINATED' : 'LAMİNE'}</p></li>
                                {/* <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'pet'])); dispatch(updateProducts())}}><p className={styles.toggled} style={pet ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? '' : ''}PET</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'pp'])); dispatch(updateProducts())}}><p className={styles.toggled} style={pp ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? '' : ''}PP</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'pe'])); dispatch(updateProducts())}}><p className={styles.toggled} style={pe ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? '' : ''}PE</p></li> */}
                            </ul>
                        : null}
                        { cap ?
                            <ul className={styles.materials}>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'plastic'])); dispatch(updateProducts())}}><p className={styles.toggled} style={plastic ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'PLASTIC' : 'PLASTİK'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'aluminum'])); dispatch(updateProducts())}}><p className={styles.toggled} style={aluminum ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'ALUMINUM' : 'ALÜMİNYUM'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'metal'])); dispatch(updateProducts())}}><p className={styles.toggled} style={metal ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'METAL' : 'METAL'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'glass'])); dispatch(updateProducts())}}><p className={styles.toggled} style={glass ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'GLASS' : 'CAM'}</p></li>
                                <li className={styles.list} onClick={() => {dispatch(toggleFilter(['material', 'wooden'])); dispatch(updateProducts())}}><p className={styles.toggled} style={wooden ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'WOODEN' : 'AHŞAP'}</p></li>
                            </ul>
                        : null}
                    </section>
                : null}

                { (bottle || jar) ?
                    <section>
                        <h5>{language === 'EN' ? 'COLOR' : 'RENK'}</h5>
                        <ul className={styles.colors}>
                            <li className={styles.color} onClick={() => {dispatch(toggleFilter(['colors', 'vn'])); dispatch(toggleFilter(['colors', 've'])); dispatch(updateProducts())}}><p className={styles.toggled} style={vn ? {backgroundColor: colorsDict.vn, border: 'solid 2px rgb(218,197,84)'} : {backgroundColor: colorsDict.vn} }></p></li>
                            <li className={styles.color} onClick={() => {dispatch(toggleFilter(['colors', 'ag'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ag ? {backgroundColor: colorsDict.ag, border: 'solid 2px rgb(218,197,84)'} : {backgroundColor: colorsDict.ag} }></p></li>
                            <li className={styles.color} onClick={() => {dispatch(toggleFilter(['colors', 'mb'])); dispatch(updateProducts())}}><p className={styles.toggled} style={mb ? {backgroundColor: colorsDict.mb, border: 'solid 2px rgb(218,197,84)'} : {backgroundColor: colorsDict.mb} }></p></li>
                            <li className={styles.color} onClick={() => {dispatch(toggleFilter(['colors', 'bn'])); dispatch(toggleFilter(['colors', 'eb'])); dispatch(toggleFilter(['colors', 'xb'])); dispatch(updateProducts())}}><p className={styles.toggled} style={bn ? {backgroundColor: colorsDict.bn, border: 'solid 2px rgb(218,197,84)'} : {backgroundColor: colorsDict.bn} }></p></li>
                            <li className={styles.color} onClick={() => {dispatch(toggleFilter(['colors', 'rb'])); dispatch(updateProducts())}}><p className={styles.toggled} style={rb ? {backgroundColor: colorsDict.rb, border: 'solid 2px rgb(218,197,84)'} : {backgroundColor: colorsDict.rb} }></p></li>
                        </ul>
                    </section>
                : null}

                { (bottle || jar) ?
                    <section>
                        <h5>{language === 'EN' ? 'VOLUME' : 'HACİM'} (ml)</h5>
                        <Slider name='volume' minValue={0} maxValue={2000} unit={'ml'}/>
                    </section>
                : null}
                { (bottle || jar) ?
                    <section>
                        <h5>{language === 'EN' ? 'DIAMETER' : 'ÇAP'} (mm)</h5>
                        <Slider name='diameter' minValue={0} maxValue={200} unit={'mm'}/>
                    </section>
                : null}
                { (bottle || jar) ?
                    <section>
                        <h5>{language === 'EN' ? 'HEIGHT' : 'YÜKSEKLİK'} (mm)</h5>
                        <Slider name='height' minValue={0} maxValue={600} unit={'mm'}/>
                    </section>    
                : null}
                { (bottle || jar) ?
                    <section>
                        <h5>{language === 'EN' ? 'WEIGHT' : 'AĞIRLIK'} (mg)</h5>
                        <Slider name='weight' minValue={0} maxValue={1500} unit={'mg'}/>
                    </section>    
                : null}

                {/* { (bottle || jar || cap) ?
                    <p className={styles.advanced} style={advancedVisible ? {backgroundColor: toggledColor} : null } onClick={() => {dispatch(toggleAdvanced()); dispatch(resetOnly('neckFinish'));}}>{language === 'EN' ? 'ADVANCED FILTERS' : 'DETAYLI FİLTRELEME'}</p>
                : null} */}

                { (bottle || jar || cap)?
                    <section>
                        <h5>{language === 'EN' ? 'NECK FINISH' : 'AĞIZ TİPİ'}</h5>
                        <ul className={styles.ulistNeck}>
                            {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'dop'])); dispatch(updateProducts())}}><p className={styles.toggled} style={dopN ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'DOP' : 'DOP (BİLYELİ)'}</p></li> : null}
                            {oliveOil || alcohol || various ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'tap'])); dispatch(updateProducts())}}><p className={styles.toggled} style={tap ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'TAP' : 'MUSLUK'}</p></li> : null}
                            {oliveOil ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'spray'])); dispatch(updateProducts())}}><p className={styles.toggled} style={spray ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'SPRAY' : 'SPREY'}</p></li> : null}
                            {/* {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp18'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp18 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 18</p></li> : null}
                            {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp24'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp24 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 24</p></li> : null}
                            {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp25'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp25 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 25</p></li> : null}
                            {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp28'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp28 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 28</p></li> : null}
                            {oliveOil || alcohol || wine || sparkling ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp315'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp315 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 31.5</p></li> : null}
                            {oliveOil ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp35'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ropp35 ? {backgroundColor: toggledColor} : null }></p><p>ROPP 35</p></li> : null}
                            {alcohol || wine || sparkling ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'bvs30'])); dispatch(updateProducts())}}><p className={styles.toggled} style={bvs30 ? {backgroundColor: toggledColor} : null }></p><p>BVS 30</p></li> : null}
                            {alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'gpi'])); dispatch(updateProducts())}}><p className={styles.toggled} style={gpi ? {backgroundColor: toggledColor} : null }></p><p>GPI</p></li> : null}
                            {various ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'gcmi'])); dispatch(updateProducts())}}><p className={styles.toggled} style={gcmi ? {backgroundColor: toggledColor} : null }></p><p>GCMI</p></li> : null} */}
                            {!various ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'ropp18'])); dispatch(toggleFilter(['neckFinish', 'ropp24'])); dispatch(toggleFilter(['neckFinish', 'ropp25'])); dispatch(toggleFilter(['neckFinish', 'ropp28'])); dispatch(toggleFilter(['neckFinish', 'ropp315'])); 
                                                                                    dispatch(toggleFilter(['neckFinish', 'ropp35'])); dispatch(toggleFilter(['neckFinish', 'bvs30'])); dispatch(toggleFilter(['neckFinish', 'gpi'])); dispatch(toggleFilter(['neckFinish', 'gcmi'])); 
                                                                                    dispatch(updateProducts())}}><p className={styles.toggled} style={ropp18 ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'SCREW' : 'VİDALI'}</p></li> : null}
                            {wine ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'corkFA'])); dispatch(updateProducts())}}><p className={styles.toggled} style={corkFA ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CORK FA' : 'MANTAR FA'}</p></li> : null}
                            {wine ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'corkFB'])); dispatch(updateProducts())}}><p className={styles.toggled} style={corkFP ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'CORK FP' : 'MANTAR FP'}</p></li> : null}
                            {oliveOil || alcohol || wine ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'vinolok'])); dispatch(updateProducts())}}><p className={styles.toggled} style={vinolok ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'VINOLOK' : 'VİNOLOK'}</p></li> : null}
                            {oliveOil || alcohol ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'stopper'])); dispatch(updateProducts())}}><p className={styles.toggled} style={stopper ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'STOPPER' : 'TIPA'}</p></li> : null}
                            {various ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'twistOff'])); dispatch(updateProducts())}}><p className={styles.toggled} style={twistOff ? {backgroundColor: toggledColor} : null }></p><p>TWIST-OFF</p></li> : null}
                            {various && bottle ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'grinder'])); dispatch(updateProducts())}}><p className={styles.toggled} style={grinder ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'GRINDER' : 'ÖĞÜTÜCÜ'}</p></li> : null}
                            {/* {oliveOil ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'rical29'])); dispatch(updateProducts())}}><p className={styles.toggled} style={rical29 ? {backgroundColor: toggledColor} : null }></p><p>RICAL 29</p></li> : null}
                            {oliveOil ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'rical32'])); dispatch(updateProducts())}}><p className={styles.toggled} style={rical32 ? {backgroundColor: toggledColor} : null }></p><p>RICAL 32</p></li> : null} */}
                            {oliveOil ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'rical29'])); dispatch(toggleFilter(['neckFinish', 'rical32'])); dispatch(updateProducts())}}><p className={styles.toggled} style={rical32 ? {backgroundColor: toggledColor} : null }></p><p>{language === 'EN' ? 'SNAP-ON' : 'ÇAKMA'}</p></li> : null}
                            {alcohol || sparkling ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'crown26'])); dispatch(updateProducts())}}><p className={styles.toggled} style={crown26 ? {backgroundColor: toggledColor} : null }></p><p>CROWN 26</p></li> : null}
                            {alcohol || sparkling ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'crown29'])); dispatch(updateProducts())}}><p className={styles.toggled} style={crown29 ? {backgroundColor: toggledColor} : null }></p><p>CROWN 29</p></li> : null}
                            {alcohol || sparkling ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'fc29'])); dispatch(updateProducts())}}><p className={styles.toggled} style={fc29 ? {backgroundColor: toggledColor} : null }></p><p>FC29</p></li> : null}
                            {!sparkling && !various ? <li className={styles.list} onClick={() => {dispatch(toggleFilter(['neckFinish', 'tm'])); dispatch(updateProducts())}}><p className={styles.toggled} style={tm ? {backgroundColor: toggledColor} : null }></p><p>{language === 'TR' ? 'MEKANİK KAPAK': 'TAPPO MECANICO'}</p></li> : null}
                        </ul>                
                    </section>
                : null}

                {/* { cap ?
                    <section>
                        <h5>{language === 'EN' ? '' : ''}SECURITY</h5>
                        <ul className={styles.ulist}>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['security', 'dop'])); dispatch(updateProducts())}}><p className={styles.toggled} style={dopS ? {backgroundColor: toggledColor} : null }></p><p>DOP</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['security', 'dopTE'])); dispatch(updateProducts())}}><p className={styles.toggled} style={dopTE ? {backgroundColor: toggledColor} : null }></p><p>DOP TEMPER-EVIDENT</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['security', 'ringTE'])); dispatch(updateProducts())}}><p className={styles.toggled} style={ringTE ? {backgroundColor: toggledColor} : null }></p><p>RING TEMPER-EVIDENT</p></li>
                            <li className={styles.list} onClick={() => {dispatch(toggleFilter(['security', 'noSecurity'])); dispatch(updateProducts())}}><p className={styles.toggled} style={noSecurity ? {backgroundColor: toggledColor} : null }></p><p>NO SECURITY</p></li>
                        </ul>                
                    </section>               
                : null} */}

                <p className={styles.whiteSpace}>{"ddd"}</p>
                
            </div>
        </div>
    )
}

export default Filter;