import React from 'react';
import {Switch, Route, useLocation, Link, useHistory} from 'react-router-dom';
import styles from './App.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getProducts, resetFilters, toggleFilter, toggleLanguageFilter } from './routes/catalogue/filterSlice';
import { selectHomeLoaded, selectLanguage, updateWidth, selectWidth, toggleLanguage } from './commons/appSlice';

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import Nav from './commons/Nav';
import Footer from './commons/Footer';
import Home from './routes/home/Home';
import Catalogue from './routes/catalogue/Catalogue';
import ProductPage from './routes/catalogue/ProductPage';
import Solutions from './routes/solutions/Solutions';
import Design from './routes/print/Design.js';
import About from './routes/about/About';
import Blog from './routes/blog/Blog';
import Contact from './routes/contact/Contact';
import Quote from './routes/quote/Quote';
import QuoteBox from './routes/quote/QuoteBox';
import Comparison from './routes/quote/Comparison';
import PrivacyPolicy from './routes/legal/PrivacyPolicy';
import TermsOfUse from './routes/legal/TermsOfUse';
import CookiePolicy from './routes/legal/CookiePolicy';
import CookieSettings from './routes/legal/CookieSettings';
import Regions from './commons/Regions';
import NotFound from './routes/notFound/NotFound';



function App() {

  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const width = useSelector(selectWidth);
  const homeLoaded = useSelector(selectHomeLoaded);
  const location = useLocation();
  const history = useHistory();


  //Gets Products
  React.useEffect(() => dispatch(getProducts()), []);

  // Gets GeoLocation ans sets the language states
  const getLocation = async () => {
    try {
      const geo = await fetch('/admin/geoip', 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      });
      const data = await geo.json();

      if (data) {
        const language = data.country === 'TR' ? 'TR' : 'EN';
        dispatch(toggleLanguage(language));
        dispatch(toggleLanguageFilter(language));
      }
    } catch (error) {
      console.error('Error fetching geo location:', error);
    }
  };
  React.useEffect(() => getLocation(), []);

  // Loading GIF
  const loader = document.querySelector(".loader");
  React.useEffect(() => {
    if (loader && homeLoaded && location.pathname === "/") {
        loader.remove()
    }
  }, [homeLoaded]);
  React.useEffect(() => {
    if (location.pathname !== "/") {
        loader.remove()
    }
  }, []);

  // Listener for width
  React.useEffect(() => {
    window.addEventListener('resize', () => dispatch(updateWidth()));
    window.addEventListener('orientationchange', () => dispatch(updateWidth()));
  }, []);


  return (

    <div className={styles.App}>
      <Nav/>
      <QuoteBox/>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/catalogue" exact component={Catalogue}/>

        <Route path="/catalogue/oliveOil" component={Catalogue}/>
        <Route path="/catalogue/wine" component={Catalogue}/>
        <Route path="/catalogue/sparkling" component={Catalogue}/>
        <Route path="/catalogue/alcohol" component={Catalogue}/>
        <Route path="/catalogue/various" component={Catalogue}/>

        <Route path="/catalogue/:product" component={ProductPage}/>
        <Route path="/solutions" component={Solutions}/>
        <Route path="/design" component={Design}/>
        <Route path="/about" component={About}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/quote" exact component={Quote}/>
        <Route path="/comparison" component={Comparison}/>
        <Route path="/privacypolicy" component={PrivacyPolicy}/>
        <Route path="/termsofuse" component={TermsOfUse}/>
        <Route path="/cookiepolicy" component={CookiePolicy}/>
        <Route path="/cookiesettings" component={CookieSettings}/>
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer/>
      <CookieConsent  location="bottom" enableDeclineButton
                      style={{ flexWrap: "none", width: "100vw", display:"grid", justifyContent: "center", justifyItems: "center",
                               padding: "0px 0px", paddingBottom: width < 800 ? "20vh" : "0px",  gridAutoFlow: width < 800 ? "row" : "column", alignItems: "center", 
                               background: "rgba(81,81,80,0.75)", color: "white", height: width < 800 ? "40vh" : "20vh"}}
                      buttonText={language === 'EN' ? 'ACCEPT' : 'KABUL ET'}
                      buttonStyle={{background: "rgb(218, 197, 84)", color: "white", fontWeight: "bold", width: "110px", borderRadius: "3px"}}
                      declineButtonStyle={{background: "transparent", color: "white", fontWeight: "light", textDecoration: "underline", width: "110px"}}
                      declineButtonText={language === 'EN' ? 'Cookies Settings' : 'Çerez Ayarları'}
                      onAccept={() => window.gtag('consent', 'update', {'analytics_storage': 'granted', 'ad_storage': 'granted'})}
                      onDecline={() => history.push("/cookiesettings")}
                      overlay>
        {language === 'EN' ? <p>We use cookies to improve your experience on our site. You can read our Cookie Policy <Link to="/cookiepolicy" style={{color: "rgb(218,197,84)"}}>here.</Link></p>
                           : <p>Sitemizdeki deneyiminizi geliştirmek için çerez kullanıyoruz. Çerez Politikamızı <Link to="/cookiepolicy" style={{color: "rgb(218,197,84)"}}>buradan</Link> okuyabilirsiniz.</p>} 
      </CookieConsent>
      <Regions />
    </div>
   
  );
}

export default App;
