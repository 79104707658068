import React from 'react';
import styles from './Slider.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { selectProducts, updateProducts } from './filterSlice';

import { selectVolumeMax, selectVolumeMin, 
         changeVolumeMax, changeVolumeMin,
         selectVolumeExact, changeVolumeExact, 
         selectVolumes, changeVolumes,
         selectDiameterMax, selectDiameterMin, 
         changeDiameterMax, changeDiameterMin,
         selectDiameterExact, changeDiameterExact,
         selectDiameters, changeDiameters,
         selectHeightMax, selectHeightMin, selectWeightMax, selectWeightMin,
         changeHeightMax, changeHeightMin, changeWeightMax, changeWeightMin,
         selectHeightExact, changeHeightExact, selectWeightExact, changeWeightExact, selectWeights, changeWeights,
         selectHeights, changeHeights } from './filterSlice';
import { selectLanguage } from '../../commons/appSlice';

function Slider(props) {

    const language = useSelector(selectLanguage);

    const volumeMin = useSelector(selectVolumeMin);
    const volumeMax = useSelector(selectVolumeMax);
    const volumeExact = useSelector(selectVolumeExact);
    const volumes = useSelector(selectVolumes);

    const diameterMin = useSelector(selectDiameterMin);
    const diameterMax = useSelector(selectDiameterMax);
    const diameterExact = useSelector(selectDiameterExact);
    const diameters = useSelector(selectDiameters);

    const heightMin = useSelector(selectHeightMin);
    const heightMax = useSelector(selectHeightMax);
    const heightExact = useSelector(selectHeightExact);
    const heights = useSelector(selectHeights);

    const weightMin = useSelector(selectWeightMin);
    const weightMax = useSelector(selectWeightMax);
    const weightExact = useSelector(selectWeightExact);
    const weights = useSelector(selectWeights);

    // const products = useSelector(selectProducts);

    
    const dispatch = useDispatch();

    return (
        <div className={styles.allSliders}>

            { props.name === 'volume' ?
                <div className={styles.container}>
                    <section className={styles.Slider}>
                        <input type="range" step='10' name='min' min={props.minValue} max={props.maxValue} value={volumeMin} className={styles.minSlider} onChange={(e) => {dispatch(changeVolumeMin(e.target.value)); dispatch(updateProducts()); dispatch(changeVolumes());}}/>
                        <input type="range" step='10' name='max' min={props.minValue} max={props.maxValue} value={volumeMax} className={styles.maxSlider} onChange={(e) => {dispatch(changeVolumeMax(e.target.value)); dispatch(updateProducts()); dispatch(changeVolumes());}}/>
                        <input type='range' name='slider' className={styles.emptySlider} defaultValue={0}/>
                    </section>
                    <select className={styles.select} value={volumeExact} onMouseOver={() => dispatch(changeVolumes())} onChange={(e) => {dispatch(changeVolumeExact(e.target.value)); dispatch(updateProducts()); dispatch(changeVolumes());}}>
                        <option value="Pick" disabled selected>{language === 'EN' ? 'Pick' : 'Seç'}</option>
                        {volumes.map(volume => <option key={volume} value={Number(volume)}>{volume}</option>)}
                        <option value="all">{language === 'EN' ? 'All' : 'Hepsi'}</option>
                    </select>
                    {/* <input type='text' name='exact' value={volumeExact} placeHolder='Exact' className={styles.valuesText} onChange={(e) => dispatch(changeVolumeExact(e.target.value))}/> */}
                    <p className={styles.valuesText}>{`${volumeMin}-${volumeMax}${volumeMax === 2000 ? '+' : ''} ${props.unit}`}</p>
                </div>
            : null}

            { props.name === 'diameter' ?
                <div className={styles.container}>
                    <section className={styles.Slider}>
                        <input type="range" step='10' name='min' min={props.minValue} max={props.maxValue} value={diameterMin} className={styles.minSlider} onChange={(e) => {dispatch(changeDiameterMin(e.target.value)); dispatch(updateProducts()); dispatch(changeDiameters());}}/>
                        <input type="range" step='10' name='max' min={props.minValue} max={props.maxValue} value={diameterMax} className={styles.maxSlider} onChange={(e) => {dispatch(changeDiameterMax(e.target.value)); dispatch(updateProducts()); dispatch(changeDiameters());}}/>
                        <input type='range' name='slider' className={styles.emptySlider} defaultValue={0}/>
                    </section>
                    <select className={styles.select} value={diameterExact} onMouseOver={() => dispatch(changeDiameters())} onChange={(e) => {dispatch(changeDiameterExact(e.target.value)); dispatch(updateProducts()); dispatch(changeDiameters());}}>
                        <option value="Pick" disabled selected>{language === 'EN' ? 'Pick' : 'Seç'}</option>
                        {diameters.map(dia => <option key={dia} value={Number(dia)}>{dia}</option>)}
                        <option value="all">{language === 'EN' ? 'All' : 'Hepsi'}</option>
                    </select>
                    {/* <input type='text' name='exact' value={volumeExact} placeHolder='Exact' className={styles.valuesText} onChange={(e) => dispatch(changeVolumeExact(e.target.value))}/> */}
                    <p className={styles.valuesText}>{`${diameterMin}-${diameterMax} ${props.unit}`}</p>
                </div>
            : null}

            { props.name === 'height' ?
                <div className={styles.container}>
                    <section className={styles.Slider}>
                        <input type="range" step='10' name='min' min={props.minValue} max={props.maxValue} value={heightMin} className={styles.minSlider} onChange={(e) => {dispatch(changeHeightMin(e.target.value)); dispatch(updateProducts()); dispatch(changeHeights())}}/>
                        <input type="range" step='10' name='max' min={props.minValue} max={props.maxValue} value={heightMax} className={styles.maxSlider} onChange={(e) => {dispatch(changeHeightMax(e.target.value)); dispatch(updateProducts()); dispatch(changeHeights())}}/>
                        <input type='range' name='slider' className={styles.emptySlider} defaultValue={0}/>
                    </section>
                    <select className={styles.select} value={heightExact} onMouseOver={() => dispatch(changeHeights())} onChange={(e) => {dispatch(changeHeightExact(e.target.value)); dispatch(updateProducts()); dispatch(changeHeights())}}>
                        <option value="Pick" disabled selected>{language === 'EN' ? 'Pick' : 'Seç'}</option>
                        {heights.map(height => <option key={height} value={Number(height)}>{height}</option>)}
                        <option value="all">{language === 'EN' ? 'All' : 'Hepsi'}</option>
                    </select>
                    {/* <input type='text' name='exact' value={volumeExact} placeHolder='Exact' className={styles.valuesText} onChange={(e) => dispatch(changeVolumeExact(e.target.value))}/> */}
                    <p className={styles.valuesText}>{`${heightMin}-${heightMax} ${props.unit}`}</p>
                </div>
            : null}

            { props.name === 'weight' ?
                <div className={styles.container}>
                    <section className={styles.Slider}>
                        <input type="range" step='10' name='min' min={props.minValue} max={props.maxValue} value={weightMin} className={styles.minSlider} onChange={(e) => {dispatch(changeWeightMin(e.target.value)); dispatch(updateProducts()); dispatch(changeWeights())}}/>
                        <input type="range" step='10' name='max' min={props.minValue} max={props.maxValue} value={weightMax} className={styles.maxSlider} onChange={(e) => {dispatch(changeWeightMax(e.target.value)); dispatch(updateProducts()); dispatch(changeWeights())}}/>
                        <input type='range' name='slider' className={styles.emptySlider} defaultValue={0}/>
                    </section>
                    <select className={styles.select} value={weightExact} onMouseOver={() => dispatch(changeWeights())} onChange={(e) => {dispatch(changeWeightExact(e.target.value)); dispatch(updateProducts()); dispatch(changeWeights())}}>
                        <option value="Pick" disabled selected>{language === 'EN' ? 'Pick' : 'Seç'}</option>
                        {weights.map(weight => <option key={weight} value={Number(weight)}>{weight}</option>)}
                        <option value="all">{language === 'EN' ? 'All' : 'Hepsi'}</option>
                    </select>
                    {/* <input type='text' name='exact' value={volumeExact} placeHolder='Exact' className={styles.valuesText} onChange={(e) => dispatch(changeVolumeExact(e.target.value))}/> */}
                    <p className={styles.valuesText}>{`${weightMin}-${weightMax}${weightMax === 1500 ? '+' : ''} ${props.unit}`}</p>
                </div>
            : null}

        </div>
    )
}


export default Slider;