import { configureStore } from '@reduxjs/toolkit';
import formReducer from '../routes/contact/formSlice';
import quoteReducer from '../routes/quote/quoteSlice';
import filterReducer from '../routes/catalogue/filterSlice';
import appReducer from '../commons/appSlice';

export const store = configureStore({
  reducer: {
    form: formReducer,
    quote: quoteReducer,
    filter: filterReducer,
    app: appReducer
  }
});
