const colorsDict = {
    vn: 'rgb(61,75,31)',
    ve: 'rgb(61,75,31)',
    ag: 'rgb(97,99,32)',
    mb: 'rgb(227,242,233)',
    bn: 'rgb(255,255,255)',
    eb: 'rgb(255,255,255)',
    xb: 'rgb(255,255,255)',
    rb: 'rgb(140,44,18)',
};

export default colorsDict;