import React from 'react';
import styles from './Icons.module.css';
import {Link} from 'react-router-dom';

import facebookIcon from '../images/facebook-social-logo.png';
import instagramIcon from '../images/instagram-logo.png';
import phoneIcon from '../images/phone-receiver-silhouette.png';
import emailIcon from '../images/email.png';

function Icons () {
    return (
        <div className={styles.allIcons}>
            <a href='https://www.facebook.com/feniksambalaj' target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="facebook icon" className={styles.facebookIcon}/></a>
            <a href='https://www.instagram.com/feniksambalaj/?hl=en' target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="instagram icon" className={styles.instagramIcon}/></a>
            <Link to='/contact'>
                <img src={phoneIcon} alt="phone icon" className={styles.phoneIcon}/>
            </Link>
            <Link to='/contact'>
                <img src={emailIcon} alt="email icon" className={styles.emailIcon}/>
            </Link>
        </div>
    )
};

export default Icons;