import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    language: 'TR',
    width: window.innerWidth,
    orientation: 'landscape',
    homeLoaded: false,
    scrollID: ''
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleLanguage: (state, action) => {
            state.language = action.payload;
        },
        updateWidth: (state) => {
            state.width = window.innerWidth;
            
            if(window.innerWidth > window.innerHeight) {
                state.orientation = 'landscape'
            } else {
                state.orientation = 'portrait'
            }
        },
        changeHomeLoaded: (state, action) => {
            state.homeLoaded = action.payload;
        },
        updateScrollID: (state, action) => {
            state.scrollID = action.payload;
        }
    }
});    

export const { toggleLanguage, updateWidth, changeHomeLoaded, updateScrollID } = appSlice.actions;

export const selectLanguage = (state) => state.app.language;
export const selectWidth = (state) => state.app.width;
export const selectOrientation = (state) => state.app.orientation;
export const selectHomeLoaded = (state) => state.app.homeLoaded;
export const selectScrollID = (state) => state.app.scrollID;

export default appSlice.reducer;