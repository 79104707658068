import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';
import styles from './CookiePolicy.module.css';


function CookiePolicy() {

    const language = useSelector(selectLanguage);

    React.useEffect(() => {
        document.title = language === 'EN' ? 'Cookie Policy' : 'Çerez Politikası';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Cookie Policy' : 'Çerez Politikası';
      }, [language]);

    return (
        <div className={styles.CookiePolicy}>
            {language === 'EN' ? 
                    <section>
                        <h3>Cookies Policy</h3>
                        <p>
                        Last updated: December 12, 2022
                        This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used. This Cookies Policy has been created with the help of the Cookies Policy Generator.
                        Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.
                        We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.
                        </p>
                        <h4>Interpretation and Definitions</h4>
                        <h5>Interpretation</h5>
                        <p>
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                        </p>
                        <h5>Definitions</h5>
                        <p>
                        For the purposes of this Cookies Policy:
                        Company (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to Feniks Labelling and Packaging Limited, Zühtüpaşa, Rüştiye Sk. No: 8/3, 34724 Kadıköy/İstanbul.
                        Cookies means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.
                        Website refers to Feniks Labelling and Packaging , accessible from www.feniks.com.tr
                        You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.
                        </p>
                        <h4>The use of the Cookies</h4>
                        <h5>Type of Cookies We Use</h5>
                        <p>
                        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
                        We use both session and persistent Cookies for the purposes set out below:
                        <br/>
                        Necessary / Essential Cookies
                        <br/>
                        Type: Session Cookies
                        <br/>
                        Administered by: Us
                        <br/>
                        Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                        <br/>
                        Functionality Cookies
                        <br/>
                        Type: Persistent Cookies
                        <br/>
                        Administered by: Us
                        <br/>
                        Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
                        </p>
                        <h5>Your Choices Regarding Cookies</h5>
                        <p>
                        If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.
                        If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.
                        If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.
                        <br/>
                        <br/>
                        For the Chrome web browser, please visit this page from Google: https://support.google.com/accounts/answer/32050
                        <br/>
                        For the Internet Explorer web browser, please visit this page from Microsoft: 
                        http://support.microsoft.com/kb/278835
                        <br/>
                        For the Firefox web browser, please visit this page from Mozilla: https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                        <br/>
                        For the Safari web browser, please visit this page from Apple: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                        <br/>
                        For any other web browser, please visit your web browser's official web pages.
                        </p>
                        <h5>Contact Us</h5>
                        <p>
                        If you have any questions about this Cookies Policy, You can contact us:
                        <br/>
                        By email: info@feniks.com.tr
                        </p>
                    </section>
            : 
                    <section>
                        <h3>Çerez Politikası</h3>
                        <p>
                        Son güncellenme: 12/12/2022
                        </p>
                        <p>
                        Biz, Feniks Etiket ve Ambalaj Sanayi Ltd. Şti., olarak güvenliğinize önem veriyor ve bu 
                        Çerez Politikası ile siz sevgili ziyaretçilerimizi, web sitemizde hangi çerezleri, hangi amaçla kullandığımız ve çerez ayarlarınızı nasıl değiştireceğiniz konularında kısaca bilgilendirmeyi hedefliyoruz.
                        Sizlere daha iyi hizmet verebilmek adına, çerezler vasıtasıyla, ne tür kişisel verilerinizin hangi amaçla toplandığı ve nasıl işlendiği konularında, kısaca bilgi sahibi olmak için lütfen bu Çerez Politikasını okuyunuz. Daha fazla bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle çekinmeden iletişime geçebilirsiniz.
                        </p>
                        <h4>Çerez Nedir?</h4>
                        Çerezler, kullanıcıların web sitelerini daha verimli bir şekilde kullanabilmeleri adına, cihazlarına kaydedilen küçük dosyacıklardır. Çerezler vasıtasıyla kullanıcıların bilgilerinin işleniyor olması sebebiyle, 6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince, kullanıcıların bilgilendirilmeleri ve onaylarının alınması gerekmektedir.
                        Bizler de siz sevgili ziyaretçilerimizin, web sitemizden en verimli şekilde yararlanabilmelerini ve siz sevgili ziyaretçilerimizin kullanıcı deneyimlerinin geliştirilebilmesini sağlamak adına, çeşitli çerezler kullanmaktayız.
                        <h5>1. Zorunlu Çerezler</h5>
                        Zorunlu çerezler, web sitesine ilişkin temel işlevleri etkinleştirerek web sitesinin kullanılabilir hale gelmesini sağlayan çerezlerdir. Web sitesi bu çerezler olmadan düzgün çalışmaz.
                        <h5>2. Performans Çerezleri</h5>
                        Performans çerezleri, ziyaretçilerin web sitesine ilişkin kullanım bilgilerini ve tercihlerini anonim olarak toplayan ve bu sayede web sitesinin performansının geliştirilmesine olanak sağlayan çerezlerdir.
                        <h5>3. Fonksiyonel Çerezler</h5>
                        Fonksiyonel çerezler, kullanıcıların web sitesine ilişkin geçmiş kullanımlarından yola çıkılarak gelecekteki ziyaretlerinde tanınmalarını ve hatırlanmalarını sağlayan ve bu sayede web sitelerinin kullanıcılara dil, bölge vb. gibi kişiselleştirilmiş bir hizmet sunmasına olanak tanıyan çerezlerdir.
                        <h4>Çerezler İle İşlenen Kişisel Veriler Nelerdir?</h4>
                        <p>
                        Kimlik (isim, soy isim, doğum tarihi vb.) ve iletişim (adres, e-posta adresi, telefon, IP, konum vb.) bilgileriniz tarafımızca, çerezler (cookies) vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat işleme şartına dayanılarak işlenecektir.
                        </p>
                        <h4>Çerezler Hangi Amaçla Kullanılmaktadır?</h4>
                        <p>
                        Web sitemizde, şüpheli eylemlerin tespiti yoluyla güvenliğin sağlanması, kullanıcıların tercihleri doğrultusunda işlevsellik ve performansın artırılması, ürünlerin ve hizmetlerin geliştirilmesi ve kişiselleştirilmesi ile bu hizmetlere ulaşımın kolaylaştırılması, sözleşmesel ve hukuki sorumlulukların yerine getirilmesi amaçlı çerezler kullanmaktadır. Ayrıca kullanıcıların daha geniş kapsamlı hizmet sağlayıcılar ile buluşturulabilmesi amacıyla reklam çerezleri ve üçüncü taraflarla bilgi paylaşımı da söz konusudur.
                        </p>
                        <h4>Çerezler Nasıl Yönetilmektedir?</h4>
                        <p>
                        Tüm bu açıklamalardan sonra, hangi çerezlerin kullanılacağı konusu, tamamen kullanıcılarımızın özgür iradelerine bırakılmıştır. Çerez tercihlerinizi, tarayıcınızın ayarlarından silerek ya da engelleyerek, web sitemize adım attığınız anda yönetebilir ya da gelecekte, istediğiniz zaman bu ayarları değiştirebilirsiniz. Daha ayrıntılı bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle info@feniks.com.tr e-mail adresi üzerinden iletişime geçebilirsiniz.
                        </p>

                    </section>
            }

        </div>
    );
}

export default CookiePolicy;