import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getProducts = createAsyncThunk(
    'filter/getProducts',
    async () => {
        const response = await fetch('/admin/products');
        const json = await response.json();
        return json;
    }
  );

const minVolume = 0;
const maxVolume = 2000;

const minDiameter = 0;
const maxDiameter = 200;

const minHeight = 0;
const maxHeight = 600;

const minWeight = 0;
const maxWeight = 1500;

const initialState = {

    mobileFilterVisible: false,

    productsLoading: false,
    firstTime: true,
    

    search: '',
    region: 'TR',

    allProducts: [],
    products: [],

    advancedVisible: false,

    sortBy: 'az',
    languageFilter: 'TR',

    filters: {
        sector: {
            oliveOil: false,
            wine: false,
            sparkling: false,
            alcohol: false,
            various: false,
            cosmetics:false,
        },
        type: {
            bottle: false,
            jar: false,
            cap: false,
            capsule: false,
            machine: false,
            outer: false,
        },
        machineType: {
            vacuumFiller: false,
            volumetricFiller: false,
            capper: false,
            labeler: false,
            complementary: false,
            customSolutions: false
        },
        shape: {
            round: false,
            square: false,
            elliptical: false,
            rectangular: false
        },
        material: {
            glass: false,
            metal: false,
            laminated: false,
            pet: false,
            pe: false,
            pp: false,
            plastic: false,
            aluminum: false,
            tin: false,
            wooden: false,
        },
        neckFinish: {
            dop: false,
            ropp18: false,
            ropp24: false,
            ropp25: false,
            ropp28: false,
            ropp315: false,
            ropp35: false,
            bvs30: false,
            corkFA: false,
            corkFP: false,
            vinolok: false,
            stopper: false,
            gpi: false,
            gcmi: false,
            twistOff: false,
            grinder: false,
            rical29: false,
            rical32: false,
            tm: false,
            crown26: false,
            crown29: false,
            fc29: false,
            tap: false,
            spray: false
        },
        security: {
            dop: false,
            dopTE: false,
            ringTE: false,
            noSecurity: false
        },
        colors: {
            vn: false,
            ve: false,
            ag: false,
            mb: false,
            bn: false,
            eb: false,
            xb: false,
            rb: false
        },
        bottleType: {
            bordeaux: false,
            burgundy: false,
            renana: false,
        }
    },

    sliders: {
        volume: {
            volumeMin: minVolume,
            volumeMax: maxVolume,
            volumeExact: null,
            volumes: []
        },
        diameter: {
            diameterMin: minDiameter,
            diameterMax: maxDiameter,
            diameterExact: null,
            diameters: [],
        },
        height: {
            heightMin: minHeight,
            heightMax: maxHeight,
            heightExact: null,
            heights: []
        },
        weight: {
            weightMin: minWeight,
            weightMax: maxWeight,
            weightExact: null,
            weights: []
        }
    }

}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        changeSearch: (state, action) => {
            state.search = action.payload;

        },
        toggleMobileFilter: (state, action) => {
            if (action.payload === undefined) {
                state.mobileFilterVisible = !state.mobileFilterVisible;
            } else {
                state.mobileFilterVisible = action.payload;
            }
        },
        changeFirstTime: (state, action) => {
            state.firstTime = action.payload;
        },
        toggleAdvanced: (state, action) => {
            if (action.payload === undefined) {
                state.advancedVisible = !state.advancedVisible;
            } else {
                state.advancedVisible = action.payload;
            }
        },
        toggleSortBy: (state) => {
            if (state.sortBy === 'az') {
                state.sortBy = 'za';
            } else {
                state.sortBy = 'az';
            }
        },
        toggleLanguageFilter: (state, action) => {
            state.languageFilter = action.payload;
        },   
        toggleRegion : (state, action) => {
            state.region = action.payload;
        },     
        updateProducts: (state) => {    
            state.products = state.allProducts;
            
            
            for (const [key1, value] of Object.entries(state.filters)) {
                let temp = [];
                let count = 0;
                for (const [key2, value2] of Object.entries(value)) {
                    if (value2) {
                        if (key1 !== 'colors' && key1 !== 'neckFinish') {
                            const filtered = state.products.filter(product => product[key1] === key2);
                            temp = [...temp, ...filtered];
                        } else {
                            const filtered = state.products.filter(product => product[key1].includes(key2));
                            filtered.filter(item => !temp.includes(item)).forEach(item => temp.push(item));
                        };
                        count = count + 1;
                    }
                };
                if (count > 0) {
                    state.products = temp;
                }
            }
            
            // Sliders
            if (state.sliders.volume.volumeExact === 'all' || state.sliders.volume.volumeExact === null) {
                if (state.sliders.volume.volumeMax !== 2000) {
                    state.products = state.products.filter(product => ((state.sliders.volume.volumeMin <= Number(product['volume']))  && (Number(product['volume']) <= state.sliders.volume.volumeMax)));
                } else {
                    state.products = state.products.filter(product => state.sliders.volume.volumeMin <= Number(product['volume']));
                }
            } else {
                state.products = state.products.filter(product => Number(product['volume']) === state.sliders.volume.volumeExact);
            }

            state.products = state.products.filter(product => ((state.sliders.diameter.diameterMin <= Number(product['diameter']))  && (Number(product['diameter']) <= state.sliders.diameter.diameterMax)));
            state.products = state.products.filter(product => ((state.sliders.height.heightMin <= Number(product['height']))  && (Number(product['height']) <= state.sliders.height.heightMax)));

            if (state.sliders.weight.weightExact === 'all' || state.sliders.weight.weightExact === null) {
                if (state.sliders.weight.weightMax !== 1500) {
                    state.products = state.products.filter(product => ((state.sliders.weight.weightMin <= Number(product['weight']))  && (Number(product['weight']) <= state.sliders.weight.weightMax)));
                } else {
                    state.products = state.products.filter(product => state.sliders.weight.weightMin <= Number(product['weight']));
                }
            } else {
                state.products = state.products.filter(product => Number(product['weight']) === state.sliders.weight.weightExact);
            }
            
            // Search
            if (state.search) {
                const upperSearch = state.search.toUpperCase();
                if (upperSearch === 'BRD' || upperSearch === 'BORD') {
                    state.products = state.products.filter(product => product.name.includes('BRD') || product.name.includes('BORD'));
                } else if (upperSearch === 'BRG' || upperSearch === 'BORG' || upperSearch === 'BOURG') {
                    state.products = state.products.filter(product => product.name.includes('BRG') || (product.name.includes('BORG')) || product.name.includes('BOURG'));
                } else {
                    state.products = state.products.filter(product => product.name.includes(upperSearch) || product.nameTR.includes(upperSearch));
                }
            }

            // Sort

            if (state.languageFilter === 'EN') {
                if (state.sortBy === 'az') {
                    state.products.sort((a, b) => a.name.localeCompare(b.name) || a.volume - b.volume);
                } else {
                    state.products.sort((a, b) => b.name.localeCompare(a.name) || a.volume - b.volume);
                }
            } else if (state.languageFilter === 'TR') {
                if (state.sortBy === 'az') {
                    state.products.sort((a, b) => a.nameTR.localeCompare(b.nameTR) || a.volume - b.volume);
                } else {
                    state.products.sort((a, b) => b.nameTR.localeCompare(a.nameTR) || a.volume - b.volume);
                }
            }
        },
        toggleFilter: (state, action) => {
            if (action.payload[2] === undefined) {
                state.filters[action.payload[0]][action.payload[1]] = !(state.filters[action.payload[0]][action.payload[1]]);
            } else {
                state.filters[action.payload[0]][action.payload[1]] = action.payload[2];
            }

            if (state.firstTime) {
                state.firstTime = false;
            }
        },
        resetFilters: (state, action) => {
            if (action.payload === undefined) {
                for (const [key1, value] of Object.entries(state.filters)) {
                    for (const key2 of Object.keys(value)) {
                        state.filters[key1][key2] = false;
                    }
                }
            } else {
                for (const [key1, value] of Object.entries(state.filters)) {
                    for (const key2 of Object.keys(value)) {
                        if (!action.payload.includes(key2)) {
                            state.filters[key1][key2] = false;
                        } else {
                            state.filters[key1][key2] = true;
                        }
                    }
                }
            }
            state.sliders.volume.volumeMin = initialState.sliders.volume.volumeMin;
            state.sliders.volume.volumeMax = initialState.sliders.volume.volumeMax;
            state.sliders.volume.volumeExact = initialState.sliders.volume.volumeExact;
            state.sliders.diameter.diameterMin = initialState.sliders.diameter.diameterMin;
            state.sliders.diameter.diameterMax = initialState.sliders.diameter.diameterMax;
            state.sliders.diameter.diameterExact = initialState.sliders.diameter.diameterExact;
            state.sliders.height.heightMin = initialState.sliders.height.heightMin;
            state.sliders.height.heightMax = initialState.sliders.height.heightMax;
            state.sliders.height.heightExact = initialState.sliders.height.heightExact;
            state.sliders.weight.weightMin = initialState.sliders.weight.weightMin;
            state.sliders.weight.weightMax = initialState.sliders.weight.weightMax;
            state.sliders.weight.weightExact = initialState.sliders.weight.weightExact;
        },
        resetOnly: (state, action) => {
            if (action.payload === 'sliders') {
                state.sliders.volume.volumeMin = initialState.sliders.volume.volumeMin;
                state.sliders.volume.volumeMax = initialState.sliders.volume.volumeMax;
                state.sliders.volume.volumeExact = initialState.sliders.volume.volumeExact;
                state.sliders.diameter.diameterMin = initialState.sliders.diameter.diameterMin;
                state.sliders.diameter.diameterMax = initialState.sliders.diameter.diameterMax;
                state.sliders.diameter.diameterExact = initialState.sliders.diameter.diameterExact;
                state.sliders.height.heightMin = initialState.sliders.height.heightMin;
                state.sliders.height.heightMax = initialState.sliders.height.heightMax;
                state.sliders.height.heightExact = initialState.sliders.height.heightExact;
                state.sliders.weight.weightMin = initialState.sliders.weight.weightMin;
                state.sliders.weight.weightMax = initialState.sliders.weight.weightMax;
                state.sliders.weight.weightExact = initialState.sliders.weight.weightExact;
            } else {
                for (const [key1, value] of Object.entries(state.filters)) {
                    for (const key2 of Object.keys(value)) {
                        if (action.payload === key1) {
                            state.filters[key1][key2] = false;
                        }
                    }
                }
            }
        },
        changeVolumeMin: (state, action) => {
            state.sliders.volume.volumeExact = 'all';
            if (action.payload < state.sliders.volume.volumeMax) {
                state.sliders.volume.volumeMin = Number(action.payload);
            } else {
                state.sliders.volume.volumeMin = Number(state.sliders.volume.volumeMax) - 150;
            }
        },
        changeVolumeMax: (state, action) => {
            state.sliders.volume.volumeExact = 'all';
            if (action.payload > state.sliders.volume.volumeMin) {
                state.sliders.volume.volumeMax = Number(action.payload);
            } else {
                state.sliders.volume.volumeMax = Number(state.sliders.volume.volumeMin) + 10;
            }
        },
        changeVolumeExact: (state, action) => {
            if (action.payload === 'all') {
                state.sliders.volume.volumeMin = initialState.sliders.volume.volumeMin;
                state.sliders.volume.volumeMax = initialState.sliders.volume.volumeMax;
                state.sliders.volume.volumeExact = 'all';
            } else {
                state.sliders.volume.volumeExact = Number(action.payload);
                state.sliders.volume.volumeMin = Number(action.payload);
                state.sliders.volume.volumeMax = Number(action.payload);
            }
        },
        changeVolumes: (state) => {
            state.sliders.volume.volumes = [];
            for (let i = 0; i < state.products.length; i++) {
                const tempVol = Number(state.products[i]['volume']);
                if (!state.sliders.volume.volumes.includes(tempVol)) {
                    if ((state.sliders.volume.volumeMin <= tempVol) && (tempVol <= state.sliders.volume.volumeMax)) {
                        state.sliders.volume.volumes.push(tempVol);
                    }
                    else if (state.sliders.volume.volumeMax === 2000 && tempVol >= 2000) {
                        state.sliders.volume.volumes.push(tempVol);
                    }
                }
            }
            state.sliders.volume.volumes.sort(function(a, b) {
                return a - b;
              });
        },
        changeDiameterMin: (state, action) => {
            state.sliders.diameter.diameterExact = 'all';
            if (action.payload < state.sliders.diameter.diameterMax) {
                state.sliders.diameter.diameterMin = Number(action.payload);
            } else {
                state.sliders.diameter.diameterMin = Number(state.sliders.diameter.diameterMax) - 20;
            }
        },
        changeDiameterMax: (state, action) => {
            state.sliders.diameter.diameterExact = 'all';
            if (action.payload > state.sliders.diameter.diameterMin) {
                state.sliders.diameter.diameterMax = Number(action.payload);
            } else {
                state.sliders.diameter.diameterMax = Number(state.sliders.diameter.diameterMin) + 10;
            }
        },
        changeDiameterExact: (state, action) => {
            if (action.payload === 'all') {
                state.sliders.diameter.diameterMin = initialState.sliders.diameter.diameterMin;
                state.sliders.diameter.diameterMax = initialState.sliders.diameter.diameterMax;
                state.sliders.diameter.diameterExact = 'all';
            } else {
                state.sliders.diameter.diameterExact = Number(action.payload);
                state.sliders.diameter.diameterMin = Number(action.payload);
                state.sliders.diameter.diameterMax = Number(action.payload);
            }
        },
        changeDiameters: (state) => {
            state.sliders.diameter.diameters = [];
            for (let i = 0; i < state.products.length; i++) {
                const tempDia = Number(state.products[i]['diameter']);
                if (!(state.sliders.diameter.diameters.includes(tempDia))) {
                    if ((state.sliders.diameter.diameterMin <= tempDia)  && (tempDia <= state.sliders.diameter.diameterMax)) {
                        state.sliders.diameter.diameters.push(tempDia);
                    }
                }
                
            }
            state.sliders.diameter.diameters.sort(function(a, b) {
                return a - b;
              });
        },
        changeHeightMin: (state, action) => {
            state.sliders.height.heightExact = 'all';
            if (action.payload < state.sliders.height.heightMax) {
                state.sliders.height.heightMin = Number(action.payload);
            } else {
                state.sliders.height.heightMin = Number(state.sliders.height.heightMax) - 50;
            }
        },
        changeHeightMax: (state, action) => {
            state.sliders.height.heightExact = 'all';
            if (action.payload > state.sliders.height.heightMin) {
                state.sliders.height.heightMax = Number(action.payload);
            } else {
                state.sliders.height.heightMax = Number(state.sliders.height.heightMin) + 10;
            }
        },
        changeHeightExact: (state, action) => {
            if (action.payload === 'all') {
                state.sliders.height.heightMin = initialState.sliders.height.heightMin;
                state.sliders.height.heightMax = initialState.sliders.height.heightMax;
                state.sliders.height.heightExact = 'all';
            } else {
                state.sliders.height.heightExact = Number(action.payload);
                state.sliders.height.heightMin = Number(action.payload);
                state.sliders.height.heightMax = Number(action.payload);
            }
        },
        changeHeights: (state) => {
            state.sliders.height.heights = [];
            for (let i = 0; i < state.products.length; i++) {
                const tempHi = Number(state.products[i]['height']);
                if (!(state.sliders.height.heights.includes(tempHi))) {
                    if ((state.sliders.height.heightMin <= tempHi)  && (tempHi <= state.sliders.height.heightMax)) {
                        state.sliders.height.heights.push(tempHi);
                    }
                }
            }
            state.sliders.height.heights.sort(function(a, b) {
                return a - b;
              });
        },
        changeWeightMin: (state, action) => {
            state.sliders.weight.weightExact = 'all';
            if (action.payload < state.sliders.weight.weightMax) {
                state.sliders.weight.weightMin = Number(action.payload);
            } else {
                state.sliders.weight.weightMin = Number(state.sliders.weight.weightMax) - 120;
            }
        },
        changeWeightMax: (state, action) => {
            state.sliders.weight.weightExact = 'all';
            if (action.payload > state.sliders.weight.weightMin) {
                state.sliders.weight.weightMax = Number(action.payload);
            } else {
                state.sliders.weight.weightMax = Number(state.sliders.weight.weightMin) + 10;
            }
        },
        changeWeightExact: (state, action) => {
            if (action.payload === 'all') {
                state.sliders.weight.weightMin = initialState.sliders.weight.weightMin;
                state.sliders.weight.weightMax = initialState.sliders.weight.weightMax;
                state.sliders.weight.weightExact = 'all';
            } else {
                state.sliders.weight.weightExact = Number(action.payload);
                state.sliders.weight.weightMin = Number(action.payload);
                state.sliders.weight.weightMax = Number(action.payload);
            }
        },
        changeWeights: (state) => {
            state.sliders.weight.weights = [];
            for (let i = 0; i < state.products.length; i++) {
                const tempWi = Number(state.products[i]['weight']);
                if (!(state.sliders.weight.weights.includes(tempWi))) {
                    if ((state.sliders.weight.weightMin <= tempWi)  && (tempWi <= state.sliders.weight.weightMax)) {
                        state.sliders.weight.weights.push(tempWi);
                    } else if (state.sliders.weight.weightMax === 1500 && tempWi >= 1500) {
                        state.sliders.weight.weights.push(tempWi);
                    }
                }
            }
            state.sliders.weight.weights.sort(function(a, b) {
                return a - b;
              });
        }
    },
    extraReducers: {
        [getProducts.fulfilled]: (state, action) => {
            state.allProducts = action.payload;
            state.products = action.payload;
            state.productsLoading = false;

        },
        [getProducts.pending]: (state) => {
            state.productsLoading = true;
        }
    }
}) 


export const { changeSearch, updateProducts, changeFirstTime, toggleFilter,
               changeVolumeMin, changeVolumeMax, changeVolumeExact, changeVolumes,
               changeDiameterMin, changeDiameterMax, changeDiameterExact, changeDiameters,
               changeHeightMin, changeHeightMax, changeHeightExact, changeHeights,
               changeWeightMin, changeWeightMax, changeWeightExact, changeWeights,  
               toggleAdvanced, resetFilters, resetOnly, toggleSortBy, toggleMobileFilter, 
               toggleRegion, toggleLanguageFilter } = filterSlice.actions;


export const selectMobileFilterVisible = (state) => state.filter.mobileFilterVisible;
export const selectProductsLoading = (state) => state.filter.productsLoading;
export const selectFirstTime = (state) => state.filter.firstTime;
export const selectRegion = (state) => state.filter.region;

export const selectSearch = (state) => state.filter.search;
export const selectProducts = (state) => state.filter.products;
export const selectAllProducts = (state) => state.filter.allProducts;

export const selectAdvancedVisible = (state) => state.filter.advancedVisible;

export const selectSortBy = (state) => state.filter.sortBy;
export const selectLanguageFilter = (state) => state.filter.languageFilter;

export const selectOliveOil = (state) => state.filter.filters.sector.oliveOil;
export const selectWine = (state) => state.filter.filters.sector.wine;
export const selectSparkling = (state) => state.filter.filters.sector.sparkling;
export const selectAlcohol = (state) => state.filter.filters.sector.alcohol;
export const selectVarious = (state) => state.filter.filters.sector.various;
export const selectCosmetics = (state) => state.filter.filters.sector.cosmetics;

export const selectBottle = (state) => state.filter.filters.type.bottle;
export const selectJar = (state) => state.filter.filters.type.jar;
export const selectCap = (state) => state.filter.filters.type.cap;
export const selectCapsule = (state) => state.filter.filters.type.capsule;
export const selectMachine = (state) => state.filter.filters.type.machine;
export const selectOuter = (state) => state.filter.filters.type.outer;

export const selectVacuumFiller = (state) => state.filter.filters.machineType.vacuumFiller;
export const selectVolumetricFiller = (state) => state.filter.filters.machineType.volumetricFiller;
export const selectCapper = (state) => state.filter.filters.machineType.capper;
export const selectLabeler = (state) => state.filter.filters.machineType.labeler;
export const selectComplementary = (state) => state.filter.filters.machineType.complementary;
export const selectCustomSolutions = (state) => state.filter.filters.machineType.customSolutions;

export const selectBordeaux = (state) => state.filter.filters.bottleType.bordeaux;
export const selectBurgundy = (state) => state.filter.filters.bottleType.burgundy;
export const selectRenana = (state) => state.filter.filters.bottleType.renana;


export const selectRound = (state) => state.filter.filters.shape.round;
export const selectSquare = (state) => state.filter.filters.shape.square;
export const selectElliptical = (state) => state.filter.filters.shape.elliptical;
export const selectRectangular = (state) => state.filter.filters.shape.rectangular;

export const selectGlass = (state) => state.filter.filters.material.glass;
export const selectMetal = (state) => state.filter.filters.material.metal;
export const selectLaminated = (state) => state.filter.filters.material.laminated;
export const selectPet = (state) => state.filter.filters.material.pet;
export const selectPP = (state) => state.filter.filters.material.pp;
export const selectPE = (state) => state.filter.filters.material.pe;
export const selectPlastic = (state) => state.filter.filters.material.plastic;
export const selectAluminum = (state) => state.filter.filters.material.aluminum;
export const selectTin = (state) => state.filter.filters.material.tin;
export const selectWooden = (state) => state.filter.filters.material.wooden;

export const selectNDop = (state) => state.filter.filters.neckFinish.dop;
export const selectRopp18 = (state) => state.filter.filters.neckFinish.ropp18;
export const selectRopp24 = (state) => state.filter.filters.neckFinish.ropp24;
export const selectRopp25 = (state) => state.filter.filters.neckFinish.ropp25;
export const selectRopp28 = (state) => state.filter.filters.neckFinish.ropp28;
export const selectRopp315 = (state) => state.filter.filters.neckFinish.ropp315;
export const selectRopp35 = (state) => state.filter.filters.neckFinish.ropp35;
export const selectBvs30 = (state) => state.filter.filters.neckFinish.bvs30;
export const selectCorkFA = (state) => state.filter.filters.neckFinish.corkFA;
export const selectCorkFP = (state) => state.filter.filters.neckFinish.corkFP;
export const selectVinolok = (state) => state.filter.filters.neckFinish.vinolok;
export const selectStopper = (state) => state.filter.filters.neckFinish.stopper;
export const selectGpi = (state) => state.filter.filters.neckFinish.gpi;
export const selectGcmi = (state) => state.filter.filters.neckFinish.gcmi;
export const selectTwistOff = (state) => state.filter.filters.neckFinish.twistOff;
export const selectGrinder = (state) => state.filter.filters.neckFinish.grinder;
export const selectRical29 = (state) => state.filter.filters.neckFinish.rical29;
export const selectRical32 = (state) => state.filter.filters.neckFinish.rical32;
export const selectTm = (state) => state.filter.filters.neckFinish.tm;
export const selectCrown26 = (state) => state.filter.filters.neckFinish.crown26;
export const selectCrown29 = (state) => state.filter.filters.neckFinish.crown29;
export const selectFc29 = (state) => state.filter.filters.neckFinish.fc29;
export const selectTap = (state) => state.filter.filters.neckFinish.tap;
export const selectSpray = (state) => state.filter.filters.neckFinish.spray;

export const selectSDop = (state) => state.filter.filters.security.dop;
export const selectDopTE = (state) => state.filter.filters.security.dopTE;
export const selectRingTE = (state) => state.filter.filters.security.ringTE;
export const selectNoSecurity = (state) => state.filter.filters.security.noSecurity;

export const selectVN = (state) => state.filter.filters.colors.vn;
export const selectVE = (state) => state.filter.filters.colors.ve;
export const selectAG = (state) => state.filter.filters.colors.ag;
export const selectMB = (state) => state.filter.filters.colors.mb;
export const selectBN = (state) => state.filter.filters.colors.bn;
export const selectEB = (state) => state.filter.filters.colors.eb;
export const selectXB = (state) => state.filter.filters.colors.xb;
export const selectRB = (state) => state.filter.filters.colors.rb;

export const selectVolumeMin = (state) => state.filter.sliders.volume.volumeMin;
export const selectVolumeMax = (state) => state.filter.sliders.volume.volumeMax;
export const selectVolumeExact = (state) => state.filter.sliders.volume.volumeExact;
export const selectVolumes = (state) => state.filter.sliders.volume.volumes;
export const selectDiameterMin = (state) => state.filter.sliders.diameter.diameterMin;
export const selectDiameterMax = (state) => state.filter.sliders.diameter.diameterMax;
export const selectDiameterExact = (state) => state.filter.sliders.diameter.diameterExact;
export const selectDiameters = (state) => state.filter.sliders.diameter.diameters;
export const selectHeightMin = (state) => state.filter.sliders.height.heightMin;
export const selectHeightMax = (state) => state.filter.sliders.height.heightMax;
export const selectHeightExact = (state) => state.filter.sliders.height.heightExact;
export const selectHeights = (state) => state.filter.sliders.height.heights;
export const selectWeightMin = (state) => state.filter.sliders.weight.weightMin;
export const selectWeightMax = (state) => state.filter.sliders.weight.weightMax;
export const selectWeightExact = (state) => state.filter.sliders.weight.weightExact;
export const selectWeights = (state) => state.filter.sliders.weight.weights;

export default filterSlice.reducer;