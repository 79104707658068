import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import Icons from './Icons';

import logo from '../images/feniks_logo.png';
import { useSelector } from 'react-redux';
import { selectLanguage } from './appSlice';


function Footer () {

    const language = useSelector(selectLanguage);

    return (
        <div className={styles.Footer}>
            <img src={logo} alt="logo" className={styles.logo}/>
            <section className={styles.siteMapContainer}>
                <h3 className={styles.titleList}>{language === 'EN' ? 'Site Map' : 'Site Haritası'}</h3>
                <ul className={styles.ul} style={language === 'TR' ? {marginRight: '0px'} : null}>
                    <li className={styles.li}><Link to='/catalogue' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Products' : 'Ürünler'}</Link></li>
                    <li className={styles.li}><Link to='/solutions' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Solutions' : 'Çözümler'}</Link></li>
                    <li className={styles.li}><Link to='/about' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'About Us' : 'Hakkımızda'}</Link></li>
                    <li className={styles.li}><Link to='/blog' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Blog' : 'Blog'}</Link></li>
                    <li className={styles.li}><Link to='/contact' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Contact' : 'İletişim'}</Link></li>
                </ul>
                <h3 className={styles.titleList}>{language === 'EN' ? 'Legal' : 'Yasal'}</h3>
                <ul className={styles.ul} style={language === 'TR' ? {marginRight: '0px'} : null}>
                    <li className={styles.li}><Link to='/privacypolicy' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Privacy Policy' : 'Gizlilik Politikası'}</Link></li>
                    <li className={styles.li}><Link to='/termsofuse' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Terms of Use' : 'Kullanım Koşulları'}</Link></li>
                    <li className={styles.li}><Link to='/cookiepolicy' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Cookie Policy' : 'Çerez Politikası'}</Link></li>
                    <li className={styles.li}><Link to='/cookiesettings' className={styles.link} onClick={() => window.scrollTo(0, 0)}>{language === 'EN' ? 'Cookie Settings' : 'Çerez Ayarları'}</Link></li>
                </ul>
                <h3>{language === 'EN' ? 'Headquarters' : 'Merkez Ofis'}</h3>
                <p className={styles.address}>          
                Zühtüpaşa Mahallesi,
                <br/>
                Rüştiye Sok. No : 8/3
                <br/>
                34724 Kadıköy – İstanbul
                {language === 'EN' ? ' / TURKEY' : null}
                <br/>
                <a className={styles.mapLink} href='https://goo.gl/maps/bkPBnjySVTYwWgT17' target="_blank" rel="noopener noreferrer">{language === 'EN' ? 'Map >' : 'Harita >'}</a>
                </p>
                <h3>{language === 'EN' ? 'İstanbul Logistics Center' : 'İstanbul Lojistik Merkezi'}</h3>
                <p className={styles.address}>
                Uzmanlar Depolama Dağıtım Hizmetleri Tic. Ltd. Şti.
                <br/>
                Adil Mahallesi, Genç Osman Cad. No:15
                <br/> 
                34935 Sultanbeyli - Istanbul
                {language === 'EN' ? ' / TURKEY' : null}
                <br/>
                <a className={styles.mapLink} href='https://goo.gl/maps/6vJQxqTsmt73AmaNA' target="_blank" rel="noopener noreferrer">{language === 'EN' ? 'Map >' : 'Harita >'}</a>
                </p>                
                <h3>{language === 'EN' ? 'İzmir Logistics Center' : 'İzmir Lojistik Merkezi'}</h3>
                <p className={styles.address}>
                Erhanlar Uluslararası Nakliyat Oto. San. Ve Tic. Ltd. Şti.
                <br/>
                Mehmet Akif Ersoy Mahallesi, 2 Öteyaka Küme Evleri No.68
                <br/>
                35170 Kemalpaşa-İzmir
                {language === 'EN' ? ' / TURKEY' : null}
                <br/>
                <a className={styles.mapLink} href='https://goo.gl/maps/ed3kk3NGYv2dZxELA' target="_blank" rel="noopener noreferrer">{language === 'EN' ? 'Map >' : 'Harita >'}</a>
                </p>
            </section>

            <section className={styles.subFooter}>
                <p className={styles.text}>{language === 'EN' ? 'FENİKS ETİKET VE AMBALAJ SAN. LTD. ŞTİ. 2023 © All Rights Reserved.' : 'FENİKS ETİKET VE AMBALAJ SAN. LTD. ŞTİ. 2023 © Tüm Hakları Saklıdır.'}</p>
                <div className={styles.icons}>
                    <Icons />
                </div>
                

            </section>
        </div>
    );
}

export default Footer;