import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLanguage } from '../../commons/appSlice';
import styles from './CookieSettings.module.css';
import CookiePolicy from './CookiePolicy';


function CookieSettings() {

    const language = useSelector(selectLanguage);
    const history = useHistory();

    React.useEffect(() => {
        document.title = language === 'EN' ? 'Cookie Settings' : 'Çerez Ayarları';
        document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Cookie Settings' : 'Çerez Ayarları';
      }, [language]);

    return (
        <div className={styles.CookieSettings}>
            <section className={styles.settingsContainer}>
                <h1 className={styles.title}>{language === 'EN' ? 'Cookies Settings' : 'Çerez Ayarları'}</h1>
                {language === 'EN' ? <p>We use cookies to improve your experience on our site. For more information, you can read our Cookie Policy below.</p>
                            : <p>Sitemizdeki deneyiminizi geliştirmek için çerez kullanıyoruz. Detaylı bilgi için aşağıdaki Çerez Politikamızı okuyabilirsiniz.</p>} 
                <div className={styles.buttons}>
                    <button className={styles.acceptButton} onClick={() => {window.gtag('consent', 'update', {'analytics_storage': 'granted'}); history.push("/");}}>{language === 'EN' ? 'Accept Cookies' : 'Çerezleri Kabul Et'}</button>
                    <button className={styles.declineButton} onClick={() => {window.gtag('consent', 'update', {'analytics_storage': 'denied'}); history.push("/");}}>{language === 'EN' ? 'Decline Cookies' : ' Çerezleri Reddet'}</button>
                </div>
            </section>
            <CookiePolicy />
        </div>
    )
}

export default CookieSettings;