import React from 'react';
import styles from './Products.module.css';
import {Link} from 'react-router-dom';

import Product from './Product';

import { useSelector, useDispatch } from 'react-redux';
import { selectProducts, selectSortBy, toggleSortBy, updateProducts, resetFilters, changeSearch, changeFirstTime } from './filterSlice';
import { addQuote, removeQuote, selectBasket} from '../quote/quoteSlice';


import compareIcon from '../../images/compare.svg';
import plusIcon from '../../images/plus.png';
import removeIcon from '../../images/remove.png';
import { selectScrollID, selectLanguage, updateScrollID } from '../../commons/appSlice';



function Products() {

    const language = useSelector(selectLanguage);

    const products = useSelector(selectProducts);
    const basket = useSelector(selectBasket);
    const sortBy = useSelector(selectSortBy);
    const dispatch = useDispatch();
    const scrollID = useSelector(selectScrollID);

    const compareColor = {backgroundColor: 'rgba(218,197,84,0.75)', borderColor: 'rgba(218,197,84,0.4)'};

    // Handles empty comparison center
    const comparePath = basket.length > 0 ? "/comparison" : "/catalogue";
    function handleClick(e) {
        if (!basket.length > 0) {
            const comparisonText = document.getElementById("comparisonText");
            comparisonText.innerHTML = language === 'EN' ? 'PLEASE ADD ITEMS' : 'ÜRÜN EKLEYENİZ';
            setTimeout(() => comparisonText.innerHTML = language === 'EN' ? 'COMPARISON CENTER' : 'KARŞILAŞTIR', 1600);
        };
    };

    // Scrolls back to last clicked element
    React.useEffect(() => {
        const element = document.getElementById(scrollID);
        if (element){
            element.scrollIntoView();
        }
    }, []);

    return (
        <div className={styles.Products}>

            <section className={styles.topBar}>  
                <Link to={comparePath} className={styles.comparisonLink} onClick={handleClick} >
                    <p id="comparisonText">{language === 'EN' ? 'COMPARISON' : 'KARŞILAŞTIR'}</p>
                    <img src={compareIcon} alt="compare icon" className={styles.compareIcon}/>
                    <p className={styles.count}>{basket.length}</p>
                </Link>
                <p className={styles.p}>{language === 'EN' ? "Add products to your cart to get a price quote or to compare them in the 'Comparison Center'." : 'Fiyat teklifi almak veya karşılaştırma yapmak için sepetinize ürün ekleyin.'}</p>
                <div className={styles.sortBy} onClick={() => {dispatch(toggleSortBy()); dispatch(updateProducts());}}>
                    <p className={styles.sortByText}>{language === 'EN' ? 'Sort By:' : 'Sırala'}</p>
                    {/* <img src={sortIcon} className={styles.sortIcon}/> */}
                    <p className={styles.sortByDir} >{sortBy === 'az' ? 'A-Z' : 'Z-A'}</p>
                </div>
            </section>

            <section className={styles.actualProducts} id="actualProducts">
                {products.map(product => 
                <div className={styles.Product} key={product.id} id={product.id}>
                    <Link to={`/catalogue/${product.id.toLowerCase().split(" ").join('-')}`} className={styles.productLink} onClick={() => {window.scrollTo(0,0); dispatch(updateScrollID(product.id));}}>
                        <Product name={product.name} nameTR={product.nameTR} image={product.image} volume={product.volume} sector={product.sector} type={product.type} colors={product.colors} material={product.material} neckFinish={product.neckFinish}/>
                    </Link>
                    <img src={compareIcon} alt="compare icon" style={basket.includes(product) ? compareColor : null} className={styles.compareCard} onClick={() => {dispatch(addQuote(product));}}/>
                    {!basket.includes(product) ? <img src={plusIcon} alt="plus icon" className={styles.addCard} onClick={() => {dispatch(addQuote(product));}}/> : <img src={removeIcon} alt="remove icon" className={styles.removeCard} onClick={() => {dispatch(removeQuote(product));}}/>}
                </div>)}
            </section>

            {products.length === 0 ? 
                    <div className={styles.moreProducts}>
                        <p className={styles.moreProductsText}>{language === 'EN' ? 'Please reconfigure or reset the filter to see more products.' : 'Daha fazla ürün görmek için lütfen filtreyi yeniden ayarlayınız veya temizleyiniz.'}</p>
                        <p className={styles.reset} onClick={() => {dispatch(resetFilters()); dispatch(changeSearch(""));dispatch(changeFirstTime(true)); dispatch(updateProducts())}}>{language === 'EN' ? 'CLEAR ALL FILTERS  X' : 'TEMİZLE  X'}</p>
                    </div>
            : null}


            
        </div>
    )
}

export default Products;