import React from 'react';
import styles from './Catalogue.module.css';

import Products from './Products';
import Filter from './Filter';
import Sectors from './Sectors';

import { useSelector, useDispatch } from 'react-redux';
import { selectFirstTime , selectMobileFilterVisible, toggleMobileFilter, 
         resetFilters, changeFirstTime, toggleFilter, updateProducts, selectProducts, 
         selectProductsLoading, selectBottle, selectCap, selectMachine, selectCapsule, selectJar, selectOuter, resetOnly } from './filterSlice';
import { selectLanguage, selectWidth } from '../../commons/appSlice';


function Catalogue() {

  const dispatch = useDispatch();

  const firstTime = useSelector(selectFirstTime);
  const language = useSelector(selectLanguage);
  const width = useSelector(selectWidth);
  const mobileFilterVisible = useSelector(selectMobileFilterVisible);
  const products = useSelector(selectProducts);
  const productsLoading = useSelector(selectProductsLoading);

  const bottle = useSelector(selectBottle);
  const jar = useSelector(selectJar);
  const cap = useSelector(selectCap);
  const capsule = useSelector(selectCapsule);
  const machine = useSelector(selectMachine);
  const outer = useSelector(selectOuter);

  // Swipe to close filter
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [touchMoved, setTouchMoved] = React.useState(false);
  function handleTouchStart(e) {
      if (width < 800) {
        setTouchStart(e.changedTouches[0].pageX);
        setTouchMoved(false);
      }
  }
  function handleTouchMove(e) {
      if (width < 800) {
        setTouchEnd(e.changedTouches[0].pageX);
        setTouchMoved(true);
      }
  }
  function handleTouchEnd(e) {

      setTouchEnd(e.changedTouches[0].pageX);

      if (width < 800) {
        if (e.target.id === "filterButton" && !touchMoved){
          if (mobileFilterVisible){
            dispatch(toggleMobileFilter(false));
          } else {
            dispatch(toggleMobileFilter(true));
          }
          window.scrollTo(0,0);
        } else {
          if (touchStart - touchEnd > 75 && touchMoved) {
            // do your stuff here for left swipe
            dispatch(toggleMobileFilter(false));
            window.scrollTo(0,0);
          }
          if (touchStart - touchEnd < -75 && touchMoved) {
              // do your stuff here for right swipe
              dispatch(toggleMobileFilter(true));
              window.scrollTo(0,0);
          }
        }
      }  
  }

  // Dynamically updates meta tags
  React.useEffect(() => {
    // document.title = language === 'EN' ? 'Products' : 'Ürünler';
    // document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Browse our catalogue to find the best fit for your product.' : 'Ürününüze en uygun ambalajı bulmak için kataloğumuza göz atın.';
    document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Products' : 'Ürünler';
  }, [language]);

  // Handle sector in url
  React.useEffect(() => {
    const url = window.location.href.split("/");
    const sectorURL = url.pop();

    if (products.length > 0 && sectorURL !== "catalogue") {
      
      if (!bottle && !jar && !cap && !capsule && !machine && !outer) {
        dispatch(resetFilters([sectorURL]));
        dispatch(toggleFilter(["type", "bottle", true]));
      }
      dispatch(changeFirstTime(false));
      dispatch(updateProducts());
    }
  }, [productsLoading]);

  return (
    <div id="catalogue" className={styles.Catalogue}
                        style={{gridTemplateColumns: (mobileFilterVisible ? '275px 5fr' : (width > 800 ? '275px 5fr' : '1fr')), 
                                height: (mobileFilterVisible ? (width > 800 ? '110vh' : '100vh') : (width > 800 ? '110vh' : 'auto'))}} 
                        onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        <Filter id="filter"/>
        {firstTime ? <Sectors id="sectors"/> : <Products id="products"/>}
        <button id="filterButton" className={styles.filterButton} >{language === 'EN' ? (mobileFilterVisible === true ? 'APPLY' : 'FILTER') : (mobileFilterVisible === true ? 'UYGULA' : 'FİLTRE')}</button>
    </div>
  );
}

export default Catalogue;