import React from 'react';
import styles from './Sectors.module.css';

import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import { resetFilters, updateProducts, changeFirstTime } from './filterSlice';

import renderOliveOils from '../../images/renderOliveOils.jpg';
import renderWines from '../../images/renderWines.jpg';
import renderSparklings from '../../images/renderSparklings.jpg';
import renderAlcohols from '../../images/renderAlcohols.jpg';
import renderFoods from '../../images/renderFoods.jpg';
import { selectLanguage } from '../../commons/appSlice';


function Sectors() {

    const language = useSelector(selectLanguage);

    const dispatch = useDispatch();

    return (
        <div className={styles.container}>
            <Link to='/catalogue' className={styles.olive} onClick={() => {dispatch(resetFilters(['oliveOil', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderOliveOils} alt="zeytinyağı şişeleri ve sirke şişeleri" className={styles.renderImg}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'OLIVE OIL AND VINEGAR' : 'ZEYTİNYAĞI VE SİRKELER'}</p>
            </Link>
            <Link to='/catalogue' className={styles.wine} onClick={() => {dispatch(resetFilters(['wine', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderWines} alt="şarap şişeleri" className={styles.renderImg}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'STILL WINE' : 'ŞARAPLAR'}</p>
            </Link>
            <Link to='/catalogue' className={styles.sparkling} onClick={() => {dispatch(resetFilters(['sparkling', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderSparklings} alt="şampanya şişeleri" className={styles.renderImg}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'SPARKLING WINE' : 'KÖPÜKLÜ ŞARAPLAR'}</p>
            </Link>
            <Link to='/catalogue' className={styles.alcohol} onClick={() => {dispatch(resetFilters(['alcohol', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>  
                <img src={renderAlcohols} alt="alkol şişeleri" className={styles.renderImg}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'ALCOHOLIC BEVERAGES' : 'ALKOLLÜ İÇECEKLER'}</p>
            </Link>
            <Link to='/catalogue' className={styles.various} onClick={() => {dispatch(resetFilters(['various', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime()); window.scrollTo(0,0);}}>
                <p className={styles.fade}></p>
                <img src={renderFoods} alt="gıda kavanozları" className={styles.renderImg}/>
                <p className={styles.sectorText}>{language === 'EN' ? 'VARIOUS FOOD' : 'GIDA ÜRÜNLERİ'}</p>
            </Link>
        </div>
    )
}
export default Sectors;