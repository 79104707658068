import React from 'react';
import styles from './QuoteIcon.module.css';
import quoteIcon from '../images/picnic.png';

import { toggleQuoteBox, selectBasket } from '../routes/quote/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';

function QuoteIcon() {

    const quoteCount = useSelector(selectBasket).length;
    const dispatch = useDispatch();

    return (
        <div>
            <button onClick={() => dispatch(toggleQuoteBox())} className={styles.button}>
                <img src={quoteIcon} alt="quote icon" className={styles.image}/>
                {quoteCount ? <p className={styles.count}>{quoteCount}</p> : null}
            </button>
            
        </div>
    )
}

export default QuoteIcon;