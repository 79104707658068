import React from 'react';
import styles from './Comparison.module.css';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { selectBasket, clearQuotes, removeQuote } from './quoteSlice';

import nakedBottles from '../../images/naked_bottles.jpg';
import removeIcon from '../../images/remove.png';
import colorsDict from '../catalogue/colors';
import { selectLanguage, selectWidth } from '../../commons/appSlice';



function Comparison() {

    const language = useSelector(selectLanguage);
    const width = useSelector(selectWidth);

    const dispatch = useDispatch();

    const products = [...useSelector(selectBasket)];
    products.sort((a, b) => (a.type > b.type) ? 1 : -1);
    
    // Hover System
    function handleEnterVer(e) {
        const boxVertical = document.getElementById('boxVertical');
        if (width > 800) {
            const rect = e.target.getBoundingClientRect();
            boxVertical.style.left = `${rect.left}px`;
            boxVertical.style.display = 'block';
        } else {
            boxVertical.style.display = 'none';
        }

    };
    function handleEnterHor(e) {
        const boxHorizontal = document.getElementById('boxHorizontal');
        if(width > 800) {
            const rect = e.target.getBoundingClientRect();
            boxHorizontal.style.top = `${rect.top + window.scrollY - 2}px`;
            boxHorizontal.style.display = 'block';
        } else {
            boxHorizontal.style.display = 'none';
        }

    };

    const propertyList = ['volume', 'height', 'diameter', 'weight', 'material', 'innerNeckDiameter', 'outerNeckDiameter', 'palletHAmount', 'palletLAmount'];
    const rowNames = language === 'EN' ? {volume: 'VOLUME (ML)', height: 'HEIGHT (MM)', diameter: 'DIAMETER (MM)', weight: 'WEIGHT (G)', material: 'MATERIAL', 
                                        innerNeckDiameter: 'INNER NECK DIAMETER (MM)', outerNeckDiameter: 'OUTER NECK DIAMETER (MM)', palletHAmount: 'HIGH PALLET AMOUNT', palletLAmount: 'LOW PALLET AMOUNT'} 
                                     : {volume: 'HACİM (ML)', height: 'YÜKSEKLİK (MM)', diameter: 'ÇAP (MM)', weight: 'AĞIRLIK (G)', material: 'MALZEME TİPİ', 
                                        innerNeckDiameter: 'AĞIZ İÇ ÇAPI (MM)', outerNeckDiameter: 'AĞIZ DIŞ ÇAPI', palletHAmount: 'YÜKSEK PALET MİKTARI', palletLAmount: 'ALÇAK PALET MİKTARI'};
    
    const valueNames = language === 'EN' ? {glass: 'GLASS', metal: 'METAL', plastic: 'PLASTIC', aluminum: 'ALUMINUM', tin: 'TIN', wooden: 'WOODEN', dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', bvs30: 'BVS 30', corkFA: 'CORK FA', corkFP: 'CORK FP', stopper: 'STOPPER',
                                         gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', tm: 'TAPPO MECANICO', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', crown26: 'CROWN 26', crown29: 'CROWN 29', fc29: 'FC 29', ropp18: 'ROPP 18', vinolok: 'VINOLOK'} 
                                      : {glass: 'CAM', metal: 'METAL', plastic: 'PLASTİK', aluminum: 'ALÜMİNYUM', tin: 'TENEKE', wooden: 'AHŞAP', dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', bvs30: 'BVS 30', corkFA: 'MANTAR FA', corkFP: 'MANTAR FP', stopper: 'TIPA',
                                         gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', tm: 'MEKANİK KAPAK', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', crown26: 'TAÇ 26', crown29: 'TAÇ 29', fc29: 'FC 29', ropp18: 'ROPP 18', vinolok: 'VİNOLOK'};     


    // Dynamically change meta tags                                 
    React.useEffect(() => {
    document.title = language === 'EN' ? 'Comparison' : 'Karşılaştırma';
    document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Compare our products and find the perfect fit.' : 'Ürünlerimizi karşılaştırın ve hangisinin size uygun olduğunu bulun.';
    document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'Comparison' : 'Karşılaştırma';
    }, [language]);

    return (
        <div className={styles.Comparison}>

            <div className={styles.bannerContainer}>
                <img src={nakedBottles} className={styles.banner} alt="banner"/>
                <h1 className={styles.header}>{language === 'EN' ? 'COMPARISON CENTER' : 'KARŞILAŞTIRMA'}</h1> 
            </div>

            <section className={styles.topBar}>
                <Link to='/catalogue' className={styles.addItems}>
                    <p>{language === 'EN' ? 'ADD ITEMS' : 'ÜRÜN EKLE'}<strong> +</strong></p>
                </Link>
                <p className={styles.p}>{language === 'EN' ? 'Compare the products in your Quote Cart. Removing them here will also remove them from your Quote Cart.' : 'Teklif sepetinizdeki ürünleri karşılaştırın. Ürünleri buradan sildiğinizde teklif sepetinizden de silinecektir.'}</p>
                <Link to='/catalogue' className={styles.removeAll} onClick={() => dispatch(clearQuotes())}>
                    <p>{language === 'EN' ? 'REMOVE ALL' : 'TEMİZLE'} X</p>
                </Link>
            </section>


            <section className={styles.bottomContainer}>
                <div className={styles.leftContainer}>
                    <h6 className={styles.Model}>MODEL</h6>
                    <h6 className={styles.propertyTitle}>{language === 'EN' ? 'COLORS' : 'RENKLER'}</h6>
                    <h6 className={styles.propertyTitle}>{language === 'EN' ? 'ALL VOLUMES' : 'BÜTÜN HACİMLER'} (ML)</h6>
                    <h6 className={styles.propertyTitle}>{language === 'EN' ? 'NECK FINISH' : 'AĞIZ TİPİ'}</h6>
                    {propertyList.map(prop => <h6 className={styles.propertyTitle} key={prop}>{rowNames[prop]}</h6>)}
                </div>
                <div className={styles.productsContainer}>
                    {products.map(product =>
                        <div className={styles.singleProduct} onMouseEnter={handleEnterVer}>
                            <Link to={`/catalogue/${product.id.toLowerCase().split(" ").join('-')}`} className={styles.imageLink} key={product.id} onMouseEnter={handleEnterVer} onClick={() => window.scrollTo(0,0)}>
                                <img src={product.image} alt={product.name} className={styles.image} />
                                <h5 className={styles.productName}>{language === 'EN' ? product.name : (product.nameTR !== 'NA' ? product.nameTR : product.name)}</h5>
                            </Link>
                            <img src={removeIcon} alt="remove icon" className={styles.removeCard} style={{border: 'none'}} onClick={() => dispatch(removeQuote(product))}/>
                            <div className={styles.colorValues} onMouseEnter={handleEnterHor}>{product.colors.map(col => <p className={styles.color} style={{backgroundColor: colorsDict[col]}}></p>)}</div>
                            <div className={styles.allVolumesValues} onMouseEnter={handleEnterHor}>{product.allVolumes.length > 0 ? product.allVolumes.map(vol => <p className={styles.vol} >{vol}</p>) : <p>-</p>}</div>
                            <div className={styles.allVolumesValues} onMouseEnter={handleEnterHor}>{product.neckFinish.length > 0 ? product.neckFinish.map(neck => <p className={styles.vol} >{valueNames[neck]}</p>) : <p>-</p>}</div>
                            {propertyList.map(prop => <p key={`${product.id}${prop}`} className={styles.propertyValue} onMouseEnter={handleEnterHor}>{prop === 'material' ? (valueNames[product[prop]] ? valueNames[product[prop]] : "-") : (product[prop] === '0' || product[prop] === 'NA' ? '-' : product[prop])}</p>)}
                        </div>
                    )}
                </div>
            </section>

            <div className={styles.boxVertical} id='boxVertical'></div>
            <div className={styles.boxHorizontal} id='boxHorizontal'></div>
        </div>
    );
}

export default Comparison;