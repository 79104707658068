import React from 'react';
import styles from './Newsletter.module.css';

import backImage from '../images/newsletter.jpg';

import { useDispatch, useSelector } from 'react-redux';
import { changeEmail, handleClick, selectEmail, selectNewsletterClicked } from '../routes/contact/formSlice';
import { selectLanguage } from './appSlice';

function Newsletter() {

    const language = useSelector(selectLanguage);

    const dispatch = useDispatch();
    const email = useSelector(selectEmail);
    const newsletterClicked = useSelector(selectNewsletterClicked);
  
    function saveEmail(event) {
      event.preventDefault();
      fetch('/admin/customers', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({email: email, newsletter: true})});
    }

    return (

        <div className={styles.newsletter}>
            <img className={styles.backImage} src={backImage} alt="zeytinyağı şişeleri ve şarap şişeleri" loading="lazy"/>
            <p className={styles.fade}></p>
            { !newsletterClicked ?
            <form className={styles.Form} onSubmit={(event) => {saveEmail(event); dispatch(handleClick('newsletter'))}}>
                <h3 className={styles.text}>{language === 'EN' ? 'Sign up to our newsletter to stay up-to-date with our products and news!' : 'Ürünlerimizden ve gelişmelerden haberdar olmak için bültenimize abone olun!'}</h3>
                <input type='email' name='newsletter' placeholder={language === 'EN' ? 'Your email...' : 'E-postanız...'} value={email} className={styles.email} onChange={(event) => dispatch(changeEmail(event.target.value))} required/>
                <input type="submit" value={language === 'EN' ? 'SIGN UP' : 'ABONE OL'} className={styles.signup}/>
            </form> 
            : <h2>Thank you for subscribing!</h2>}

        </div>

    )
}

export default Newsletter;