import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',

    newsletterClicked: false,
    contactClicked: false,
    quoteClicked: false,

    newsletterChecked: true
}

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        changeName: (state, action) => {
            state.name = action.payload;
        },
        changeCompany: (state, action) => {
            state.company = action.payload;
        },
        changeEmail: (state, action) => {
            state.email = action.payload;
        },
        changePhone: (state, action) => {
            state.phone = action.payload;
        },
        changeMessage: (state, action) => {
            state.message = action.payload;
        },
        handleClick: (state, action) => {
            if (action.payload === 'newsletter') {
                state.newsletterClicked = !state.newsletterClicked;
            } else if (action.payload === 'contact') {
                state.contactClicked = !state.contactClicked;
            } else if (action.payload === 'quote') {
                state.quoteClicked = !state.quoteClicked;
            }
        },
        handleCheck: (state) => {
            state.newsletterChecked = !state.newsletterChecked;
        },
        clearAll: (state) => {
            state.name = '';
            state.company = '';
            state.email = '';
            state.phone = '';
            state.message = '';
        }
    }
})

export const {changeName, changeCompany, changeEmail, changePhone, changeMessage, handleClick, handleCheck, clearAll} = formSlice.actions;

export const selectName = (state) => state.form.name;
export const selectCompany = (state) => state.form.company;
export const selectEmail = (state) => state.form.email;
export const selectPhone = (state) => state.form.phone;
export const selectMessage = (state) => state.form.message;

export const selectNewsletterClicked = (state) => state.form.newsletterClicked;
export const selectContactClicked = (state) => state.form.contactClicked;
export const selectQuoteClicked = (state) => state.form.quoteClicked;
export const selectNewsletterChecked = (state) => state.form.newsletterChecked;

export default formSlice.reducer;