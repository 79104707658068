import React from 'react';
import styles from './About.module.css';
import arrayBottles from '../../images/arrayBottles.jpg';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../commons/appSlice';

function About() {

const language = useSelector(selectLanguage);

React.useEffect(() => {
  document.title = language === 'EN' ? 'About Us' : 'Hakkımızda';
  document.getElementsByTagName('meta')["description"].content = language === 'EN' ? 'Discover who we are and where we came from.' : 'Kim olduğumuzu ve nereden geldiğimizi keşfedin.';
  document.querySelector("meta[property='og:title']").content = language === 'EN' ? 'About Us' : 'Hakkımızda';
}, [language]);


  return (
    <div className={styles.About}>
      <div className={styles.bannerContainer}>
        <img src={arrayBottles} alt="şişeler ve kavanozlar" className={styles.image}/>
      </div>
      
      <section className={styles.text}>
        {language === 'TR' ?
          <section className={styles.text1}>
            <h3>Biz kimiz ?</h3>
            <p>
            1993 yılından beri faaliyet göstermekte olan Feniks Etiket ve Ambalaj Sanayi Ltd. Şti., zeytinyağı, sirke, şarap, alkollü içecek ve gıda sektörlerinde kullanılan ambalaj malzemeleri ve aksesuarlarını içeren bir ürün yelpazesine sahiptir. 
            Bu ürünlerin satışının yanı sıra müşterilerine tasarım, dekorasyon, uygulama ve kutulama konularında da rehberlik etmektedir. 
            Feniks Etiket ve Ambalaj’ın söz konusu ürün ve hizmetler çerçevesinde oluşturmuş olduğu bu platformun birincil amacı, potansiyel müşterilerinin ambalaj gereksinimlerine tek bir tedarik noktasında “Komple Çözüm” getirebilmektir.
            </p>
          </section>
        :
          <section className={styles.text2}>
            <h3>Who are we?</h3>
            <p>
            Established in 1993, Feniks Label and Packaging has a product range that includes packaging materials and accessories used in the olive oil, vinegar, wine, alcoholic beverage and food industries. 
            In addition to the sales of these products, it also guides its customers in design, decoration, application and packaging. 
            The primary purpose of this platform, created by Feniks Label and Packaging within the framework of the aforementioned products and services, is to bring a "Complete Solution" to the packaging requirements of its potential customers from a single supply point.
            </p>
        </section>       
        }

        <br/>
        <br/>
        <div className={styles.line}></div>
        <br/>
        <br/>

        { language === 'TR' ?
        
          <section className={styles.letter}>
              <h3>Kurucumuz Tuncay ALTINOK’tan mesaj</h3>
              <p>
              Merhaba,
              </p>
              <p>
              Telefonda çevir sesini duymak için 45 dakika bekleyen bir neslin üyesiyim ve geçtiğimiz 50 yıl boyunca iletişim sektöründe yaşanan teknolojik gelişmeler sayesinde günümüzün akıllı telefonlarını kullanmaktayız. 
              1993 yılında giriş yaptığım ambalaj sektöründe de benzer bir değişim ve doğal olarak bir gelişim sürecine şahit oldum. 
              Yurtdışı temsilcilikleri ve sektör fuarları sayesinde elde edilen tecrübeler, takip eden 30 yıl boyunca sayısız yeni ürün ve uygulamanın Feniks tarafından ülkemize taşınmasına vesile oldu ve ekibimde yer alan arkadaşlarımla beraber bu işi misyonumuz haline getirdik.
              2007 yılı itibariyle gıda ambalajı kulvarında gelişmeyi hedefleyen Feniks, global üreticilerin verdiği destek ile cam ambalaj ve aksesuarları kategorisinde ciddi bir ürün portföyü oluşturarak, bu ürünleri İstanbul ve İzmir’de kurduğu lojistik merkezler üzerinden müşterilerine ulaştırmaya başladı.
              </p>
              <p>
              Bu dönem itibariyle müşterilerimizin ambalaj konusunda yaşadığı iyi ve kötü tecrübeleri izleyerek bu izlenimleri değerlendirmeye başladık. Değerlendirmelerimiz sonucunda müşterilerimizin ihtiyaçlarına cevap verebilecek her türlü ambalaj malzemesini ve ekipmanı en doğru şekilde ve en uygun maliyet ile elde edebilecekleri, Feniks’in yanı sıra tamamlayıcı ürün üreticilerinin de yer aldığı bir “Komple Çözüm” platformu oluşturmayı odağımıza koyduk ve bu yolda ilerlemeye devam ediyoruz. Nitekim, bu web sitesi de bu hedef doğrultusunda hazırlandı. Bu platform aracılığıyla hayata geçen ve başarılı olan onlarca start-up projesinin destekçisi olmanın verdiği gurur, bize doğru istikamette ilerlediğimizi gösteriyor.
              Elbette bundan sonrası için oluşturduğumuz planlarımız, üzerinde çalıştığımız projelerimiz var ama bir şey kesin ki Feniks’in nihai rotası müşterilerinin talepleri doğrultusunda belirlenecektir.
              Bugüne kadar ürün ve hizmet portföyümüze ilgi gösteren ve bize güvenen saygıdeğer müşterilerimize çok teşekkür ederiz.
              </p>
              <p>Genel Müdür</p>
              <p>Tuncay ALTINOK</p>
          </section>
          :
          <section className={styles.letter}>
              <h3>Message from our founder, Tuncay ALTINOK</h3>
              <p>
              Hi,
              </p>
              <p>
              I am a member of a generation that had to wait 45 minutes to hear a dial tone on the phone, and now we are using today's smartphones thanks to the technological advances in the communications industry over the past 50 years. 
              I witnessed a similar change and thus a development process in the packaging industry that I entered in 1993. 
              The experiences gained through overseas representations and sector fairs have been instrumental in bringing countless new products and applications to our country in the past 30 years, and we have made this our mission.
              In 2007, aiming to thrive in the food packaging industry, we created a substantial product portfolio in glass packaging and accessories with the support of global manufacturers, and started to deliver these products to our customers through our logistics centers in Istanbul and Izmir.
              </p>
              <p>
              Since then, we've been monitoring and evaluating the good and bad experiences of our customers regarding packaging. 
              As a result of our evaluations, we've focused on creating a "Complete Solution" platform, which includes Feniks as well as  our complementary product manufacturer partners, where our customers can obtain all kinds of packaging materials and equipment that can meet their needs in the most accurate way and at the most affordable cost. 
              As a matter of fact, this website was prepared in line with this goal. Being a supporter of dozens of successful start-up projects implemented through this platform makes us proud and shows us that we are moving in the right direction.
              Of course, we do have plans for the future, projects we are working on, but one thing is certain, our direction will be determined in line with the demands of our customers.
              We would like to thank our esteemed customers who have shown interest in our products and services and trusted us.
              </p>
              <p>General Manager</p>
              <p>Tuncay ALTINOK</p>
          </section>
        }
      </section>


    </div>
  );
}

export default About;