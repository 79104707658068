import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/feniks_logo.png';
import styles1 from './Nav.module.css';
import styles2 from './NavHome.module.css';
import Icons from './Icons';
import QuoteIcon from './QuoteIcon';
import hamburgerIcon from '../images/hamburger.png';
import Language from './Language';

import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, updateProducts, changeFirstTime, selectRegion } from '../routes/catalogue/filterSlice';
import { selectLanguage, selectWidth } from './appSlice';

import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { selectBasket } from '../routes/quote/quoteSlice';

function Nav() {

  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const width = useSelector(selectWidth);
  const quoteBasket = useSelector(selectBasket);
  const region = useSelector(selectRegion);

  // Window-size Correction
  useEffect(() => {
    const nav = document.getElementById("nav");
    const ulistNav = document.getElementById("ulistNav");
    const iconsNav = document.getElementById("iconsNav");
    const languageNav = document.getElementById("languageNav");
    const burger = document.getElementById("hamburger");
    if (width > 800) {
      nav.style.height = '90px';
      ulistNav.style.display = 'grid';
      iconsNav.style.display = 'block';
      languageNav.style.display = 'block';
      burger.style.display = 'none'
    } else {
      const nav = document.getElementById("nav");
      nav.style.backgroundColor = 'white';
      nav.style.height = '50px';
      burger.style.display = 'block'
    } 
  }, [width]);

 
  const history = useHistory();
  function handleClickQuote() {
    if (width < 800){

      history.push("/quote");

      const nav = document.getElementById("nav");
      const burger = document.getElementById("hamburger");
      const ulistNav = document.getElementById("ulistNav");
      const iconsNav = document.getElementById("iconsNav");
      const languageNav = document.getElementById("languageNav");

      nav.style.height = '50px';
      ulistNav.style.display = 'none';
      iconsNav.style.display = 'none';
      languageNav.style.display = 'none';
      burger.style.transform = 'rotate(0deg)';
      
    }
  }

  function handleClickHamburger(e) {
    const ulistNav = document.getElementById("ulistNav");

    if (e.target.id === "logo" && ulistNav.style.display !== 'grid') {
      return
    }

    if (width < 800) {
      const nav = document.getElementById("nav");
      const burger = document.getElementById("hamburger");
      const iconsNav = document.getElementById("iconsNav");
      const languageNav = document.getElementById("languageNav");
  
      if (ulistNav.style.display === 'grid'){
        nav.style.height = '50px';
        ulistNav.style.display = 'none';
        iconsNav.style.display = 'none';
        languageNav.style.display = 'none';
        burger.style.transform = 'rotate(0deg)';
      } else {
        nav.style.height = '100vh';
        ulistNav.style.display = 'grid';
        iconsNav.style.display = 'block';
        languageNav.style.display = 'block';
        burger.style.display = 'block';
        burger.style.transform = 'rotate(90deg)';
      }
    } else {
        const burger = document.getElementById("hamburger");
        burger.style.display = 'none';
    }

  }


  const path = useLocation().pathname;
  const styles = path === "/" || path === "/solutions" || path === "/blog" ? styles2 : styles1;

  const borderStyle = {borderBottom: 'solid thick rgb(218,197,84)'};

  return ( 
    <nav className={styles.Nav} id="nav">
      <Link to='/'>
        <img src={logo} alt="logo" className={styles.logo} onClick={handleClickHamburger} id="logo"/>
      </Link>
      <div className={styles.ulist} id="ulistNav">
          <div className={styles.dropDown}>
            <Link to='/catalogue' className={styles.dropDownButton} onClick={handleClickHamburger}>
              <p className={styles.dropDownButtonText} style={path === '/catalogue' ? borderStyle : null}>{language === 'EN' ? 'PRODUCTS' : 'ÜRÜNLER'}</p>
            </Link>
            <div className={styles.dropDownContent} id="dropDownContent" >
                <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['oliveOil', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}} >
                    <p>{language === 'EN' ? 'OLIVE OIL AND VINEGAR' : 'ZEYTİNYAĞI VE SİRKELER'}</p>
                </Link>
                <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['wine', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}}>
                    <p>{language === 'EN' ? 'STILL WINE' : 'ŞARAPLAR'}</p>
                </Link>
                <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['sparkling', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}}>
                    <p>{language === 'EN' ? 'SPARKLING WINE' : 'KÖPÜKLÜ ŞARAPLAR'}</p>
                </Link>
                <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['alcohol', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}}>
                <   p>{language === 'EN' ? 'ALCOHOLIC BEVERAGES' : 'ALKOLLÜ İÇECEKLER'}</p>
                </Link>
                <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['various', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}}>
                    <p>{language === 'EN' ? 'VARIOUS FOOD' : 'GIDA ÜRÜNLERİ'}</p>
                </Link>
                {region === 'INT' ? <Link to='/catalogue' className={styles.secli} onClick={() => {dispatch(resetFilters(['cosmetics', 'bottle'])); dispatch(updateProducts()); dispatch(changeFirstTime(false))}}>
                    <p>{language === 'EN' ? 'COSMETICS' : 'KOZMETİK'}</p>
                </Link> : null}
            </div>
          </div>
          <Link to='/solutions' className={styles.li} onClick={handleClickHamburger}>
            <p className={styles.buttonText} style={path === '/solutions' ? borderStyle : null}>{language === 'EN' ? 'SOLUTIONS' : 'ÇÖZÜMLER'}</p>
          </Link>
          <Link to='/about' className={styles.li} onClick={handleClickHamburger}>
            <p className={styles.buttonText} style={path === '/about' ? borderStyle : null}>{language === 'EN' ? 'ABOUT US' : 'HAKKIMIZDA'}</p>
          </Link>
          <Link to='/blog' className={styles.li} onClick={handleClickHamburger}>
            <p className={styles.buttonText} style={path === '/blog' ? borderStyle : null}>{language === 'EN' ? 'BLOG' : 'BLOG'}</p>
          </Link>
          <Link to='/contact' className={styles.li} onClick={handleClickHamburger}>
            <p className={styles.buttonText} style={path === '/contact' ? borderStyle : null}>{language === 'EN' ? 'CONTACT' : 'İLETİŞİM'}</p>
          </Link>
      </div>
      <section></section>

      <div className={styles.Icons} id="iconsNav" onClick={handleClickHamburger}>
        <Icons />
      </div>
      <div className={styles.Language} id="languageNav" onClick={handleClickHamburger}>
        <Language/>
      </div>

      
      <div className={styles.quoteIcon} onClick={handleClickQuote}>
        <QuoteIcon />
      </div>

      <img src={hamburgerIcon} alt="menu icon" onClick={handleClickHamburger} className={styles.hamburgerIcon} id="hamburger"/>
    </nav>
    
  );
}

export default Nav;