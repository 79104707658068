import React from 'react';
import styles from './Regions.module.css';

import logo from '../images/feniks_logo.png';
import backgroundImage from '../images/solutions2.jpg';
import { toggleLanguageFilter, toggleRegion } from '../routes/catalogue/filterSlice';
import { useDispatch } from 'react-redux';
import { toggleLanguage } from './appSlice';

function Regions() {

    const dispatch = useDispatch();

    function handleClick() {
        document.getElementById("Regions").style.display = "none";
    }

    return (
        <div className={styles.Regions} id="Regions">

            <img src={logo} alt="logo" className={styles.logo}/>

            <section className={styles.tr} onClick={() => {handleClick(); dispatch(toggleRegion('TR'))}}>
                <h1 className={styles.header}>FENİKS AMBALAJ'A HOŞ GELDİNİZ</h1>
                <h4 className={styles.text}>LÜTFEN BÖLGENİZİ SEÇİNİZ</h4>
                <button className={styles.button}>TÜRKİYE</button>
            </section>

            <section className={styles.int} onClick={() => {dispatch(toggleRegion('INT')); dispatch(toggleLanguageFilter('EN')); handleClick(); dispatch(toggleLanguage('EN'))}}>
                <h1 className={styles.header}>WELCOME TO FENIKS PACKAGING</h1>
                <h4 className={styles.text}>PLEASE PICK YOU REGION</h4>
                <button className={styles.button}>OUTSIDE OF TURKEY</button>
            </section>


            <img src={backgroundImage} alt="zeytinyagi sisesi" className={styles.image}/>

        </div>
    )
};

export default Regions;

