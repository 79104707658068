import React from 'react';
import styles from './ProductPage.module.css';
import {Link} from 'react-router-dom';

import { resetFilters, updateProducts, selectAllProducts, changeFirstTime} from './filterSlice';

import { addQuote, removeQuote, toggleQuoteBox, selectBasket } from '../quote/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';

import nextIcon from '../../images/next.svg';
import compareIcon from '../../images/compare.svg';
import plusIcon from '../../images/plus.png';
import removeIcon from '../../images/remove.png';
import colorsDict from './colors';
import { selectLanguage, selectWidth } from '../../commons/appSlice';

import { CreatePDF } from './PDFGenerator';
import logoGif from '../../images/logo_trans.gif';


function ProductPage(props) {

    const language = useSelector(selectLanguage);
    const width = useSelector(selectWidth); 

    const dispatch = useDispatch();
    const quoteBasket = useSelector(selectBasket);
    const productArray = useSelector(selectAllProducts);
    const compareColor = {backgroundColor: 'rgba(218,197,84,0.5)', borderColor: 'rgba(218,197,84,0.4)'};

    const productID = props.match.params.product;

    const currentProduct = productArray.find(prod => prod.id.toLowerCase() === productID);

    // Resets scroll of related products & changes page title
    React.useEffect(() => {
        if (currentProduct !== undefined) {
            if (document.getElementById('compBottle')){
                document.getElementById('compBottle').scrollLeft = 0;
            }
            if (document.getElementById('compCap')){
                document.getElementById('compCap').scrollLeft = 0;
            }
            if (document.getElementById('compMachine')){
                document.getElementById('compMachine').scrollLeft = 0;
            }

            // Change page title
            document.title = language === 'EN' ? currentProduct.name : currentProduct.nameTR;
            document.querySelector("meta[property='og:title']").content = language === 'EN' ? currentProduct.name : currentProduct.nameTR;
        }
    }, [currentProduct]);


    // Loading state for pdf button
    const [loading, setLoading] = React.useState(false);

    // Adjusts background element height
    React.useEffect(() => {
        if (document.getElementById("background") && width < 800) {
            document.getElementById("background").style.height = document.getElementById("container").offsetHeight + 40 + "px";
        }
    });


    // If product undefined (for initial load as well)
    if (currentProduct === undefined) {
        return null;
    }

    // Compatible/Related Products
    const neckFinish = currentProduct.neckFinish;

    let compatibleBottles = [];
    if (currentProduct.type === 'bottle'){
        compatibleBottles = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'bottle' && prod.sector === currentProduct.sector && prod.volume === currentProduct.volume));
        if (compatibleBottles.length === 0){
            compatibleBottles = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'bottle' && prod.sector === currentProduct.sector && Math.abs(prod.volume - currentProduct.volume) < 100));
        }
        compatibleBottles.sort(function(a, b){return Math.abs(currentProduct.height - a.height) - Math.abs(currentProduct.height - b.height)});

    } else if (currentProduct.type === 'cap'){
        compatibleBottles = productArray.filter(prod => (prod.id !== currentProduct.id && (prod.type === 'bottle' || prod.type === 'jar') && prod.sector === currentProduct.sector && (prod.neckFinish.includes(neckFinish[0]) || prod.neckFinish.includes(neckFinish[1]) ||
        prod.neckFinish.includes(neckFinish[2]) || prod.neckFinish.includes(neckFinish[3]) ||
        prod.neckFinish.includes(neckFinish[4]) || prod.neckFinish.includes(neckFinish[5]))));
    } else if (currentProduct.type === 'capsule' || currentProduct.type === 'outer') {
        compatibleBottles = productArray.filter(prod => (prod.type === 'bottle' && prod.sector === currentProduct.sector));
    }

    let compatibleJars = [];
    if (currentProduct.type === 'jar'){
        compatibleJars = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'jar' && prod.sector === currentProduct.sector && prod.volume === currentProduct.volume));
        if (compatibleJars.length === 0){
            compatibleJars = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'jar' && prod.sector === currentProduct.sector && Math.abs(prod.volume - currentProduct.volume) < 50));
        }
        compatibleJars.sort(function(a, b){return Math.abs(currentProduct.height - a.height) - Math.abs(currentProduct.height - b.height)});

    } else if (currentProduct.type === 'cap'){
        compatibleJars = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'jar' && prod.sector === currentProduct.sector && (prod.neckFinish.includes(neckFinish[0]) || prod.neckFinish.includes(neckFinish[1]) ||
        prod.neckFinish.includes(neckFinish[2]) || prod.neckFinish.includes(neckFinish[3]) ||
        prod.neckFinish.includes(neckFinish[4]) || prod.neckFinish.includes(neckFinish[5]))));
    }
    
    
    let compatibleCaps = [];
    if (currentProduct.type === 'bottle' || currentProduct.type === 'jar'){
        compatibleCaps = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'cap' && prod.sector === currentProduct.sector && (prod.neckFinish.includes(neckFinish[0]) || prod.neckFinish.includes(neckFinish[1]) ||
                                                                                                                                                        prod.neckFinish.includes(neckFinish[2]) || prod.neckFinish.includes(neckFinish[3]) ||
                                                                                                                                                        prod.neckFinish.includes(neckFinish[4]) || prod.neckFinish.includes(neckFinish[5]))));
    } else if (currentProduct.type === 'cap'){
        compatibleCaps = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'cap' && prod.sector === currentProduct.sector && (prod.neckFinish.includes(neckFinish[0]) || prod.neckFinish.includes(neckFinish[1]) ||
                                                                                                                                                        prod.neckFinish.includes(neckFinish[2]) || prod.neckFinish.includes(neckFinish[3]) ||
                                                                                                                                                        prod.neckFinish.includes(neckFinish[4]) || prod.neckFinish.includes(neckFinish[5]))));
    }

    let compatibleMachines = [];
    if(currentProduct.type === 'machine') {
        compatibleMachines = productArray.filter(prod => (prod.id !== currentProduct.id && prod.type === 'machine' && prod.sector === currentProduct.sector));
    } else if (currentProduct.type === 'bottle' || currentProduct.type === 'jar') {
        compatibleMachines = productArray.filter(prod => (prod.type === 'machine' && prod.sector === currentProduct.sector && ((prod.neckFinish.includes(neckFinish[0]) || prod.neckFinish.includes(neckFinish[1]) ||
                                                                                                                                prod.neckFinish.includes(neckFinish[2]) || prod.neckFinish.includes(neckFinish[3]) ||
                                                                                                                            prod.neckFinish.includes(neckFinish[4]) || prod.neckFinish.includes(neckFinish[5])) || prod.machineType !== 'capper')));
        if (neckFinish.includes('spray')) {
            compatibleMachines = productArray.filter(prod => (prod.type === 'machine' && prod.neckFinish.includes(neckFinish[0])));
        } else if (neckFinish.includes('tap')) {
            compatibleMachines = productArray.filter(prod => (prod.type === 'machine' && prod.neckFinish.includes(neckFinish[0])));
        } else if (currentProduct.name === 'TIN') {
            compatibleMachines = [];
        }                                                                                                            
    } else if (currentProduct.type === 'cap') {
        compatibleMachines = productArray.filter(prod => (prod.type === 'machine' && prod.neckFinish.includes(neckFinish[0]) && prod.sector === currentProduct.sector));
    } else if (currentProduct.type === 'capsule') {
        if (currentProduct.name === 'PVC CAPSULE') {
            compatibleMachines = productArray.filter(prod => (prod.type === 'machine' && prod.name === 'HEAT SHRINKER FOR PVC CAPSULES' && prod.sector === currentProduct.sector));
        } else {
            compatibleMachines = [];
        }
        
    }


    // Breadcrumbs name fixer
    const breadCrumbNames = {oliveOil: ['OLIVE OIL AND VINEGAR', 'ZEYTİNYAĞI VE SİRKELER'], wine: ['WINE', 'ŞARAPLAR'], sparkling: ['SPARKLING WINE', 'KÖPÜKLÜ ŞARAPLAR'], alcohol: ['ALCOHOLIC BEVERAGES', 'ALKOLLÜ İÇECEKLER'],
                             various: ['VARIOUS FOOD', 'GIDA ÜRÜNLERİ'], bottle: ['BOTTLE', 'ŞİŞE'], jar: ['JAR', 'KAVANOZ'], capsule: ['CAPSULE', 'KAPUŞON'], machine: ['MACHINE', 'MAKİNE'], outer: ['OUTER PACKAGING', 'DIŞ AMBALAJ'], cap: ['CAP', 'KAPAK'],
                             round: ['ROUND', 'DAİRESEL'], square: ['SQUARE', 'KARE'], elliptical: ['ELLIPTICAL', 'ELİPS'], rectangular: ['RECTANGULAR', 'DİKDÖRTGEN'], glass: ['GLASS', 'CAM'], metal: ['METAL', 'METAL'], 
                             plastic: ['PLASTIC', ' PLASTİK'], aluminum: ['ALUMINUM', 'ALÜMİNYUM'], tin: ['TIN', 'TENEKE'], wooden: ['WOODEN', 'AHŞAP'], laminated: ["LAMINATED", "LAMİNE"], vacuumFiller: ['VACUUM FILLER', 'VAKUMLU DOLUM MAKİNESİ'], volumetricFiller: ['VOLUMETRIC FILLER', 'HACİMSEL DOLUM MAKİNESİ'],
                             complementary: ['COMPLEMENTARY MACHINES', 'TAMAMLAYICI MAKİNELER'], customSolutions: ['CUSTOM SOLUTIONS', 'ÖZEL ÇÖZÜMLER'], capper: ['CAPPER', 'KAPATMA MAKİNESİ'], labeler: ['LABELLER', 'ETİKETLEME MAKİNESİ']};

             

    let currentSector = language === 'EN' ? breadCrumbNames[currentProduct.sector][0] : breadCrumbNames[currentProduct.sector][1];
    let currentType = language === 'EN' ? breadCrumbNames[currentProduct.type][0] : breadCrumbNames[currentProduct.type][1];
    let currentShape = '';
    let currentMaterial = '';
    let currentMachineType = '';
    if (currentProduct.shape && currentProduct.shape !== 'NA'){
        currentShape = language === 'EN' ? breadCrumbNames[currentProduct.shape][0] : breadCrumbNames[currentProduct.shape][1];
    }
    if (currentProduct.material && currentProduct.material !== 'NA'){
        currentMaterial = language === 'EN' ? breadCrumbNames[currentProduct.material][0] : breadCrumbNames[currentProduct.material][1];
    }
    if (currentProduct.machineType && currentProduct.machineType !== 'NA'){
        currentMachineType = language === 'EN' ? breadCrumbNames[currentProduct.machineType][0] : breadCrumbNames[currentProduct.machineType][1];
    }
    // Displayed product properties
    let productProperties = ['weight', 'diameter', 'height', 'neckFinish'];
    let productProperties2 = ['innerNeckDiameter', 'outerNeckDiameter', 'palletLAmount', 'palletHAmount'];
    if (currentProduct.type === 'cap') {
        productProperties = ['neckFinish'];
    } else if (currentProduct.type === 'accessories') {
        productProperties = [];  
    } else if (currentProduct.type === 'machine') {
        productProperties = [];
    }
    
    const units = {weight: 'g', diameter: 'mm', height: 'mm', neckFinish: '', innerNeckDiameter: 'mm', outerNeckDiameter: 'mm',
                   palletLAmount: '', palletHAmount: ''}

    const propertyNamesEN = {neckFinish: 'NECK FINISH', innerNeckDiameter: 'INNER NECK DIAMETER', outerNeckDiameter: 'OUTER NECK DIAMETER',
                             palletLAmount: 'LOW PALLET AMOUNT', palletHAmount: 'HIGH PALLET AMOUNT'};
    const propertyNamesTR = {weight: 'AĞIRLIK', neckFinish: 'AĞIZ TİPİ', height: 'YÜKSEKLİK', diameter: 'ÇAP',
                             innerNeckDiameter: 'AĞIZ İÇ ÇAPI', outerNeckDiameter: 'AĞIZ DIŞ ÇAPI',
                             palletLAmount: 'ALÇAK PALET MİKTARI', palletHAmount: 'YÜKSEK PALET MİKTARI'};

    // onHover Slide/Scroll

    let leftIntervalBottle = 'left';
    let rightIntervalBottle = 'right';
    let leftIntervalCap = 'left';
    let rightIntervalCap = 'right';
    let leftIntervalMachine = 'left';
    let rightIntervalMachine = 'right';
    function handleEnter(e) {
        e.preventDefault();
        if (e.target.id === 'leftCompBottle') {
            leftIntervalBottle = setInterval(() => document.getElementById('compBottle').scrollLeft -= 4, 5);
        } else if (e.target.id === 'rightCompBottle') {
            rightIntervalBottle =  setInterval(() => document.getElementById('compBottle').scrollLeft += 4, 5);
        } else if (e.target.id === 'leftCompCap') {
            leftIntervalCap =  setInterval(() => document.getElementById('compCap').scrollLeft -= 4, 5);
        } else if (e.target.id === 'rightCompCap') {
            rightIntervalCap =  setInterval(() => document.getElementById('compCap').scrollLeft += 4, 5);
        } else if (e.target.id === 'leftCompMachine') {
            leftIntervalMachine =  setInterval(() => document.getElementById('compMachine').scrollLeft -= 4, 5);
        } else {
            rightIntervalMachine =  setInterval(() => document.getElementById('compMachine').scrollLeft += 4, 5);
        }
    }
    function handleOut(e) {
        e.preventDefault();
        if (e.target.id === 'leftCompBottle') {
            clearInterval(leftIntervalBottle);
        } else if (e.target.id === 'rightCompBottle') {
            clearInterval(rightIntervalBottle);
        } else if (e.target.id === 'leftCompCap') {
            clearInterval(leftIntervalCap);
        } else if (e.target.id === 'rightCompCap') {
            clearInterval(rightIntervalCap);
        } else if (e.target.id === 'leftCompMachine') {
            clearInterval(leftIntervalMachine);
        } else {
            clearInterval(rightIntervalMachine);
        }
    }
    // function handleTouch(e) {
    //     if (e.target.id === 'leftCompBottle') {
    //         clearInterval(leftIntervalBottle);
    //     } else if (e.target.id === 'rightCompBottle') {
    //         clearInterval(rightIntervalBottle);
    //     } else if (e.target.id === 'leftCompCap') {
    //         clearInterval(leftIntervalCap);
    //     } else if (e.target.id === 'rightCompCap') {
    //         clearInterval(rightIntervalCap);
    //     } else if (e.target.id === 'leftCompMachine') {
    //         clearInterval(leftIntervalMachine);
    //     } else {
    //         clearInterval(rightIntervalMachine);
    //     }
    // }


    const neckNames = language === 'EN' ? {glass: 'GLASS', metal: 'METAL', plastic: 'PLASTIC', aluminum: 'ALUMINUM', tin: 'TIN', dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', bvs30: 'BVS 30', corkFA: 'CORK FA', corkFP: 'CORK FP', stopper: 'STOPPER',
                                                gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', tm: 'TAPPO MECANICO', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', crown26: 'CROWN 26', crown29: 'CROWN 29', fc29: 'FC 29', ropp18: 'ROPP 18', vinolok: 'VINOLOK', tap: "TAP", spray: "SPRAY"} 
                                         : {glass: 'CAM', metal: 'METAL', plastic: 'PLASTİK', aluminum: 'ALÜMİNYUM', tin: 'TENEKE', dop: 'DOP', ropp315: 'ROPP 31.5', ropp35: 'ROPP 35', bvs30: 'BVS 30', corkFA: 'MANTAR FA', corkFP: 'MANTAR FP', stopper: 'TIPA',
                                                gpi: 'GPI', gcmi: 'GCMI', twistOff: 'TWIST-OFF', rical29: 'RICAL 29', rical32: 'RICAL 32', tm: 'MEKANİK KAPAK', ropp24: 'ROPP 24', ropp25: 'ROPP 25', ropp28: 'ROPP 28', crown26: 'TAÇ 26', crown29: 'TAÇ 29', fc29: 'FC 29', ropp18: 'ROPP 18', vinolok: 'VİNOLOK', tap: "MUSLUK", spray: "SPREY"};                           

                                         

    // Download PDF Handle
    async function handlePDFClick() {
        let logo = document.getElementById("logo");
        let image = new Image();
        image.src = currentProduct.image + '?v=' + Math.random();
        image.setAttribute('crossOrigin', 'anonymous');
        await CreatePDF(currentProduct, image, logo, language);
        setLoading(false);
    }


    return (
        <div className={styles.ProductPage}>
 

            <ul className={styles.crumlist}>
                <Link to='/catalogue' className={styles.crum}><li>{'<'}</li></Link>
                <Link to='/catalogue' onClick={() => {dispatch(resetFilters([currentProduct.sector])); dispatch(updateProducts()); dispatch(changeFirstTime(false));}} className={styles.crum}><li>{currentSector}</li></Link>
                <li style={{color: 'black'}}>/</li>
                <Link to='/catalogue' onClick={() => {dispatch(resetFilters([currentProduct.sector, currentProduct.type])); dispatch(updateProducts()); dispatch(changeFirstTime(false));}} className={styles.crum}><li>{currentType}</li></Link>
                {currentProduct.type !== 'cap' && currentProduct.type !== 'capsule'  && currentProduct.type !== 'outer'? <li style={{color: 'black'}}>/</li> : null}
                {currentProduct.type === 'bottle' || currentProduct.type === 'jar' ? <Link to='/catalogue' onClick={() => {dispatch(resetFilters([currentProduct.sector, currentProduct.type, currentProduct.shape])); dispatch(updateProducts()); dispatch(changeFirstTime(false));}} className={styles.crum}><li>{currentShape}</li></Link> : null}
                {currentProduct.type === 'machine' ? <Link to='/catalogue' onClick={() => {dispatch(resetFilters([currentProduct.sector, currentProduct.type, currentProduct.machineType])); dispatch(updateProducts())}} className={styles.crum}><li>{currentMachineType}</li></Link> : null}
                {currentProduct.type === 'bottle' || currentProduct.type === 'jar' ? <li style={{color: 'black'}}>/</li> : null}
                {currentProduct.type === 'bottle' || currentProduct.type === 'jar' ? <Link to='/catalogue' onClick={() => {dispatch(resetFilters([currentProduct.sector, currentProduct.type, currentProduct.shape, currentProduct.material])); dispatch(updateProducts()); dispatch(changeFirstTime(false));}} className={styles.crum}><li>{currentMaterial}</li></Link> : null}
            </ul>
            
            <div className={styles.topContainer}>
                <img id="productImage" src={currentProduct.image} alt={currentProduct.name} className={styles.image}/>
                <section className={styles.container} id="container">

                    <h1 className={styles.name}>{language === 'EN' ? currentProduct.name : (currentProduct.nameTR !== 'NA' ? currentProduct.nameTR : currentProduct.name)}</h1>

                    <div className={styles.descriptionContainer}>
                        {(currentProduct.type !== 'bottle' && currentProduct.type !== 'jar') || (currentProduct.type === 'bottle' && (currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap' || currentProduct.neckFinish[0] === 'spray')) ? (language === 'EN' ? currentProduct.description.split("%").map(text => <p key={text}>{text}</p>) : currentProduct.descriptionTR.split("%").map(text => <p key={text}>{text}</p>)) : null}
                    </div>

                    <div>
                        {(currentProduct.type === 'bottle' && !(currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap' || currentProduct.neckFinish[0] === 'spray')) || currentProduct.type === 'jar' ? currentProduct.colors.map(color => <p key={color} className={styles.colors} style={{backgroundColor: colorsDict[color]}}></p>) : null} 
                    </div>
                    


                    { (currentProduct.type === 'bottle' && !(currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap')) || (currentProduct.type === 'jar') ?
                        <section className={styles.allVolumesContainer}>    
                            <p><strong>{language === 'EN' ? 'AVAILABLE VOLUMES' : 'BÜTÜN HACİMLER'}:</strong></p>
                            <div className={styles.volumeLinkContainer}>
                                {currentProduct.allVolumes.map(vol => <Link className={styles.linkVolume} style={Number(currentProduct.volume) === vol ? {backgroundColor: 'white', color: 'rgb(218,197,84)'} : {}} key={`${currentProduct.id}${vol}`} to={`/catalogue/${currentProduct.id.split('-').slice(0,-1).join('-')}-${vol}`}>{vol}</Link>)}
                            </div>
                            
                        </section>
                    : null}

                    <br/>

                    {/* Product Properties */}
                    {(currentProduct.type === 'bottle' && !(currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap' || currentProduct.neckFinish[0] === 'spray')) || currentProduct.type === 'jar' ? 

                        <div className={styles.propsParentContainer}>

                            <div className={styles.propsContainer}>
                                <div className={styles.propsNames}>           
                                    {Object.entries(currentProduct).map(([key, value]) => {
                                        if (productProperties.includes(key) && value !== null) {
                                            if (language === 'EN'){
                                                const upperKey = key.toUpperCase();
                                                return <p key={key} className={styles.productProperties}>{propertyNamesEN.hasOwnProperty(key) ? propertyNamesEN[key] : upperKey}</p>
                                            } else {
                                                return <p key={key} className={styles.productProperties}>{propertyNamesTR[key]}</p>   
                                            }
                                        } else {
                                            return null
                                        }
                                    })} 
                                </div>
                                <div className={styles.propsValues}>           
                                    {Object.entries(currentProduct).map(([key, value]) => {
                                        if (productProperties.includes(key) && value !== null) {
                                            if (typeof value === "string"){
                                                const upperValue = value.toUpperCase();
                                                return <p key={key} className={styles.productValues}>{upperValue} {units[key]}</p>
                                            } else {
                                                return value.length > 0 ? <div key={key} className={styles.neckFinishDiv}>{value.map(val => <p key={key + val} className={styles.productValues}>{neckNames[val]}</p>)}</div> : <p className={styles.productValues}>-</p>
                                            }
                                        } else {
                                            return null
                                        }
                                    })} 
                                </div>
                            </div>

                            <div className={styles.propsContainer2}>
                                <div className={styles.propsNames}>           
                                    {Object.entries(currentProduct).map(([key, value]) => {
                                        if (productProperties2.includes(key) && value !== null) {
                                            if (language === 'EN'){
                                                const upperKey = key.toUpperCase();
                                                return <p key={key} className={styles.productProperties}>{propertyNamesEN.hasOwnProperty(key) ? propertyNamesEN[key] : upperKey}</p>
                                            } else {
                                                return <p key={key} className={styles.productProperties}>{propertyNamesTR[key]}</p>   
                                            }
                                        } else {
                                            return null
                                        }
                                    })} 
                                </div>
                                <div className={styles.propsValues}>           
                                    {Object.entries(currentProduct).map(([key, value]) => {
                                        if (productProperties2.includes(key) && value !== null) {
                                            if (typeof value === "string"){
                                                const upperValue = value.toUpperCase();
                                                return <p key={key} className={styles.productValues}>{value === '0' ? '-' : upperValue + ' ' + units[key]} </p>
                                            } else {
                                                return <div key={key} className={styles.neckFinishDiv}>{value.map(val => <p key={key + val} className={styles.productValues}>{neckNames[val]}</p>)}</div>
                                            }
                                        } else {
                                            return null
                                        }
                                    })} 
                                </div>
                            </div>
                            <br/>
                        </div>
                    :
                    null}



                    <div className={styles.buttons}>
                        {!quoteBasket.includes(currentProduct) ? <button onClick={() => { dispatch(addQuote(currentProduct)); dispatch(toggleQuoteBox(true))}} className={styles.quoteButton}>{language === 'EN' ? 'ADD QUOTE' : 'TEKLİF AL'}</button> : 
                        <button onClick={() => dispatch(removeQuote(currentProduct))} className={styles.quoteButton}>{language === 'EN' ? 'REMOVE' : 'KALDIR'}</button>
                        // <button className={styles.addedButton}>{language === 'EN' ? 'ADDED' : 'EKLENDİ'}</button>
                        }
                        <Link to='/comparison' className={styles.compareLink} onClick={() => dispatch(addQuote(currentProduct))}><button className={styles.compareButton}>{language === 'EN' ? 'COMPARE' : 'KARŞILAŞTIR'}</button></Link>
                        {(currentProduct.type === 'bottle' || currentProduct.type === 'jar') && !(currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap') ? <button className={styles.pdfButton} onClick={() => {setLoading(true);}}>{loading ? <img src={logoGif} alt="logo gif" style={{display: 'none'}} onLoad={() => {handlePDFClick();}}/> : null} {language === 'EN' ? (loading ? 'Generating...' : 'DOWNLOAD PDF') : (loading ? 'Hazırlanıyor...' : 'PDF İNDİR') }</button> : null}
                    </div>
                    
                </section>
            </div>
        
            {/* Relateds */}
           <div >
                {(currentProduct.type !== 'machine' && currentProduct.type !== 'jar') ? <section className={styles.compContainer} >
                    <img src={nextIcon} alt='scroll left' className={styles.previousIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='leftCompBottle'/>
                    <img src={nextIcon} alt='scroll right' className={styles.nextIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='rightCompBottle'/>
                    <h3 className={styles.compTitles}>{currentProduct.type === 'bottle' ? (language === 'EN' ? 'RELATED BOTTLES' : 'BENZER ŞİŞELER') : (language === 'EN' ? 'COMPATIBLE BOTTLES' : 'UYUMLU ŞİŞELER')}</h3>
                    <div className={styles.compBottle} id='compBottle'>
                        {compatibleBottles.map(bott => 
                            <div className={styles.productCard} key={bott.id}>
                                <Link to={`/catalogue/${bott.id}`} onClick={() => window.scrollTo(0, 0)} className={styles.compLink}>
                                    <img src={bott.image} alt={bott.name} className={styles.imageComp}/>
                                    <p className={styles.relatedName}>{language === 'EN' ? bott.name : (bott.nameTR !== 'NA' ? bott.nameTR : bott.name)}</p>
                                    {!(currentProduct.material === 'tin' || currentProduct.neckFinish[0] === 'tap') ? <p className={styles.relatedVolume}>{bott.volume}ml</p> : null}
                                </Link>
                                <img src={compareIcon} alt="compare icon" style={quoteBasket.includes(bott) ? compareColor : null} className={styles.compareCard} onClick={() => dispatch(addQuote(bott))}/>
                                {!quoteBasket.includes(bott) ? <img src={plusIcon} alt="plus icon" className={styles.addCard} onClick={() => dispatch(addQuote(bott))}/> : <img src={removeIcon} alt="remove icon" className={styles.removeCard} style={{border: 'none'}} onClick={() => dispatch(removeQuote(bott))}/>}
                            </div>
                        )}
                    </div>
                </section> : null}
                {currentProduct.type === 'jar' ? <section className={styles.compContainer} >
                    <img src={nextIcon} alt='scroll left' className={styles.previousIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='leftCompBottle'/>
                    <img src={nextIcon} alt='scroll right' className={styles.nextIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='rightCompBottle'/>
                    <h3 className={styles.compTitles}>{currentProduct.type === 'jar' ? (language === 'EN' ? 'RELATED JARS' : 'BENZER KAVANOZLAR') : (language === 'EN' ? 'COMPATIBLE JARS' : 'UYUMLU KAVANOZLAR')}</h3>
                    <div className={styles.compBottle} id='compBottle'>
                        {compatibleJars.map(bott => 
                            <div className={styles.productCard} key={bott.id}>
                                <Link to={`/catalogue/${bott.id}`} onClick={() => window.scrollTo(0, 0)} className={styles.compLink}>
                                    <img src={bott.image} alt={bott.name} className={styles.imageComp}/>
                                    <p className={styles.relatedName}>{language === 'EN' ? bott.name : (bott.nameTR !== 'NA' ? bott.nameTR : bott.name)}</p>
                                    <p className={styles.relatedVolume}>{bott.volume}ml</p>
                                </Link>
                                <img src={compareIcon} alt="compare icon" style={quoteBasket.includes(bott) ? compareColor : null} className={styles.compareCard} onClick={() => dispatch(addQuote(bott))}/>
                                {!quoteBasket.includes(bott) ? <img src={plusIcon} alt="plus icon" className={styles.addCard} onClick={() => dispatch(addQuote(bott))}/> : <img src={removeIcon} alt="remove icon" className={styles.removeCard} style={{border: 'none'}} onClick={() => dispatch(removeQuote(bott))}/>}
                            </div>
                        )}
                    </div>
                </section> : null}
                {currentProduct.type !== 'machine' && compatibleCaps.length !== 0 ? <section className={styles.compContainer}>
                    <img src={nextIcon} alt='scroll left' className={styles.previousIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='leftCompCap'/>
                    <img src={nextIcon} alt='scroll right' className={styles.nextIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='rightCompCap'/>
                    <h3 className={styles.compTitles}>{currentProduct.type === 'cap' ? (language === 'EN' ? 'RELATED CAPS' : 'BENZER KAPAKLAR') : (language === 'EN' ? 'COMPATIBLE CAPS' : 'UYUMLU KAPAKLAR')}</h3>
                    <div className={styles.compBottle} id='compCap'>
                        {compatibleCaps.map(cap =>
                            <div className={styles.productCard} key={cap.id}>
                                <Link  to={`/catalogue/${cap.id}`} onClick={() => window.scrollTo(0, 0)} className={styles.compLink}>
                                    <img src={cap.image} alt={cap.name} className={styles.imageComp}/>
                                    <p className={styles.relatedName}>{language === 'EN' ? cap.name : (cap.nameTR !== 'NA' ? cap.nameTR : cap.name)}</p>
                                </Link>
                                <img src={compareIcon} alt="compare icon" style={quoteBasket.includes(cap) ? compareColor : null} className={styles.compareCard} onClick={() => dispatch(addQuote(cap))}/>
                                {!quoteBasket.includes(cap) ? <img src={plusIcon} alt="plus icon" className={styles.addCard} onClick={() => dispatch(addQuote(cap))}/> : <img src={removeIcon} alt="remove icon" className={styles.removeCard} style={{border: 'none'}} onClick={() => dispatch(removeQuote(cap))}/>}
                            </div>
                        )}
                    </div>
                </section> : null}
                {currentProduct.type !== 'outer' ? <section className={styles.compContainer}>
                    <img src={nextIcon} alt='scroll left' className={styles.previousIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='leftCompMachine'/>
                    <img src={nextIcon} alt='scroll right' className={styles.nextIcon} onMouseEnter={width > 800 ? handleEnter : null} onMouseOut={width > 800 ? handleOut : null} id='rightCompMachine'/>
                    <h3 className={styles.compTitles}>{language === 'EN' ? 'RELATED MACHINES' : 'ALAKALI MAKİNELER'} </h3>
                    <div className={styles.compBottle} id='compMachine'>
                        {compatibleMachines.map(machine => 
                            <div className={styles.productCard} key={machine.id}>
                                <Link to={`/catalogue/${machine.id}`} onClick={() => window.scrollTo(0, 0)} className={styles.compLink}>
                                    <img src={machine.image} alt={machine.name} className={styles.imageComp}/>
                                    <p className={styles.relatedName}>{language === 'EN' ? machine.name : (machine.nameTR !== 'NA' ? machine.nameTR : machine.name)}</p>
                                </Link>
                                <img src={compareIcon} alt="compare icon" style={quoteBasket.includes(machine) ? compareColor : null} className={styles.compareCard} onClick={() => dispatch(addQuote(machine))}/>
                                {!quoteBasket.includes(machine) ? <img src={plusIcon} alt="plus icon" className={styles.addCard} onClick={() => dispatch(addQuote(machine))}/> : <img src={removeIcon} alt="remove icon" className={styles.removeCard} style={{border: 'none'}} onClick={() => dispatch(removeQuote(machine))}/>}
                            </div>
                        )}
                    </div>
                </section> : null}
            </div>

            <div className={styles.background} id="background">&#9632;</div>
        </div>
    )
}

export default ProductPage;